import React, { Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal';
import Datatable from '../common/datatable';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import Activity_finder from '../specific/activity_finder'
import Handler_bidding_deta from '../specific/handler_bidding_deta'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConsoleData from '../../hooks/ConsoleData';

export class Bidding_suggest extends Component {

    constructor(props) {
        super(props);
     
        this.GetDataDetaBiddingSuggest = this.GetDataDetaBiddingSuggest.bind(this);
        this.GetDataStore = this.GetDataStore.bind(this);
        this.handlerViewBidding = this.handlerViewBidding.bind(this);  
        this.handlerViewModal = this.handlerViewModal.bind(this);   

        this.state = {
            Action: "C",
            Action_form: "P",
            open: false,
            Max_size: 5*1000000, //Se maneja máximo de 5MB 
            data_store: [],                         
            Msg_load: "",
            loading: true,
            data_bidding: [], 
            data_detabidding: [],   
            name_store: "",        
            error: false,
            User_email: "",
            User_type: "",
            form:{
                deta:{                    
                    deta_bidding: []                    
                }, 
                Cod_bidding: "",
                Total_value: "",
                Partial: "",
                FactElect : "",
                Delivery:"",
                PolPag:"",
                Plus: "",
                Note: ""                            
            },
            file_name: "",    
            file: null              
        };           
    }

    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })
        
    }    

    handlerAddDetaBidding  = e => {            
        
        ConsoleData("handlerAddDetaBidding");
        ConsoleData(e);
        
        this.setState({            
            form:{
                ...this.state.form,
                deta:{
                    ...this.state.form.deta,
                    deta_bidding: e
                }
            }            
        })
        
    }

    handleChangeFile = e => {
        //console.log(e.target.files[0]);
        ConsoleData(e.target.files[0]);         
          

        ///Se valida el tamaño máximo de los archivos
        if(e.target.files[0].size > this.state.Max_size ){
            this.setState({       
                file: null,    
                file_name: "",    
                error: true,
                Msg_error: "El archivo no puede superar el tamaño de "+(this.state.Max_size/1000000)+" MB"
            }) ;

            toast.error("El archivo no puede superar el tamaño de "+(this.state.Max_size/1000000)+" MB");
        }else{
            this.setState({
                file : e.target.files[0],
                file_name: e.target.value,    
                loading: false,                
                error: false,
                Msg_error: ""                
            });
        }
        
    }    


    onOpenModal = () => {
        //console.log("onOpenModal");
        //console.log(e);
        this.setState({            
            open: true            
        });
    };

    onCloseModal = () => {        
        this.setState({            
            open: false,
            form:{
                deta:{                    
                    deta_bidding: []                    
                }, 
                Cod_bidding: "",
                Total_value: "",
                Partial: "",
                FactElect : "",
                Delivery:"",
                PolPag:"",
                Plus: "",
                Note: ""                                            
            },
            file_name: "",    
            file: null     
        });
    }

    componentDidMount(){        
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataSubCategories();
            this.GetDataSession("IcreapSessionData");        
        }        
    }

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            this.GetDataStore([DataValue.data[0].Email,DataValue.data[0].Tipo])

        }
    }

    GetDataStore = (Data_Sesion) => {
        const Urlquery = icreappconfig.url_controller+"AdminStores.php";
        //console.log(Urlquery);        

        const Params = {
            Action: this.state.Action,
            User_email: Data_Sesion[0],
            User_type: Data_Sesion[1]
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_store: [],
            loading: true            
        })

        //console.log(Params); 
        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    //console.log(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        
                        let Man_dataStore = [];
                        if(res.data[0].Data_store.length != 0){
                            Man_dataStore = res.data[0].Data_store[0];
                        }

                        this.setState({                            
                            data_store : Man_dataStore,                            
                            Msg_load: decoder,
                            //loading: false,
                            error: false                        
                        })           
                        
                        setTimeout(() => {
                            this.GetDataBiddingsSuggest();
                        },1000); 
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            data_store : res.data[0].Data_store,
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }

    
    GetDataBiddingsSuggest = () => {
        

        const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);       

        const formData = new FormData();           

        formData.append('Action','S');            
        formData.append('Cod_store',this.state.data_store.Cod_store);                     

        this.setState({                            
            loading: true,
            data_bidding: [],
            error: false,
            Msg_error: ""                        
        });    
        
        try{
            axios.post(Urlquery,formData)
                .then(res => {
                    //console.log(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                                                
                        this.setState({                                                                                 
                            data_bidding : res.data[0].Data_bidding,
                            Msg_load: decoder,
                            loading: false,
                            error: false                        
                        })                    
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                        

                        this.setState({                            
                            data_bidding : res.data[0].Data_bidding,
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }        

    }

    GetDataDetaBiddingSuggest = (props) => {
        ConsoleData(props);
        
        this.setState({                            
            loading: true,
            name_store: props.name_store,
            form:{
                ...this.state.form,
                Cod_bidding: props.cod_bidding
            }            
        });           

        ////Se traen los datos de la oferta especifica 
        try {                                        
            const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
            ConsoleData(Urlquery);        
    

            const formData = new FormData();               
            formData.append('Action','B');  
            formData.append('Cod_bidding',props.cod_bidding);  
            formData.append('Cod_store',props.cod_store);  

            axios.post(Urlquery, formData)            
                .then(res => {
                    //console.log(res.data);   
                    ConsoleData(res.data);                           
                   
                    if(res.data[0].Status){

                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       
                        this.setState({
                            loading: false,
                            error: false,
                            data_detabidding: res.data[0].DataBidding                          
                        })

                        this.onOpenModal();

                    }else{  
                        //alert(res.data[0].Msg); 
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 

                        toast.error( decoder );
                        this.setState({
                            loading: false,
                            error: false,
                            data_detabidding: [],
                            Msg_Error: res.data[0].Msg
                        })
                        
                   }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            

        }catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
                
    }   

    handleSubmit = async e => {
        ConsoleData("Submit")                        
        try {

            e.preventDefault();  
        
            let error_submit=false;
            
            /*
            if(this.state.file==null){
                toast.error("Debe seleccionar correctamente el archivo a adjuntar a la propuesta.");
                error_submit = true;                
                return false;
            } 
            */       

            if(parseInt(this.state.data_detabidding[0].Delivery)>0 && (parseInt(this.state.data_detabidding[0].Delivery) < parseInt(this.state.form.Delivery)) ){
                toast.error("No puedes sobrepasar los días máximos de entrega establecidos en la propuesta.");
                error_submit = true;   
                return false;             
            }

            ///Se valida el parametro de facturacion electronica
            if(parseInt(this.state.data_detabidding[0].FactElect)==1 && parseInt(this.state.form.FactElect)!= 1){
                toast.error("Es obligatorio manejar facturación electrónica para esta Oferta.");
                error_submit = true;   
                return false;             
            }

            ///Validar detalle del requerimiento
            if(this.state.data_detabidding[0].deta.deta_bidding != undefined && this.state.data_detabidding[0].deta.deta_bidding.length>0 && this.state.form.deta.deta_bidding.length<=0){
                toast.error("Se debe diligenciar el detalle del.");
                error_submit = true;   
                return false;   
            }
            
                                        
            const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";                
            
            ConsoleData("Urlquery");
            ConsoleData(Urlquery);        
    
            const formData = new FormData();   

            formData.append("PostBidding",this.state.file);
            formData.append('Action',this.state.Action_form);    
            formData.append('User_email',this.state.User_email);    
            formData.append('User_type',this.state.User_type);                
            formData.append('form',JSON.stringify(this.state.form)); 

            //ConsoleData(JSON.stringify(formData));

            this.setState({                            
                loading: true,
                error: false,
                Msg_error: ""                        
            });        
            
            axios.post(Urlquery, formData)            
                .then(res => {
                    //console.log(res.data);   
                    ConsoleData(res.data);       
                                                                
                    if(res.data[0].Status){

                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       
                        
                        this.setState({
                            loading: false,
                            error: false,
                            file: null,
                            file_name: null,
                            form:{
                                deta:{                    
                                    deta_bidding: []                    
                                }, 
                                Cod_bidding: "",
                                Total_value: "",
                                Partial: "",
                                FactElect : "",
                                Delivery:"",
                                PolPag:"",
                                Plus: "",
                                Note: ""                            
                            }
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio                        
                        toast.success(decoder);                                                
                        this.onCloseModal();        
                        this.GetDataBiddingsSuggest();                              
                    }else{  
                        //alert(res.data[0].Msg); 
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 

                        toast.error( decoder );
                        this.setState({
                            loading: false,
                            error: false,
                            Msg_Error: res.data[0].Msg
                        })
                        
                }
                
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    
            
        }catch (error) {
            
            ConsoleData(error);

            this.setState({
                loading: false,
                error
            }) 
        }
        
        

    } 


    handlerViewBidding = (props) => {

        //console.log("handlerViewBidding");
        //console.log(props);
        const DataDeta = this.GetDataDetaBiddingSuggest;

        return props.map(function (CurrentData,i){
            return (
                <div className="col-sm-12 col-md-6 col-lg-5 col-xl-3 border border-success rounded shadow p-4 mb-4 bg-white" key={i} style={ {margin:5} }  onClick={()=>DataDeta(CurrentData)}>
                    
                    <label htmlFor="recipient-name" className="col-form-label" > 
                        <strong>Empresa:</strong> {CurrentData.name_store} 
                    </label>
                    <br/>
                    <label htmlFor="recipient-name" className="col-form-label" > 
                        <strong>Expira el día:</strong> {CurrentData.date_expires} 
                    </label>
                    <br/>
                    <label htmlFor="recipient-name" className="col-form-label" > 
                        <strong>Descripción:</strong> {DecoderData({Data: CurrentData.desc, Type: "S"})} 
                    </label> 
                    
                </div>
            )
        })                         
    }    

    handlerViewModal = (props) => {
        
        //console.log("handlerViewModal");
        //console.log(props.length);    
        const onCloseModal = this.onCloseModal;
        const handleSubmit = this.handleSubmit;
        const handleChange = this.handleChange;
        const handleChangeFile = this.handleChangeFile;                
        const handlerAddDetaBidding = this.handlerAddDetaBidding;

        const form_data = this.state.form;
        const name_store = this.state.name_store;

        let LoadCategories;
        if(props.length>0){
            //console.log(props[0].deta);    
            LoadCategories= <div style={{margin:20}} >
                                <table className="table table-striped table-sm" >
                                    <thead>
                                        <tr className="align-self-center text-center">
                                            <th>Id</th>
                                            <th>Nombre</th>                                                                                                                              
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {
                                            //let Data_AddSubcat = { 'cod_subcat' : this.state.tmp.Cod_subcategory, 'name_subcat' : this.state.tmp.Name_subcategory };
                                            props[0].deta.sub_categories.map( (CurrentData,i) => {                            
                                                return (
                                                    <tr key={i} >
                                                        <td >{CurrentData.cod_subcat} </td>
                                                        <td >{CurrentData.name_subcat}</td>                                                                                                            
                                                    </tr>
                                                )                                                
                                            })
                                        }
                                    </tbody>
                                </table>                                
                            </div>;

        }

        return props.map(function (CurrentData,i){
            return (<form onSubmit={handleSubmit} key={i}>
                        <div className="modal-body" >                                
                            <div className="row">

                                <div className="col-xl-12 col-md-12 align-self-center text-center" >
                                    <h4>Datos de la Oferta</h4> 
                                </div>

                                <div className="col-xl-4 col-md-4">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Id Oferta:</strong> {CurrentData.Cod_bidding}</label>                                
                                </div>

                                <div className="col-xl-4 col-md-4">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Comercio:</strong> {name_store}</label>                                
                                </div>

                                <div className="col-xl-4 col-md-4">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Estado de la oferta:</strong> Activa</label>
                                </div>

                                <div className="col-xl-4 col-md-4">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Creado:</strong> {CurrentData.Date_create}</label>                                
                                </div>

                                <div className="col-xl-4 col-md-4">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Expira:</strong> {CurrentData.Date_expire}</label>                                                                
                                </div>

                                <div style={ {marginTop:20} } className="col-xl-12 col-md-12 align-self-center text-center">                                            
                                    <h4>
                                        {CurrentData.Adjunto.length>0 ? <a href={icreappconfig.url_platform+CurrentData.Adjunto} target="_blank" >Ver archivo adjunto de la oferta</a> : ""}                                        
                                    </h4>
                                </div>

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Descripción de la Oferta:</strong> {DecoderData({Data: CurrentData.Desc, Type: "S"})}</label>                                                                
                                </div> 

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Políticas de pago de la Oferta:</strong> {DecoderData({Data: CurrentData.PolPag, Type: "S"})}</label>
                                </div> 

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Solicitud:</strong>{(CurrentData.Type_bidding == "1" ? "Pública" : (CurrentData.Type_bidding == "2" ? "Directa" : "Sin definir")  )}</label>                                
                                </div>                                    

                                {
                                    /*
                                
                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" ><strong>Comercio Seleccionado:</strong> {CurrentData.ComercioSel}</label>                                                                                                
                                    </div>

                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" ><strong>Expiración de la oferta "dias":</strong> {CurrentData.Expire}</label> 
                                    </div>

                                    */
                                } 
                                

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Tiempo máximo entrega "días":</strong> {CurrentData.Delivery}</label>                                 
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Cantidad Ítems:</strong> {CurrentData.Items}</label>                                 
                                </div>

                                {
                                    /*
                                
                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" ><strong>Valor máximo $:</strong> {CurrentData.Valmax}</label>                                                                
                                    </div>

                                    */
                                }
                                

                                <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop:20} } >
                                    <h6>Filtros de la oferta</h6> 
                                </div> 

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Permite parciales:</strong> {(CurrentData.Partial=="1" ? "Permite" : "No permite")}</label>
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Facturación Electrónica:</strong> {(CurrentData.FactElect=="1" ? "Obligatorio" : "No obligatorio")}</label>
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Empleados mínimos:</strong> {CurrentData.Employees}</label>
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Experiencia mínima (años):</strong> {CurrentData.Experience}</label>
                                </div>

                                <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop:20} } >
                                    <h6>Filtro por actividad</h6> 
                                </div>

                                <div className="col-xl-12 col-md-12">
                                    {LoadCategories}
                                </div>                             

                                <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop:20} } >
                                    <h5>Datos para aplicar a la oferta</h5> 
                                </div> 

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" >Valor total de la propuesta</label>
                                    <input type="number" className="form-control" min={0} name="Total_value" value={form_data.Total_value} onChange={handleChange} required/>
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" >Maneja parciales</label>                                                                                
                                    <select className="custom-select " value={form_data.Partial} onChange={handleChange} name="Partial" required >            
                                        <option value="">Seleccione una opción</option>
                                        <option value="1">Si Maneja</option>
                                        <option value="0">Propuesta completa</option>
                                    </select>
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" >Facturación Electrónica</label>                                                                                
                                    <select className="custom-select " value={form_data.FactElect} onChange={handleChange} name="FactElect" required >            
                                        <option value="">Seleccione una opción</option>
                                        <option value="1">Si Manejamos</option>
                                        <option value="0">No Manejamos</option>
                                    </select>
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" >Tiempo máximo entrega "días"</label>
                                    <input type="number" className="form-control" min={1} name="Delivery" value={form_data.Delivery} onChange={handleChange} required/>
                                </div>


                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" >Políticas de pago</label>
                                    <input type="text" className="form-control" name="PolPag" placeholder="Ingrese una descripción relacionada, ejemplo 'Pago a 30 días' " value={form_data.PolPag} onChange={handleChange} required/>
                                </div>

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" >Subir archivo para adjuntar a la propuesta</label>
                                    <input type="file" className="form-control" onChange={handleChangeFile} />
                                </div>

                                <div style={ {marginTop:10} } className="col-xl-12 col-md-12 align-self-center text-center" >                                        
                                        <Handler_bidding_deta 
                                            Type = {"Postulation"}
                                            Data={ props[0].deta.deta_bidding }
                                            HandlerData = {handlerAddDetaBidding}/>                                        
                                </div>

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" >Describe el valor agregado de tu oferta </label>
                                    <textarea className="form-control" name="Plus" rows="3" value={form_data.Plus} onChange={handleChange} required></textarea>
                                </div>

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" >Ingresa una nota si es necesario</label>
                                    <textarea className="form-control" name="Note" rows="3" value={form_data.Note} onChange={handleChange} ></textarea>
                                </div>                                
                    
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" >Aplicar a la oferta</button>
                            <button type="button" className="btn btn-secondary" onClick={() => onCloseModal()}>Cerrar</button>                
                        </div>
                    </form>
            )
        })        
    }

    render() {

        const { open } = this.state;
        let TitleModal = "Aplicar a la oferta";
        let BodyPage = "";     
        let handle_add = "";     
        let handler_modal = "";      
        
        if(this.state.loading){
            BodyPage = <Loading />;
            handler_modal=<Loading />;
        }else{
            if(this.state.Data_bidding == []){
                BodyPage = <div></div>;
                handle_add = <h3>{this.state.Msg_load}</h3>;
            }else{
                if(this.state.data_bidding.length>0){
                    handle_add = <h3>Se ha cargado {this.state.data_bidding.length} ofertas recomendadas</h3>;
                    BodyPage = this.handlerViewBidding(this.state.data_bidding);
                }else{
                    handle_add = <h3>{this.state.Msg_load}</h3>;
                    BodyPage = <div></div>;
                }
                
            }         
            
            
            if(this.state.data_detabidding == []){
                handler_modal = <></>;
            }else{
                handler_modal = this.handlerViewModal(this.state.data_detabidding);
            }
        }




        return (
            <Fragment>
                <Breadcrumb title="Ofertas recomendadas" parent="Ofertas" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        {handle_add}
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{TitleModal}</h5>
                                            </div>                                            
                                            {handler_modal}                                            
                                        </Modal>                                        
                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical" style={ {margin:10} }> 
                                        
                                        <div className="row">
                                            {BodyPage}                                                                                                                    
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }

}

export default Bidding_suggest
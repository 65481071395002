import React from 'react';

////Manejo de modal para asignacion
//import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form_services from './form_services'
import DetaServices from './deta_service'
import ConsoleData from '../../hooks/ConsoleData'

const formatterPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
})

function VerticallyCenteredModal(props) {
    //console.log(props);
    ConsoleData(props);    

    return (
        <Modal        
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                ID Servicio: {props.DataServices.cod_agenda}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={ {marginLeft: 20} }>                                           
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 align-self-center text-center" >
                            <h4>Datos del cliente</h4> 
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <strong>Usuario: </strong> {props.DataServices.name_user}
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <strong>Telefono: </strong> {props.DataServices.phone_user}
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <strong>Ciudad: </strong> {props.DataServices.city}
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <strong>Direccion: </strong> {props.DataServices.address_client}
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <strong>Indicaciones: </strong> {props.DataServices.address_indic}
                        </div>

                                                    
                        <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop: 10} }>
                            <h4>Datos del Vehiculo</h4> 
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <strong>Tipo: </strong> {props.DataServices.name_type}
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <strong>Serie o Placa: </strong> {props.DataServices.serie}
                        </div>                            
                        

                        <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop: 10} }>
                            <h4>Datos del Agendamiento </h4> 
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <strong>Agendado para: </strong> {props.DataServices.agenda_date+" "+props.DataServices.agenda_time}
                        </div>                            
                        <div className="col-xl-6 col-md-6">
                            <strong>Valor Total: </strong> {formatterPeso.format(props.DataServices.total_cost)}
                        </div>                            
                    </div>
                    <div className="row">                        
                                            
                        <DetaServices DataService={props.DataServices} /> 

                    </div>

                </div>
            </div>    
            <Form_services DataService={props} />
                        
        </Modal.Body>
        <Modal.Footer>
            {
                /*
                <Button onClick={props.onHide}>Close</Button>                
                */
            }            
        </Modal.Footer>
      </Modal>
    );
}

function  TableServices(props) {

    //console.log(props);
    ConsoleData(props);    
    
    const [modalShow, setModalShow] = React.useState(false);
    const [ManCurrendServices, setCurrentservices] = React.useState("");

    return(
        <>            
            
            <VerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}   
                RefreshData = {props.RefreshData}             
                DataServices = {ManCurrendServices}                           
            />

            <table className="table table-striped" style={ {marginTop:20,paddingLeft:20,paddingRight:20}  } >
                <thead>
                    <tr>
                        <th>Servicio Agendado</th>
                        <th>Usuario</th>
                        <th>Telefono</th>
                        <th>Placa o Serie</th>
                        <th>Dirección</th>
                        <th>Indicaciones</th>
                        <th>Metodo de Pago</th>
                        <th>Costo Total</th>                                    
                    </tr>
                </thead>
                <tbody>                                    

                    {

                        props.DataService.map( (CurrentService,i) => {                            
                            return (
                                <tr key={i}  onClick={() => {
                                        setModalShow(true); 
                                        setCurrentservices(CurrentService);
                                        }}>
                                    <td >{CurrentService.agenda_date} {CurrentService.agenda_time} </td>
                                    <td >{CurrentService.name_user}</td>
                                    <td >{CurrentService.phone_user}</td>
                                    <td >{CurrentService.name_type} - {CurrentService.serie}</td>
                                    <td >{CurrentService.city} - {CurrentService.address_client}</td>
                                    <td >{CurrentService.address_indic}</td>
                                    <td >Efectivo</td>
                                    <td >{formatterPeso.format(CurrentService.total_cost)}</td>                                                                
                                </tr>
                            )
                            
                        })

                    }


                </tbody>
            </table>
        </>    
    )

    
}


export default TableServices;



/* custom Hook para session data*/
import React from 'react';
import icreappconfig from '../Icreappconfig'
import ConsoleData from './ConsoleData'

const GetDataSession = key => {
    let SessionDataValue = sessionStorage.getItem(key);
    let DataSesion = true;
    if (SessionDataValue !== null) {
        let DataValue = JSON.parse(SessionDataValue);        
        let expirationDate = new Date(DataValue.expirationDate);
        if (expirationDate > new Date()) {
            UpdateSessionExpiration(key,DataValue.data);
        } else {                
            sessionStorage.removeItem(key);  
            
            ///Se eliminan otras variables de session utilizadas
            sessionStorage.removeItem("Cod_store");
            sessionStorage.removeItem("Data_bidding");
            ///////////////////////////////


            DataSesion = false;
            //useHistory.push(`${process.env.PUBLIC_URL}/`);          
            //this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }

    }else{
        //this.props.history.push(`${process.env.PUBLIC_URL}/`);
        //useHistory.push(`${process.env.PUBLIC_URL}/`);          
        //console.log("No se encontro session iniciada");
        ConsoleData("No se encontro session iniciada");        
        DataSesion = false;
    }

    return DataSesion;
}

function UpdateSessionExpiration(_key,_DataSession){

    let expirationDate = new Date(new Date().getTime() + (60000 * icreappconfig.sessionExpiration) );
    let DateNow = new Date(new Date().getTime() );
    let ValueData = {
        data: _DataSession,
        expirationDate: expirationDate.toISOString(),
        DateNow : DateNow 
    }

    sessionStorage.removeItem(_key);      
    sessionStorage.setItem(_key, JSON.stringify(ValueData));
}
//export default useFetchPOST
export default GetDataSession


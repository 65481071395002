import React, { Component, Fragment, useState, useEffect } from 'react';

import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Unlock, HelpCircle, File } from 'react-feather';
import { withRouter } from 'react-router-dom';

import icreappconfig from '../../Icreappconfig'

import axios from 'axios';
import Icreappconfig from '../../Icreappconfig';
import ConsoleData from '../../hooks/ConsoleData';
import DecoderData from '../../hooks/DecoderData';

export class LoginTabset extends Component {
        
    constructor(props) {
        super(props);
        
        this.state = {
            tipo: 'B',
            SessionState: false,
            SessionMessage: '',
            RegisterMessage: '',
            form: {
                Name: "",
                Phone: "",
                Email: "",
                Type_user: 10
            },
            files_help: [],
            loading:false
        }

        /*
        this.state = {
            activeShow: true,
            startDate: new Date()
        }
        */
        
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeReg = this.handleChangeReg.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitReg = this.handleSubmitReg.bind(this);
        this.GetDataSession = this.GetDataSession.bind(this);
        this.Func_login = this.Func_login.bind(this);
        
    }

    componentWillMount(){
        ///Se valida que la sesion este iniciada para redireccionar
        this.GetDataSession("IcreapSessionData");        
    }
    

    ///Se debe inicializar la variable de estado para evitar errores al iniciar los campos
    //state = {};
    

    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove('show');
        event.target.classList.add('show');
    }

    /*Se cambia funcion para cambiar datos de estado
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    */
    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            [e.target.name]: e.target.value
        })
        
    }

    handleChangeReg = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })        
    }

    handleSubmit(e) {        
        e.preventDefault();

        /* Manejo de custom hook para comunicacion */
        //const { data, loading, error} = useFetchPOST(`${url_platform}/exercises`, );
        this.Func_login("B")
        //this.routeChange();
    }

    handleSubmitReg(e){
        e.preventDefault();        

        try {
            
            //console.log(this.state.form);            
            ConsoleData(this.state.form);
            
            const Urlquery = icreappconfig.url_controller+"Reg_UserLogin.php";
            //console.log(Urlquery);        
            ConsoleData(Urlquery);
    
            const Params = {
                Action : "U",                
                Form : this.state.form
            }
    
            //console.log(Params); 
            ConsoleData(Params);
    
            axios.post(Urlquery,Params)
                .then(res => {
                //console.log(res.data);   
                ConsoleData(res.data);
                
                if(res.data[0].Status){
                    ///Se utiliza para cerrar el modal despues de registrar el cambio
                    //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                    let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                       
                    //toast.success(decoder); 
                    
                    this.setState({   
                        RegisterMessage: decoder,                         
                        form:{
                            Name: "",
                            Phone: "",
                            Email: ""
                        }                            
                    })       
                        
                                                                                           
                }else{  
                    //alert(res.data[0].Msg); 
                    //let decoder = decodeURIComponent(escape(res.data[0].Msg));                       
                    let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                        
                    
                    this.setState({
                        RegisterMessage: decoder
                    })
                    
                }
                
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

                ////this.props.history.push('/exercise')

        }catch (error) {
            //console.log(error);
            ConsoleData(error);
            this.setState({
                loading: false,
                error
            }) 
        }      

    }
    
    routeChange = () => {
        //Se cambia a ruta temporal mientras se define dashboard
        //this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);

        ///Se valida a donde se dirige dependiendo del tipo de usuario
        let SessionDataValue = sessionStorage.getItem("IcreapSessionData");
        let DataValue = JSON.parse(SessionDataValue);  
        if(parseInt(DataValue.data[0].Tipo)<9){
            this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
        }else{
            this.props.history.push(`${process.env.PUBLIC_URL}/store/data_store`);
        }


        
    }    


    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            let expirationDate = new Date(DataValue.expirationDate);
            if (expirationDate > new Date()) {
                this.routeChange();
            } else {
                sessionStorage.removeItem(key);                
            }

        }else{
            //console.log("No se encontro session iniciada");
            ConsoleData("No se encontro session iniciada");
            this.GetHelpFiles();
        }

    }
    
    Func_login(action){

        const UrlLogin = icreappconfig.url_controller+"Reg_UserLogin.php";

        const Params = {
            Email: this.state.Name,
            Pasw: this.state.Pasw,
            Action: action
        }

        /*
        axios.defaults.headers.post['Content-Type'] ='application/json';               
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';
        axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';        
        //const {data} = axios.post(UrlLogin,Params);        
        //console.log(data);                
        */

        axios.post(UrlLogin,Params)
             .then(res => {
                //console.log(res.data);   
                // ConsoleData(res.data);
                
                if(res.data[0].Status){
                    let expirationDate = new Date(new Date().getTime() + (60000 * icreappconfig.sessionExpiration) );
                    let ValueData = {
                        data: res.data,
                        expirationDate: expirationDate.toISOString()
                    }

                    this.setState({
                        SessionState: true,
                        SessionMessage: ''
                    })

                    sessionStorage.setItem('IcreapSessionData', JSON.stringify(ValueData));
                    
                    setTimeout(() => {
                        this.routeChange();
                    }, 300);
                    
                }else{
                    //console.log("Error al ingresar "+res.data[0].Msg);
                    ConsoleData("Error al ingresar "+res.data[0].Msg);                    

                    this.setState({
                        SessionState: true,
                        SessionMessage: res.data[0].Msg
                    })

                    this.render();
                }

                
             })
             .catch(err => console.error(err));

    }

    GetHelpFiles = () => {
        const Urlquery = icreappconfig.url_controller+"upload_file.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);

        const formData = new FormData(); 
        formData.append('Action',"H");    
        formData.append('Group',"ayuda_registro");    

        ///Se reinician estados para control de datos
        this.setState({
            datatable_files : [], 
            data_files: [],  
            loading: true            
        })

        try{
            axios.post(Urlquery,formData)
                .then(res => {
                    
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        ConsoleData(res.data[0].Data_file);
                        
                        this.setState({                            
                            files_help: res.data[0].Data_file,
                            loading : false,                           
                            Msg_load: decoder,                            
                            error: false                        
                        })           
                                                
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            files_help: [],
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            datatable_files : [], 
                            data_files: [],
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
    
    
    }

    routeChangeFile  = (file) => {        
        
        sessionStorage.removeItem("HelpFile");      
        sessionStorage.setItem("HelpFile", file );
        this.props.history.push(`${process.env.PUBLIC_URL}/view_help`);

    }

    viewHelpFiles = () => {


        ConsoleData("Ingreso viewHelpFiles")

        const helpfiles = this.state.files_help;
        const routeChangeFile = this.routeChangeFile;

        return helpfiles.map( (CurrentData,i) => {   
            //ConsoleData(Object.values(form_data).includes(CurrentData.Cod_biddingpost));

            return (
                <tr key={i}> 
                    <td>
                        <a href={Icreappconfig.url_platform+CurrentData.name_file} target="_blank" >
                            <File />{CurrentData.desc_file}
                        </a>                                    
                    </td>                            
                </tr>                
            )                                                
            
        })

    }



    render() {

        let Table_help = <tr><td></td></tr>;
        if(this.state.loading){
            Table_help = <tr>
                            <td>Cargando Archivos...</td>
                        </tr>;
        }else{
            if(this.state.files_help!= []){

                Table_help = this.viewHelpFiles();

            }else{
                Table_help = <tr>
                                <td>Lo sentimos aun no hemos subido los archivos de ayuda. Pronto podras ingresar a ellos y conocer mejor nuestra plataforma</td>
                            </tr>;                
            }
        }


        return (
            <div>
                <Fragment>
                    <Tabs>
                        <TabList className="nav nav-tabs tab-coupon" >
                            <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><User />Login</Tab>
                            <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><Unlock />Registrate</Tab>
                            <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><HelpCircle />Ayuda</Tab>
                            {/*<Tab className="nav-link" onClick={(e) => this.clickActive(e)}><Unlock />Register</Tab>*/}
                        </TabList>

                        <TabPanel>
                            <form className="form-horizontal auth-form" onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input required name="Name" type="email" className="form-control" placeholder="Email" onChange={this.handleChange} value={this.state.username} />
                                </div>
                                <div className="form-group">
                                    <input required name="Pasw" type="password" className="form-control" placeholder="Password" onChange={this.handleChange} value={this.state.password}/>
                                </div>
                                                                
                                <div className="form-terms">
                                    {/*
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                        
                                        <label className="d-block">
                                            <input className="checkbox_animated" name="chk_remem" id="chk_remem" type="checkbox" />
                                                Recordarme por 30 dias                                                                                                 
                                        </label>
                                    </div>
                                    
                                    <div className="text-center">
                                        <span > 
                                            <a href="#" className="btn btn-default forgot-pass p-0">¿Olvidaste la contraseña?</a>
                                        </span>
                                    </div>
                                    */}

                                    <div className="text-center">
                                        <span > 
                                            <a href={`${process.env.PUBLIC_URL}/auth/login_forgot`} className="btn btn-default forgot-pass p-0 text-underline">
                                            ¿Olvidaste la contraseña?
                                            </a>
                                        </span>
                                    </div> 
                                                                  
                                    <div className="text-center">
                                        <span > 
                                            <h4 style={{color: "#dc3545"}}>{this.state.SessionMessage}</h4>
                                        </span>
                                    </div>
                                </div>
                                
                                
                                <div className="form-button">
                                    <button className="btn btn-primary" type="submit"  >Login</button>
                                </div>
                                
                                <div className="form-footer">

                                    {/* se quita ya que no se utilza
                                    
                                    <span>O ingresa con tus redes sociales</span>
                                    <ul className="social">
                                        <li><a className="fa fa-facebook" href=""></a></li>
                                        <li><a className="fa fa-google-plus" href=""></a></li>                                        
                                    </ul>


                                    */}
                                    {/* Se comentarea para posterior use
                                    
                                        <span>Or Login up with social platforms</span>
                                        <ul className="social">
                                            <li><a className="fa fa-facebook" href=""></a></li>
                                            <li><a className="fa fa-twitter" href=""></a></li>
                                            <li><a className="fa fa-instagram" href=""></a></li>
                                            <li><a className="fa fa-pinterest" href=""></a></li>
                                        </ul>
                                    */                                   
                                    }    
                                    
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel>
                            <form className="form-horizontal auth-form" onSubmit={this.handleSubmitReg}>
                                <div className="form-group">
                                    <h6 style={{color: "#000000"}}>Ingresa tus datos personales para enviarte la solicitud al correo, luego de que estés registrado podrás crear el perfil de tu negocio.</h6>
                                </div>
                                <div className="form-group">
                                    <input required name="Name" type="text" className="form-control" placeholder="Nombre completo" onChange={this.handleChangeReg} />
                                </div>
                                <div className="form-group">
                                    <input required name="Phone" type="tel" className="form-control" placeholder="Numero de contacto" onChange={this.handleChangeReg} />
                                </div>
                                <div className="form-group">
                                    <input required name="Email" type="email" className="form-control" placeholder="Email" onChange={this.handleChangeReg}/>
                                </div>                                                                
                                
                                <div className="text-center">
                                    <span > 
                                        <p style={{color: "#dc3545"}}>{this.state.RegisterMessage}</p>
                                    </span>
                                </div>

                                <div className="form-terms">
                                    <div className="custom-control custom-checkbox mr-sm-2">
                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                                        <label className="d-block">
                                            <input className="checkbox_animated" id="chk-ani2" type="checkbox" required/>
                                            Acepto todo los <span><a href="/TermCond" target="_blank">Terminos &amp; Condiciones</a></span>
                                        </label>
                                    </div>
                                </div>


                                <div className="form-button">
                                    <button className="btn btn-primary" type="submit" >Registrar</button>
                                </div>                                
                            </form>
                        </TabPanel>
                        <TabPanel>
                            <div className="modal-body" >                                
                                <div className="row">  
                                
                                    <div className="col-xl-12 col-md-12">
                                        <h6 style={{color: "#000000"}}>
                                            Verifica primero los archivos de ayuda, si no encuentras respuesta a tus preguntas puedes escribirnos al correo mosqueraaplicacion@gmail.com, te responderemos en el menor tiempo posible.
                                        </h6>
                                    </div>
                                
                                    <div className="col-xl-12 col-md-12">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>Manuales</th>
                                                </tr> 
                                            </thead>
                                            <tbody>
                                                {Table_help}
                                            </tbody>
                                        </table>         
                                    </div>
                                    
                                    
                                </div> 
                            </div>                                                
                        </TabPanel>                                        
                        {/*                
                            <TabPanel>
                                <form className="form-horizontal auth-form">
                                    <div className="form-group">
                                        <input required="" name="username" type="email" className="form-control" placeholder="Email" onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <input required="" name="fullname" type="text" className="form-control" placeholder="Full Name" onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <input required="" name="telephone" type="number" className="form-control" placeholder="Telephone" onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <input required="" name="password" type="password" className="form-control" placeholder="Password" onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <input required="" name="conf_password" type="password" className="form-control" placeholder="Confirm Password" onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-terms">
                                        <div className="custom-control custom-checkbox mr-sm-2">
                                            <input type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                                            <label className="d-block">
                                                <input className="checkbox_animated" id="chk-ani2" type="checkbox" />
                                                Acepto todo los <span><a href="">Terminos &amp; Condiciones</a></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-button">
                                        <button className="btn btn-primary" type="submit" onClick={() => this.routeChange()}>Registrar</button>
                                    </div>
                                    <div className="form-footer">                                        
                                        <span>Or Sign up with social platforms</span>
                                        <ul className="social">
                                            <li><a className="fa fa-facebook" href=""></a></li>
                                            <li><a className="fa fa-twitter" href=""></a></li>
                                            <li><a className="fa fa-instagram" href=""></a></li>
                                            <li><a className="fa fa-pinterest" href=""></a></li>
                                        </ul>                                                                                    
                                    </div>
                                </form>
                            </TabPanel>
                        */}
                        
                    </Tabs>
                </Fragment>
            </div>
        )
    }
}

export default withRouter(LoginTabset)


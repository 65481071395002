import React, { Component,Fragment } from 'react';
import icreappconfig from '../../Icreappconfig'
import GetDataSession from '../../hooks/DataSession'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import ConsoleData from '../../hooks/ConsoleData'

////se trae componente funcional para los datos
import TableServices from './table_services'

export class ActiveServices extends Component {                   

    constructor(props){
        super(props)

        //console.log(props.location.state.Service);

        this.GetActiveServices = this.GetActiveServices.bind(this);

        this.state = {
            Services_Status: "A", ///Active services
            Data: []
        }        
        
    }

    componentDidMount(){

        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            this.GetActiveServices();
        }
        
    }

    GetActiveServices(){
        const Urlquery = icreappconfig.url_controller+"LoadStatusServices.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);

        const Params = {
            Action: this.state.Services_Status            
        }

        //console.log(Params); 
        ConsoleData(Params);

        axios.post(Urlquery,Params)
             .then(res => {
                //console.log(res.data);   
                ConsoleData(res.data);
                
                if(res.data[0].Status){
                    this.setState({
                        Data: res.data[0].Data                        
                    })                    
                }else{                    
                }
                
             })
             .catch(err => console.error(err));
    }
            

    render(){                

        //console.log('props form link',this.props.location)//props not recived
        return (
            <Fragment>
                <Breadcrumb title="Servicios Activos" parent="Dashboard" />
                <div className="container-fluid">
                    <div className="row">
                        <TableServices DataService={this.state.Data} RefreshData={() => this.GetActiveServices() } />                   
                    </div>                                
                </div>
            </Fragment>    
            
        )
    }
}

export default ActiveServices
import React, { Component, Fragment } from 'react'
import BiddingTabset from './bidding_tabset';
import { ArrowLeft, Sliders } from 'react-feather';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import stats from '../../assets/images/dashboard/stats.png';

import Breadcrumb from '../common/breadcrumb'
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import ConsoleData from '../../hooks/ConsoleData'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//const image_logo = icreappconfig.url_images+"iconos/Icono_logo.jpg";
const image_logo = icreappconfig.url_images+"iconos/logo_color.png";

export class UpdateBiddingsEmploy extends Component {    
    
    constructor(props) {
        super(props);

        this.GetDataStore = this.GetDataStore.bind(this);                     

        this.state = {
            Id_post: "",
            open: false,
            open_mfile: false,
            data_store: [],                         
            Action: "U",
            Action_form: "T",
            loading: true,
            error: true,   
            Msg_load: '',    
            User_email: '',
            User_type: '',     
            Msg_Error: ''
        };
    }
    
    componentDidMount(){
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{            
            this.GetDataSession("IcreapSessionData");
        }        
    }

    onCloseModal = () => {        
        this.setState({            
            open: false,
            form : {
                Status_bidding: ""
            }, 
        });
    }

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })
            
            this.getDataLocalStore();                                    
        }
    }

    getDataLocalStore = () => {
        //this.GetDataStore = this.GetDataStore.bind(this);
        let Data_store = sessionStorage.getItem("Id_post")        

        if (Data_store !== null) {
            
            ///Se quita para evitar que al recargar se vea doble recarga
            //sessionStorage.removeItem("Data_bidding");
            this.setState({                
                Id_post : Data_store
            })
            
            setTimeout(() => {
                //this.GetData();
            }, 1000);
        }else{                        
            ConsoleData("No se encontraron datos de oferta");
            this.props.history.push(`${process.env.PUBLIC_URL}/employ/handler_employ_post`);
        }

    }

    GetDataStore = () => {
        const Urlquery = icreappconfig.url_controller+"AdminStores.php";
        //console.log(Urlquery);        

        const Params = {
            Action: this.state.Action,
            User_email: this.state.User_email,
            User_type: this.state.User_type,
            Cod_store: this.state.Cod_store
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_store : [],
            loading : true,
            error : true,
            Msg_load: ""            
        })

        //console.log(Params); 
        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                        
                        
                        let Man_dataStore = res.data[0].Data_store[0];
                                                                   
                        this.setState({                            
                            data_store : Man_dataStore,                                                      
                            data_groups: [],
                            Msg_load: decoder,
                            loading: false,
                            error: false                        
                        })                    

                    }else{     
                        
                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                

                        this.setState({
                            data_store : [],
                            loading: false,
                            Msg_load: decoder,
                            error: true                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            data_store : [],
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }       


    render() {

        const { open } = this.state;
        let Id_post = this.state.Id_post;
        let TitleModal = "Comercio";
        let BodyPage = "";     
        let handle_add = "";   
        let handler_modal = "";      
        
        if(this.state.loading){
            BodyPage = <Loading />;
        }else{
            if(this.state.data_store != [] ){
                BodyPage = this.viewDataBody();
            }else{
                BodyPage = <h3>{this.state.Msg_load}</h3>;
            }            
        }

        return (
            <Fragment>
                <Breadcrumb title="Modificar Oferta" parent="Ofertas" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">
                                    <div className="btn-popup pull-right">                                        
                                    </div>

                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">  
                                        <BiddingTabset Id_post={Id_post} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }


}

export default UpdateBiddingsEmploy

import React, { Component,Fragment } from 'react';
import Loading from '../Loading'
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import ConsoleData from '../../hooks/ConsoleData'
import DecoderData from '../../hooks/DecoderData'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const date_now = new Date();

const camelCase = (str) =>  {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
};

const filterColumns = (data) => {
    // Get column names
    const columns = Object.keys(data[0]);
    
    ConsoleData("filterColumns");
    //ConsoleData(data);
    ConsoleData(columns);

    // Remove by key (firstname)
    const filterColsByKey = columns.filter(c => c !== 'id');

    // OR use the below line instead of the above if you want to filter by index
    // columns.shift()

    return filterColsByKey // OR return columns
};

const ExcelDownload = Data => {

    let File_name = Data.FileName+"_"+date_now.getDate()+"_"+(date_now.getMonth()+1)+"_"+date_now.getFullYear();
    let DataSheet = Data.Sheet;    
    
    return (

        <div >
            <ExcelFile element={ <div className="col-xl-6 col-md-6 align-self-right text-right" style={{marginTop:10,marginBottom:10}}>  <button className="btn btn-warning" type="button">Exportar a Excel</button></div>  } filename={File_name} >

                <ExcelSheet data={DataSheet} name="Datos">

                    {
                        filterColumns(DataSheet).map((col,i)=> {
                            return <ExcelColumn label={camelCase(col)} value={col} key={i}/>
                        })
                    }
                    
                </ExcelSheet>

                
            </ExcelFile>
        </div>
    );
    
}

export default ExcelDownload;


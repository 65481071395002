import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.scss';
import App from './components/app';
import { ScrollContext } from 'react-router-scroll-4';

// Components
import Dashboard from './components/dashboard';
import DashboardEmploy from './components/dashboard_employ';


// Products physical
import Category from './components/products/physical/category';
import Sub_category from './components/products/physical/sub-category';
import Product_list from './components/products/physical/product-list';
import Add_product from './components/products/physical/add-product';
import Product_detail from './components/products/physical/product-detail';

//Product Digital
import Digital_category from './components/products/digital/digital-category';
import Digital_sub_category from './components/products/digital/digital-sub-category';
import Digital_pro_list from './components/products/digital/digital-pro-list';
import Digital_add_pro from './components/products/digital/digital-add-pro';

//Sales
import Orders from './components/sales/orders';
import Transactions_sales from './components/sales/transactions-sales';
//Coupons
import ListCoupons from './components/coupons/list-coupons';
import Create_coupons from './components/coupons/create-coupons';

//Pages
import ListPages from './components/pages/list-page';
import Create_page from './components/pages/create-page';
import Media from './components/media/media';
import List_menu from './components/menus/list-menu';
import Create_menu from './components/menus/create-menu';
import List_user from './components/users/list-user';
import Create_user from './components/users/create-user';

import List_vendors from './components/vendors/list-vendors';
import Create_vendors from './components/vendors/create.vendors';
import Translations from './components/localization/translations';
import Rates from './components/localization/rates';
import Taxes from './components/localization/taxes';
import Profile from './components/settings/profile';
import Reports from './components/reports/report';
import Invoice from './components/invoice';
import Datatable from './components/common/datatable'
import Login from './components/auth/login';

///Se importa cierre de sesion y verificacion de cuenta ICreapp
import Logout from './components/auth/logout';
import Login_request from './components/auth/login_request'
import Login_forgot from './components/auth/login_forgot'

///Componentes para manejo de Servicios o agendas 
import ActiveServices from './components/services/active_services';
import AcceptServices from './components/services/accepting_services';
import DeliveryServices from './components/services/delivery_services';
import DayServices from './components/services/day_services';
import ManualServices from './components/services/manual_services';

///Componentes para manejo de comercios
import Commerce_groups from './components/commerce/groups'
import Commerce_category from './components/commerce/category'
import Commerce_subcategory from './components/commerce/sub_category'

//Componentes para manejo de usuarios
import User_list from './components/users/user_list'
import User_request from './components/users/user_request'

//Componentes para manejo de notificaciones comercios
import EmailEditorTemplate from './components/notifications/edit-email-template';
import ListEmailTemplate from './components/notifications/list_email_template';
import AdminEmailTemplate from './components/notifications/admin_email_template';

//Componentes para manejo de stores
import Data_Store from './components/stores/data_store'
import View_Store from './components/stores/view_store'
import List_store from './components/stores/list_store'

//Componentes manejo de Directorio de comercios
import List_directory_store from './components/stores/list_directory_store'
import List_category_directory_store from './components/stores/list_category_directory_store'


//Componentes para manejo de Ofertas
import Bidding_made from './components/bidding/bidding_made'
import Bidding_apply from './components/bidding/bidding_apply'
import Bidding_suggest from './components/bidding/bidding_suggest'
import Bidding_postulation from './components/bidding/bidding_postulation'
import Bidding_active from './components/bidding/bidding_active'
import Bidding_cancel from './components/bidding/bidding_cancel'
import Bidding_finish from './components/bidding/bidding_finalized'

//Componentes de empleo
import RegisterBiddingsEmploy from './components/employ/register_biddings'
import UpdateBiddingsEmploy from './components/employ/update_biddings'
import BiddingRegisterList from './components/employ/bidding_register_list'
import HandlerEmployPost from './components/employ/handler_post'


///Archivos
import Public_files from './components/media/public_files'

///Archivos
import Publication_apps from './components/publication/publication_app'


////Archivos de ayuda
import Help_store from './components/help/help_store'
import TermCond from './components/help/termcond'

////Manejo de notificaciones
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
////////////////////////////


class Root extends Component {
    render() {
        return (
            <div className="app-container">
                <ReactNotification />                            
                <BrowserRouter basename={'/'}>                
                    <ScrollContext>
                        <Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                            <Route path={`${process.env.PUBLIC_URL}/auth/login_request:id`} component={Login_request} />
                            <Route exact path={`${process.env.PUBLIC_URL}/auth/login_forgot`} component={Login_forgot} />                        
                            <Route exact path={`${process.env.PUBLIC_URL}/auth/login`} component={Login} />        

                            {/*Registro de ofertas de empleo*/}
                            <Route exact path={`${process.env.PUBLIC_URL}/employ/register_bidding`} component={RegisterBiddingsEmploy} />                                    
                            

                            {/*Manejo de terminos y condiciones*/}                    
                            <Route exact path={`${process.env.PUBLIC_URL}/TermCond`} component={TermCond} />                                                                
                            
                            <App>                            
                                <Route exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />                                                                                                    
                                <Route exact path={`${process.env.PUBLIC_URL}/dashboard_employ`} component={DashboardEmploy} />                                                                                                    

                                
                                
                                {/*Routes users */}                                                            
                                <Route exact path={`${process.env.PUBLIC_URL}/users/list-user`} component={List_user} />
                                <Route exact path={`${process.env.PUBLIC_URL}/users/create-user`} component={Create_user} />

                                {/*Routes notification*/}    
                                <Route exact path={`${process.env.PUBLIC_URL}/notification/list_email_templates`} component={ListEmailTemplate} />
                                <Route exact path={`${process.env.PUBLIC_URL}/notification/email-editor`} component={EmailEditorTemplate} />
                                <Route exact path={`${process.env.PUBLIC_URL}/notification/admin-email-template`} component={AdminEmailTemplate} />
                                

                                {/*Routes stores */}                                                            
                                <Route exact path={`${process.env.PUBLIC_URL}/store/data_store`} component={Data_Store} />
                                <Route exact path={`${process.env.PUBLIC_URL}/store/view_store`} component={View_Store} />
                                <Route exact path={`${process.env.PUBLIC_URL}/store/list_store`} component={List_store} />                                

                                {/*Routes directory stores */}                                                            
                                <Route exact path={`${process.env.PUBLIC_URL}/store/list_directory_store`} component={List_directory_store} />
                                <Route exact path={`${process.env.PUBLIC_URL}/store/list_category_directory_store`} component={List_category_directory_store} />

                                
                                {/*Routes Ofertas Laborales*/}                                                            
                                <Route exact path={`${process.env.PUBLIC_URL}/employ/bidding_register_list`} component={BiddingRegisterList} />
                                <Route exact path={`${process.env.PUBLIC_URL}/employ/handler_employ_post`} component={HandlerEmployPost} />
                                <Route exact path={`${process.env.PUBLIC_URL}/employ/update_bidding`} component={UpdateBiddingsEmploy} />        
                                
                                

                                {/*Routes biddings u ofertas*/}                                                            
                                <Route exact path={`${process.env.PUBLIC_URL}/bidding/bidding_made`} component={Bidding_made} />
                                <Route exact path={`${process.env.PUBLIC_URL}/bidding/bidding_apply`} component={Bidding_apply} />
                                <Route exact path={`${process.env.PUBLIC_URL}/bidding/bidding_suggest`} component={Bidding_suggest} />
                                <Route exact path={`${process.env.PUBLIC_URL}/bidding/bidding_postulation`} component={Bidding_postulation} />
                                <Route exact path={`${process.env.PUBLIC_URL}/bidding/bidding_active`} component={Bidding_active} />
                                <Route exact path={`${process.env.PUBLIC_URL}/bidding/bidding_cancel`} component={Bidding_cancel} />
                                <Route exact path={`${process.env.PUBLIC_URL}/bidding/bidding_finish`} component={Bidding_finish} />
                                
                                {/*Routes files o Archivos*/}                                                            
                                <Route exact path={`${process.env.PUBLIC_URL}/media`} component={Media} />
                                <Route exact path={`${process.env.PUBLIC_URL}/public_files`} component={Public_files} />

                                <Route exact path={`${process.env.PUBLIC_URL}/Help_store`} component={Help_store} />

                                <Route exact path={`${process.env.PUBLIC_URL}/publication_app`} component={Publication_apps} />
                                
                                
                                {/* se commentarean rutas para no tener accesos erroneos a rutas no configuradas

                                <Route path={`${process.env.PUBLIC_URL}/products/physical/category`} component={Category} />
                                <Route path={`${process.env.PUBLIC_URL}/products/physical/sub-category`} component={Sub_category} />
                                <Route path={`${process.env.PUBLIC_URL}/products/physical/product-list`} component={Product_list} />
                                <Route path={`${process.env.PUBLIC_URL}/products/physical/product-detail`} component={Product_detail} />
                                <Route path={`${process.env.PUBLIC_URL}/products/physical/add-product`} component={Add_product} />
                                
                                <Route path={`${process.env.PUBLIC_URL}/products/digital/digital-category`} component={Digital_category} />
                                <Route path={`${process.env.PUBLIC_URL}/products/digital/digital-sub-category`} component={Digital_sub_category} />
                                <Route path={`${process.env.PUBLIC_URL}/products/digital/digital-product-list`} component={Digital_pro_list} />
                                <Route path={`${process.env.PUBLIC_URL}/products/digital/digital-add-product`} component={Digital_add_pro} />

                                <Route path={`${process.env.PUBLIC_URL}/sales/orders`} component={Orders} />
                                <Route path={`${process.env.PUBLIC_URL}/sales/transactions`} component={Transactions_sales} />

                                <Route path={`${process.env.PUBLIC_URL}/coupons/list-coupons`} component={ListCoupons} />
                                <Route path={`${process.env.PUBLIC_URL}/coupons/create-coupons`} component={Create_coupons} />

                                <Route path={`${process.env.PUBLIC_URL}/pages/list-page`} component={ListPages} />
                                <Route path={`${process.env.PUBLIC_URL}/pages/create-page`} component={Create_page} />

                                <Route path={`${process.env.PUBLIC_URL}/media`} component={Media} />

                                <Route path={`${process.env.PUBLIC_URL}/menus/list-menu`} component={List_menu} />
                                <Route path={`${process.env.PUBLIC_URL}/menus/create-menu`} component={Create_menu} />

                                <Route path={`${process.env.PUBLIC_URL}/users/list-user`} component={List_user} />
                                <Route path={`${process.env.PUBLIC_URL}/users/create-user`} component={Create_user} />

                                <Route path={`${process.env.PUBLIC_URL}/vendors/list_vendors`} component={List_vendors} />
                                <Route path={`${process.env.PUBLIC_URL}/vendors/create-vendors`} component={Create_vendors} />

                                <Route path={`${process.env.PUBLIC_URL}/localization/transactions`} component={Translations} />
                                <Route path={`${process.env.PUBLIC_URL}/localization/currency-rates`} component={Rates} />
                                <Route path={`${process.env.PUBLIC_URL}/localization/taxes`} component={Taxes} />

                                <Route path={`${process.env.PUBLIC_URL}/reports/report`} component={Reports} />

                                <Route path={`${process.env.PUBLIC_URL}/settings/profile`} component={Profile} />

                                <Route path={`${process.env.PUBLIC_URL}/invoice`} component={Invoice} />

                                <Route path={`${process.env.PUBLIC_URL}/data-table`} component={Datatable} />


                                */}

                                

                                {/* =================Routes creados aparte de la plantilla =========================*/}
                                
                                {/*Routes login */}
                                <Route exact path={`${process.env.PUBLIC_URL}/auth/logout`} component={Logout} />                                
                                
                                {/*Routes users*/}
                                <Route exact path={`${process.env.PUBLIC_URL}/users/user_list`} component={User_list} />
                                <Route exact path={`${process.env.PUBLIC_URL}/users/user_request`} component={User_request} />

                                {/*Routes servicios */}                                                            
                                <Route exact path={`${process.env.PUBLIC_URL}/services/active_services`} component={ActiveServices} />
                                <Route exact path={`${process.env.PUBLIC_URL}/services/accept_services`} component={AcceptServices} />
                                <Route exact path={`${process.env.PUBLIC_URL}/services/delivery_services`} component={DeliveryServices} />
                                <Route exact path={`${process.env.PUBLIC_URL}/services/day_services`} component={DayServices} />
                                <Route exact path={`${process.env.PUBLIC_URL}/services/manual_services`} component={ManualServices} />

                                {/*Routes Comercios */}
                                <Route exact path={`${process.env.PUBLIC_URL}/commerce/category`} component={Commerce_category} />
                                <Route exact path={`${process.env.PUBLIC_URL}/commerce/subcategory`} component={Commerce_subcategory} />
                                <Route exact path={`${process.env.PUBLIC_URL}/commerce/group`} component={Commerce_groups} />
                                

                            </App>
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </div>  
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));

import {
    Home,
    Box,
    DollarSign,
    Calendar,
    Tag,
    Clipboard,
    Camera,
    AlignLeft,
    User,
    UserPlus,
    Users,
    Chrome,
    ShoppingCart,
    HelpCircle,
    Bell,
    Share2,
    BarChart,
    Settings,
    Archive, 
    LogIn, 
    List,
    Layout
    
} from 'react-feather';

export const MENUITEMS_EMPLOYMENT = [
    
    {
        path: '/dashboard_employ', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
    },
    /*
    {
        title: 'Ofertas', icon: DollarSign, type: 'sub', active: false, children: [            
            { path: '/services/accept_services', title: 'Sin Aceptar', type: 'link' },
            { path: '/services/delivery_services', title: 'Sin Akualiver', type: 'link' },
            { path: '/services/active_services', title: 'Activos', type: 'link' },
            { path: '/services/day_services', title: 'Del dia', type: 'link' },
            { path: '/services/manual_services', title: 'Manual', type: 'link' }
        ]
    },
    */    


    {
        title: 'Ofertas', icon: List, type: 'sub', active: false, children: [                        
            { path: '/employ/bidding_register_list', title: 'Registradas', type: 'link' },
            //{ path: '/commerce/category', title: 'Aceptadas', type: 'link' },
            //{ path: '/commerce/subcategory', title: 'Vencidas', type: 'link' },        
            { path: '/employ/register_bidding', title: 'Registrar Oferta', type: 'link' }          
        ]
    },      

    /*
    {
        title: 'Notificaciones', icon: Bell, type: 'sub', active: false, children: [
            { path: '/notification/list_email_templates', title: 'Enviar Emails', type: 'link' },            
            { path: '/notification/list_email_templates', title: 'Templates', type: 'link' },            
            { path: '/notification/list_email_templates', title: 'Historial', type: 'link' }            
            //{ path: '/users/create-user', title: 'Create User', type: 'link' }
        ]
    },
    */


    /*
    {
        title: 'Usuarios', icon: UserPlus, type: 'sub', active: false, children: [
            { path: '/users/user_list', title: 'Lista de Usuarios', type: 'link' },
            { path: '/users/user_request', title: 'Solicitud de Usuario', type: 'link' },
            //{ path: '/users/email-editor', title: 'Emails Usuarios', type: 'link' }
            //{ path: '/users/create-user', title: 'Create User', type: 'link' }
        ]
    },
    */
    
    /*
    {
        title: 'Agenda', icon: Calendar, type: 'sub', active: false, children: [            
            { path: '/services/accept_services', title: 'Sin Aceptar', type: 'link' },
            { path: '/services/delivery_services', title: 'Sin Akualiver', type: 'link' },
            { path: '/services/active_services', title: 'Activos', type: 'link' },
            { path: '/services/day_services', title: 'Del dia', type: 'link' },
            { path: '/services/manual_services', title: 'Manual', type: 'link' }
        ]
    },
    */    
    /*
    {
        title: 'Productos', icon: Box, type: 'sub', active: false, children: [
            {
                title: 'Servicios', type: 'sub', active: false, children: [
                    { path: '/products/physical/category', title: 'Category', type: 'link' },
                    { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
                    { path: '/products/physical/product-list', title: 'Product List', type: 'link' },
                    { path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
                    { path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
                ]
            },
            {
                title: 'Fisicos', type: 'sub', active: false, children: [
                    { path: '/products/physical/category', title: 'Category', type: 'link' },
                    { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
                    { path: '/products/physical/product-list', title: 'Product List', type: 'link' },
                    { path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
                    { path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
                ]
            },
            {
                title: 'Digitales', type: 'sub', active: false, children: [
                    { path: '/products/digital/digital-category', title: 'Category', type: 'link' },
                    { path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
                    { path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
                    { path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
                ]
            },
        ]
    },
    */
    /*
    {
        title: 'Ventas', icon: DollarSign, type: 'sub', active: false, children: [
            { path: '/sales/orders', title: 'Ordenes', type: 'link' },
            { path: '/sales/transactions', title: 'Transacciones', type: 'link' },
        ]
    },
    {
        title: 'Cupones', icon: Tag, type: 'sub', active: false, children: [
            { path: '/coupons/list-coupons', title: 'Lista de Cupones', type: 'link' },
            { path: '/coupons/create-coupons', title: 'Crear Cupones', type: 'link' },
        ]
    },
    {
        title: 'Referidos', icon: Share2, type: 'sub', active: false, children: [
            { path: '/coupons/list-coupons', title: 'Lista Referidos', type: 'link' },
            { path: '/coupons/create-coupons', title: 'Crear Referidos', type: 'link' },
        ]
    },
    */
    /*
    {
        title: 'Pages', icon: Clipboard , type: 'sub', active: false, children: [
            { path: '/pages/list-page', title: 'List Page', type: 'link' },
            { path: '/pages/create-page', title: 'Create Page', type: 'link' },
        ]
    },
    {
        title: 'Media', path: '/media', icon: Camera, type: 'link', active: false
    },
    {
        title: 'Menus', icon: AlignLeft, type: 'sub', active: false, children: [
            { path: '/menus/list-menu', title: 'List Menu', type: 'link' },
            { path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
        ]
    },
    */
    
    /*
    {
        title: 'Users', icon: UserPlus, type: 'sub', active: false, children: [
            { path: '/users/list-user', title: 'User List', type: 'link' },
            { path: '/users/create-user', title: 'Create User', type: 'link' },
        ]
    },
    */
    /*
    {
        title: 'Akualivers', icon: Users, type: 'sub', active: false, children: [
            { path: '/vendors/list_vendors', title: 'Vendor List', type: 'link' },
            { path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
        ]
    },
    {
        title: 'Localization', icon: Chrome, type: 'sub', children: [
            { path: '/localization/transactions', title: 'Translations', type: 'link' },
            { path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
            { path: '/localization/taxes', title: 'Taxes', type: 'link' }
        ]
    },
    {
        title: 'Reports',path:'/reports/report', icon: BarChart, type: 'link', active: false
    },
    {
        title: 'Settings', icon: Settings, type: 'sub', children: [
            { path: '/settings/profile', title: 'Profile', type: 'link' },
        ]
    },
    {
        title: 'Invoice',path:'/invoice', icon: Archive, type: 'link', active: false
    },
    */
    {
        title: 'Ayuda',path:'/Help_store', icon: HelpCircle, type: 'link', active: false
    },    
    {
        title: 'Cerrar Sesion',path:'/auth/logout', icon: LogIn, type: 'link', active: false
    }
]

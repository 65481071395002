import React from 'react';
export const data =[
    {
        title: "Us Dollar",
        usd : "2.4512478",
        code: "USD",
        rate: "0.57912364",
    },
    {
        title: "Afghanistan Afghani",
        usd : "5.14761214",
        code: "AFN",
        rate: "1.05761432",
    },
    {
        title: "United Arab Emirates Dirham",
        usd : "12.02646581",
        code: "AED",
        rate: "0.15642465",
    },
    {
        title: "European Union",
        usd : "8.124712425",
        code: "EUR",
        rate: "0.187541231",
    },
    {
        title: "Japanese",
        usd : "10.78422121",
        code: "JPY",
        rate: "3.8425110221",
    },
    {
        title: "Great Britain Pound",
        usd : "7.1246121152",
        code: "GBP",
        rate: "0.25522648",
    }
]

export default data
import {
    Home,
    Box,
    DollarSign,
    Calendar,
    Tag,
    Clipboard,
    Camera,
    AlignLeft,
    User,
    UserPlus,
    Users,
    Chrome,
    ShoppingCart,    
    HelpCircle,
    Share2,
    BarChart,Settings,Archive, LogIn
} from 'react-feather';

export const MENUITEMS = [       
        
    {
        title: 'Perfil', icon: User, type: 'sub', active: false, children: [            
            { path: '/store/data_store', title: 'Datos Perfil', type: 'link' }            
        ]
    },
    /* Se cambia para divirdirse en dos menus  
    {
        title: 'Ofertas', icon: ShoppingCart, type: 'sub', active: false, children: [            
            { path: '/bidding/bidding_made', title: 'Ofertas Realizadas', type: 'link' },
            { path: '/bidding/bidding_suggest', title: 'Ofertas Recomendadas', type: 'link' },
            //{ path: '/bidding/bidding_made', title: 'Ofertas Publicas', type: 'link' }            
            { path: '/bidding/bidding_apply', title: 'Ofertas Aplicadas', type: 'link' }            
        ]
    },
    */    
    {
        title: 'Compra PYS', icon: ShoppingCart, type: 'sub', active: false, children: [            
            { path: '/bidding/bidding_made', title: 'Requerimientos', type: 'link' }            
        ]
    }, 
    {
        title: 'Ofertas', icon: DollarSign, type: 'sub', active: false, children: [                        
            { path: '/bidding/bidding_suggest', title: 'Ofertas Recomendadas', type: 'link' },
            //{ path: '/bidding/bidding_made', title: 'Ofertas Publicas', type: 'link' }            
            { path: '/bidding/bidding_apply', title: 'Ofertas Aplicadas', type: 'link' }            
        ]
    },
    {
        title: 'Invitar Usuarios', icon: UserPlus, type: 'sub', active: false, children: [
            //{ path: '/users/user_list', title: 'Lista de Usuarios', type: 'link' },
            { path: '/users/user_request', title: 'Solicitud de Usuario', type: 'link' }            
        ]
    },            
    {
        title: 'Ayuda',path:'/Help_store', icon: HelpCircle, type: 'link', active: false
    },
    {
        title: 'Volver al Comercio',path:'/', icon: Chrome, type: 'link', active: false
    },
    {
        title: 'Cerrar Sesion',path:'/auth/logout', icon: LogIn, type: 'link', active: false
    }
]

import React, { Component, Fragment } from 'react'
import LoginTabset from './loginTabset';
import { ArrowLeft, Bold, Sliders } from 'react-feather';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import stats from '../../assets/images/dashboard/stats.png';

import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Unlock } from 'react-feather';

///Librerias utilizadas IcreApp
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import DecoderData from '../../hooks/DecoderData'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
///////////////////////////////


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ConsoleData from '../../hooks/ConsoleData';

//const image_logo = icreappconfig.url_images+"iconos/Icono_logo.jpg";
const image_logo = icreappconfig.url_images+"iconos/logo_color.png";

export class Login_request extends Component {    

    constructor(props) {
        super(props);
        
        
        const { match: { params } } = this.props;
        //console.log(params.id)       
        let id_transaction = params.id;

        this.state = {
            Action: 'A',
            Action_Form: '',
            Name_user: "",
            Email_user: "",
            Man_login: false,
            Login_user: false,
            Msg_load: "",
            loading: true,
            Transaction: id_transaction.replace("=",""),
            SessionState: false,
            SessionMessage: ''
        }

        /*
        this.state = {
            activeShow: true,
            startDate: new Date()
        }
        */
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.GetDataSession = this.GetDataSession.bind(this);
        //this.Func_login = this.Func_login.bind(this);
        
    }

    componentDidMount(){        
        this.GetDataUser();                
    }

    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            [e.target.name]: e.target.value
        })
        
    }

    handleSubmit = async e => {
        //console.log("Submit")
        ConsoleData("Submit");

        e.preventDefault();                 

        ///Se validan que las contraseñas sean de mas de 5 caracteres
        let contrasenna = this.state.Pasw;
        let error_val = false;

        if(contrasenna.length > 5 && contrasenna.length < 15){
            let mayuscula = false;
			let minuscula = false;
            let numero = false;            
            
            for(let i = 0;i<contrasenna.length;i++){
                if(contrasenna.charCodeAt(i) >= 65 && contrasenna.charCodeAt(i) <= 90)
                {
                    mayuscula = true;
                }
                else if(contrasenna.charCodeAt(i) >= 97 && contrasenna.charCodeAt(i) <= 122)
                {
                    minuscula = true;
                }
                else if(contrasenna.charCodeAt(i) >= 48 && contrasenna.charCodeAt(i) <= 57)
                {
                    numero = true;
                }                
            }

            if( mayuscula && minuscula && numero ){
                
            }else{
                toast.error( "Recuerda que la contraseña debe tener mínimo una mayúscula, una minúscula y un numero.");    
                error_val=true;
            }
            
        }else{
            toast.error( "Las contraseña debe tener mas de 5 caracteres y menos de 15");
            error_val=true;
        }

        ///Se validan las contraseñas que sean iguales 
        if(this.state.Pasw != this.state.Conf_Pasw){
            toast.error( "Las contraseñas ingresadas no son iguales");
            error_val=true;
        }      
        
        ///se valida que no venga ningun error en la asignacion de contraseñas
        if(error_val){
            return;
        }
        
        this.setState({
            loading: true
        })

        try {

            
           const Urlquery = icreappconfig.url_controller+"Reg_UserLogin.php";
   
           const Params = {
                Action : this.state.Action_Form,
                Transaction: this.state.Transaction,                
                Pasw : this.state.Pasw
           }
   
           axios.post(Urlquery,Params)
                .then(res => {
                   
                   if(res.data[0].Status){
                        this.setState({
                            loading: false,
                            error: false
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                       
                        toast.success(decoder);                                                

                        //Se registran los datos de inicio de sesion
                        let expirationDate = new Date(new Date().getTime() + (60000 * icreappconfig.sessionExpiration) );
                        let ValueData = {
                            data: res.data,
                            expirationDate: expirationDate.toISOString()
                        }

                        sessionStorage.setItem('IcreapSessionData', JSON.stringify(ValueData));

                        setTimeout(() => {
                            this.routeChange();
                        }, 300);                        
                        ////////////////////////////////
                                                
                   }else{  
                    //alert(res.data[0].Msg); 
                    //let decoder = decodeURIComponent(escape(res.data[0].Msg));                       
                    let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                       
                    toast.error( decoder );
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: res.data[0].Msg
                        })
                    
                   }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            ////this.props.history.push('/exercise')

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        

    }

    routeChange = () => {
        //Se cambia a ruta temporal mientras se define dashboard
        //this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
        //this.props.history.push(`${process.env.PUBLIC_URL}/commerce/category`);

        let SessionDataValue = sessionStorage.getItem("IcreapSessionData");
        let DataValue = JSON.parse(SessionDataValue);  
        if(parseInt(DataValue.data[0].Tipo) <= 2){
            this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
        }else if(parseInt(DataValue.data[0].Tipo)<9 && parseInt(DataValue.data[0].Tipo)>2){
            this.props.history.push(`${process.env.PUBLIC_URL}/dashboard_employ`);
        }else{
            this.props.history.push(`${process.env.PUBLIC_URL}/store/data_store`);
        }
    }

    loginrouteChange = () => {
        //Se cambia a ruta temporal mientras se define dashboard
        //this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
        this.props.history.push(`${process.env.PUBLIC_URL}/auth/logout`);
    }
    GetDataUser = () => {
        
        ConsoleData("GetDataUser");        
        
        const Urlquery = icreappconfig.url_controller+"LoadUsers.php";
        ConsoleData(Urlquery);        

        const Params = {
            Action: this.state.Action,
            Transaction: this.state.Transaction            
        }

        ///Se reinician estados para control de datos
        this.setState({       
            Man_login: false,     
            loading: true,
            Login_user: false,
            Msg_load: ""            
        })

        ConsoleData(Params); 
        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    ConsoleData(res.data);   
                    let Data_Name_user = "";
                    let Data_Email_user = "";
                    let Data_StatusTrans = "";

                    if(res.data[0].Status){
                        
                        res.data[0].Data.map( (CurrentData,i) => {                                                        
                            ConsoleData(CurrentData);
                            Data_Name_user = CurrentData.Name;
                            Data_Email_user = CurrentData.Email;  
                            Data_StatusTrans = CurrentData.StatusT;                            
                        })
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                       
                        this.setState({
                            Name_user: Data_Name_user,
                            Email_user: Data_Email_user,
                            Action_Form: Data_StatusTrans,
                            Man_login: res.data[0].Status,
                            loading: false,
                            Login_user: res.data[0].Login,
                            Msg_load: decoder                                                         
                        })     
                        
                        
                    }else{     

                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));                       
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        this.setState({
                            Name_user: Data_Name_user,
                            Email_user: Data_Email_user,
                            Action_Form: Data_StatusTrans,
                            Man_login: res.data[0].Status,
                            loading: false,
                            Login_user: res.data[0].Login,
                            Msg_load: decoder                                                         
                        })                                            
                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,                            
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false
        };

        let Body_panel;

        if(this.state.loading){
            Body_panel = <Loading />;
        }else{
            if(!this.state.Man_login){
                Body_panel = <h6>{this.state.Msg_load}</h6>
            }else{
                if(!this.state.Login_user){

                    Body_panel =<div>
                                    <div className="form-terms">                                                                                                                                                                                                                                                                                                                                                        
                                        <div className="text-center">
                                            <span > 
                                                <h5>{this.state.Msg_load}</h5>
                                            </span>
                                        </div>                                                                                                                                        
                                    </div>

                                    <div className="form-button">
                                        <button className="btn btn-warning" onClick={this.loginrouteChange}>Ir a Login</button>
                                    </div>

                                </div>;

                }else{
                    ConsoleData("Ingreso a BadyPanel New PAsword");

                    Body_panel = <form className="form-horizontal auth-form" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <input required name="Email_user" type="email" className="form-control" placeholder="Email" onChange={this.handleChange} value={this.state.Email_user} disabled />
                            </div>
                            <div className="form-group">
                                <input required name="Pasw" type="password" className="form-control" placeholder="Password" onChange={this.handleChange} value={this.state.password}/>
                            </div>                                                                
                            <div className="form-group">
                                <input required name="Conf_Pasw" type="password" className="form-control" placeholder="Confirm Password" onChange={this.handleChange} value={this.state.password}/>
                            </div>
                            
                            <div className="form-terms">                                                                                                                                                                                                                                                                                                                                                        
                                <div className="text-center">
                                    <span > 
                                        <h4 style={{color: "#dc3545"}}>{this.state.SessionMessage}</h4>
                                    </span>
                                </div>                                                                                                                                        
                            </div>
                            
                            <div className="form-terms">                                                                                                                                                                                                                                                                                                                                                        
                                <div className="text-center">
                                    <span > 
                                        <h6 >La contraseña debe tener mínimo 6 caracteres, una mayúscula, una minúscula y un numero.</h6>
                                    </span>
                                </div>                                                                                                                                        
                            </div>
                            
                            <div className="form-button">
                                <button className="btn btn-primary" type="submit">Login</button>
                            </div>
                            
                            <div className="form-footer">                                                                                                                                         
                            </div>
                        </form>;

                }

            }            
        }


        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 p-0 card-left">
                                    <div className="card bg-primary">
                                        
                                        {
                                            /*
                                            <div className="svg-icon">
                                                <img src={image_logo} className="Img-fluid" />
                                            </div>
                                             */
                                        }
                                        
                                        <div className="container" >
                                                <img src={image_logo} className="img-responsive" height="180" width="200" style={{display: "block", margin: "auto" }} />
                                        </div>
                                        
                                        <Slider className="single-item" {...settings}>
                                            <div>
                                                <div>
                                                    <h3 style={{color: "#095f28"}}>Bienvenido <br />{this.state.Name_user}<br /> a MosquerApp</h3>
                                                    <p style={{color: "#095f28"}}>Acá encontraras todo lo que necesitas</p>
                                                </div>
                                            </div>                                                                                        
                                        </Slider >
                                    </div>
                                </div>
                                <div className="col-md-7 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <div>
                                                <Fragment>
                                                    <Tabs>
                                                        <TabList className="nav nav-tabs tab-coupon" >
                                                            <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><User />Login</Tab>                                                            
                                                        </TabList>

                                                        <TabPanel>
                                                            {Body_panel}
                                                        </TabPanel>
                                                                                                                
                                                    </Tabs>
                                                </Fragment>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                                                                                    
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </Fragment>
        )
    }
}

export default Login_request

import React, { useState } from 'react'
import Loading from '../../Loading'
import icreappconfig from '../../../Icreappconfig'
import user from '../../../assets/images/dashboard/user.png';

import ConsoleData from '../../../hooks/ConsoleData';
import DecoderData from '../../../hooks/DecoderData';
import { Paperclip,User } from 'react-feather';

import classNames from 'classnames';
import './Message.scss';
import './MessageList.scss'

const updateScroll = () => {    
    try {        
        setTimeout(() => {
            const objDiv = document.getElementById('chat-message-list');
            if(objDiv!=null){
                objDiv.scrollTop = objDiv.scrollHeight;                            
            }            
        }, 400);                
    } catch (error) {
        ConsoleData(error);
    }    
}

const isMessageEmpty = (textMessage) => {
    return adjustTextMessage(textMessage).length === 0;
}

const adjustTextMessage = (textMessage) => {
    return textMessage.trim();
};

const Chat_data = ({data,rem}) => {    
    
    ConsoleData("Chat_data")
    ConsoleData(data);    

    try {

        return (        
            data.map( (CurrentData,index) => {
             
                const isMyMessage = ( CurrentData.owner=="P" ? true : false);
    
                const messageClass = classNames('message-row', {
                    'you-message': isMyMessage,
                    'other-message': !isMyMessage
                });
    
                ////Busca valor dentro del objeto
                let Logo = null;
                for (var prop in rem) { 
                                    
                    if(rem[prop].user_chat == CurrentData.id_uchat ){
                        Logo = rem[prop].logo;
                    }
                }
                
                ///////////////////////////////////////
                ConsoleData(Logo.length);
    
                const imageThumbnail = (isMyMessage ? null : (Logo.length>0 ? <img src={icreappconfig.url_platform+Logo} /> : <User/>  ));
    
    
                ///Se actualiza visualizacion para que aparezca al final
                if(data.length -1 == index){
                    //do what you want to do here may be a flag set here
                    updateScroll();
                }
    
                return(
                    <div className={messageClass} key={index}>
                        <div className="message-content">
                            {imageThumbnail}
                            <div className="message-text">
                                {DecoderData({Data: CurrentData.msg, Type: "S"})}
                            </div>
                            <div className="message-time">{CurrentData.date_create}</div>
                        </div>
                    </div>
                );
            })
        );
        
    } catch (error) {
        ConsoleData(error);    
        return <div></div>;

    }
    
}


const Modal_Sidebar= ({data, rem, loading, eventfile, eventchat}) => {
    
    const [textMessage, setTextMessage] = useState('');
    const disableButton = isMessageEmpty(textMessage);    

    ConsoleData("Body_Sidebar");
    ConsoleData(data);
    

    if(loading){
        return <Loading />;
    }        

    return (        
        <>
            <div className="modal-body" >
                <div className="row" style={{margin:10}} >     
                    <div id="chat-message-list" >
                        <Chat_data data={data} rem={rem} />                           
                    </div>    
                </div>                         
            </div>                         
            <div className="modal-footer">                
                <div className="input-group mb-3">
                    {/*
                        <div title="Adjuntar archivo">
                            <Paperclip />
                        </div>
                    */}                    
                    <input 
                        type="text" 
                        lang="es"
                        className="form-control" 
                        placeholder="Ingrese el mensaje a enviar"
                        name="text"
                        value={textMessage}
                        onChange={ (e) => { setTextMessage(e.target.value); } } 
                    />
                    <button 
                        type="button" 
                        disabled={disableButton} 
                        onClick={()=> {eventchat(textMessage);setTextMessage("");}} 
                        className="btn btn-primary">Enviar</button>    
                </div>
            </div>
        </>    
    )

}

export default Modal_Sidebar

/* custom Hook para decodificar y dar formato a datos*/
import React from 'react';
import icreappconfig from '../Icreappconfig'
import ConsoleData from './ConsoleData';


const formatterPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
})


const DecoderData = props => {
    let DataReturn = "";
    //ConsoleData("DecoderData");
    //ConsoleData(props);

    try{
        if(props.Type=="S"){ ///Tipo String
            DataReturn = props.Data;
            DataReturn = DataReturn.replace("´","'");
            DataReturn = decodeURIComponent(escape(DataReturn));        
        }else if(props.Type=="C"){///Tipo currency o moneda            
            DataReturn = formatterPeso.format(props.Data);                
        }
    }catch(e){
        //console.log(e);
        //ConsoleData(e);
        
        DataReturn = props.Data;
    }
        
    return DataReturn;
}


export default DecoderData;
import React, { Component, Fragment } from 'react'
import BiddingTabset from './bidding_tabset';
import { ArrowLeft, Sliders } from 'react-feather';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import stats from '../../assets/images/dashboard/stats.png';

import icreappconfig from '../../Icreappconfig'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//const image_logo = icreappconfig.url_images+"iconos/Icono_logo.jpg";
const image_logo = icreappconfig.url_images+"iconos/logo_color.png";

export class RegisterBiddingsEmploy extends Component {    

    
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false
        };
        return (
            <Fragment>
                <div className="page-wrapper" style={{ backgroundImage: `url("https://emprendimiento.mosquerapp.com/wp-content/uploads/2021/09/VALOR3-Transparen4.png")`  }}>
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0">

                                    <div className="card bg-primary mt-3">
                                        {
                                            /*
                                            <div className="svg-icon">
                                                <img src={image_logo} className="Img-fluid" />
                                            </div>
                                             */
                                        }
                                        
                                        <div className="container" >
                                                <img src={image_logo} className="img-responsive" height="200" width="250" style={{display: "block", margin: "auto" }} />
                                        </div>
                                        
                                        <Slider className="single-item" {...settings}>
                                            <div>
                                                <div>
                                                    <h3 style={{color: "#095f28"}} >Bienvenido a MosquerApp</h3>
                                                    <p style={{color: "#095f28"}}>Acá podras registrar tus ofertas de empleo</p>
                                                </div>
                                            </div>
                                            {
                                                /* Se comentarea para posterior uso                                            
                                                    <div>
                                                        <div>
                                                            <h3>Dashboar de tu empresa</h3>
                                                            <p>Encontraras un dashboard con el resumen de todos los movimientos del negocios</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <h3>Productos</h3>
                                                            <p>Podrás administrar todos los productos, servicios, promociones y muchas cosas más.</p>
                                                        </div>
                                                    </div>                                                                                                
                                                */
                                            }
                                            
                                        </Slider >
                                    </div>

                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <BiddingTabset Id_post={null} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <a href={icreappconfig.url_platform+"dashboard_employ"} target="_blank" className="btn btn-primary back-btn"><ArrowLeft />Volver al Comercio</a>
                            
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default RegisterBiddingsEmploy

import React, { Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import ConsoleData from '../../hooks/ConsoleData'
import Activity_finder from '../specific/activity_finder'
import File_upload from './file_upload'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export class View_Store extends Component {
    
    constructor(props) {
        super(props);

        this.GetDataStore = this.GetDataStore.bind(this);        

        this.state = {
            open: false,
            open_mfile: false,
            data_store: [],                         
            Action: "U",
            Action_form: "T",
            loading: true,
            error: true,   
            Msg_load: '',    
            User_email: '',
            User_type: '',     
            Msg_Error: ''
        };
    }
    
    componentDidMount(){
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{            
            this.GetDataSession("IcreapSessionData");
        }        
    }

    onCloseModal = () => {        
        this.setState({            
            open: false,
            form : {
                Status_bidding: ""
            }, 
        });
    }

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })
            
            this.getDataLocalStore();                                    
        }
    }

    getDataLocalStore = () => {
        //this.GetDataStore = this.GetDataStore.bind(this);
        let Data_store = sessionStorage.getItem("Cod_store")        

        if (Data_store !== null) {
            
            ///Se quita para evitar que al recargar se vea doble recarga
            //sessionStorage.removeItem("Data_bidding");
            this.setState({                
                Cod_store : Data_store
            })
            
            setTimeout(() => {
                this.GetDataStore();
            }, 1000);
        }else{                        
            ConsoleData("No se encontraron datos de oferta");
            this.props.history.push(`${process.env.PUBLIC_URL}/bidding/bidding_made`);
        }

    }

    GetDataStore = () => {
        const Urlquery = icreappconfig.url_controller+"AdminStores.php";
        //console.log(Urlquery);        

        const Params = {
            Action: this.state.Action,
            User_email: this.state.User_email,
            User_type: this.state.User_type,
            Cod_store: this.state.Cod_store
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_store : [],
            loading : true,
            error : true,
            Msg_load: ""            
        })

        //console.log(Params); 
        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                        
                        
                        let Man_dataStore = res.data[0].Data_store[0];
                                                                   
                        this.setState({                            
                            data_store : Man_dataStore,                                                      
                            data_groups: [],
                            Msg_load: decoder,
                            loading: false,
                            error: false                        
                        })                    

                    }else{     
                        
                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                

                        this.setState({
                            data_store : [],
                            loading: false,
                            Msg_load: decoder,
                            error: true                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            data_store : [],
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }

    handleSubmit = async e => {
        ConsoleData("Submit")        
        e.preventDefault();  

        try {
            
            //Se hacen validaciones previas
            if(this.state.data_store.Status_change == undefined || this.state.data_store.Status_change == this.state.data_store.Status ){
                toast.error("No se ha seleccionado ningún cambio de estado. Por esto no se puede generar el cambio.");
                return;
            }

            if(this.state.data_store.Desc_Status == undefined || this.state.data_store.Desc_Status.length < 10  ){
                toast.error("Debes ingresar una observación mayor a 10 caracteres.");
                return;
            }

            this.setState({
                loading: true
            })

            const Urlquery = icreappconfig.url_controller+"AdminStores.php";
            ConsoleData(Urlquery);                  

            const Params = {
                Action : this.state.Action_form,
                User_email : this.state.User_email,
                User_type : this.state.User_type,
                Status : this.state.data_store.Status_change,
                Cod_store : this.state.data_store.Cod_store,
                Desc_status : this.state.data_store.Desc_Status
            }

            ConsoleData(Params);  

            axios.post(Urlquery,Params)
                .then(res => {
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        toast.success(decoder);
                        
                        this.GetDataStore();

                    }else{     
                        
                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                
                        toast.error(decoder);

                        this.setState({                            
                            loading: false,
                            Msg_load: decoder,
                            error: true                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({                            
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
                                        
    }

    handleChangeDeta = e => {        
        this.setState({
            data_store:{
                ...this.state.data_store,
                [e.target.name]: e.target.value                
            }            
        })                   
    }
    

    viewDataBody = () => {

        /*
        const handleChangeCheck = this.handleChangeCheck;        
        const viewHandlerBiddingsPost = this.viewHandlerBiddingsPost;        
        const data_biddingpost = this.state.data_biddingpost;        
        */

        const handleSubmit = this.handleSubmit;
        const data_store = this.state.data_store;
        const data_store_obj = [this.state.data_store];
        const data_deta = this.state.data_store.deta;
        const handleChangeDeta = this.handleChangeDeta;

        let LoadCategories=<div></div>;
        let LoadContacts = <div></div>;
        let LoadFilesVerif = <div></div>;
        let HandlerStatus = <div></div>;
        let Man_Catalog = "";

        ConsoleData("viewDataBody")
        ConsoleData(data_store.Status)      
        //ConsoleData(data_store.Desc)      
        
        if(parseInt(this.state.User_type)<9){
            HandlerStatus = <>
                                <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {margin:20} } >
                                    <h3>Cambios de Estado</h3> 
                                </div>
                                <div className="col-xl-12 col-md-12" >
                                    <label htmlFor="recipient-name" className="col-form-label" >Estado del comercio</label>                                                                                
                                    <select className="custom-select" name="Status_change" value={data_store.Status_change == undefined ? data_store.Status : data_store.Status_change} onChange={handleChangeDeta} required >                                                                                            
                                        <option disabled={true} value="E">En espera</option>
                                        <option value="B">Bloqueado</option>
                                        <option value="A">Activo</option>
                                    </select>
                                </div>                
                                <div className="col-xl-12 col-md-12" >
                                    <label htmlFor="recipient-name" className="col-form-label" >Observación del cambio de estado</label>
                                    <textarea className="form-control" name="Desc_Status" rows="3" value={data_store.Desc_Status == undefined ? "" : data_store.Desc_Status} onChange={handleChangeDeta} ></textarea>
                                </div>                
                                <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop:10} }>
                                    <button type="submit" className="btn btn-secondary" >Cambiar Estado</button>
                                </div>                
                            </>    
        }

        if(data_deta.contacts.length > 0){  
            LoadContacts =  <div className="col-xl-12 col-md-12" >                
                                <table className="table table-striped" >
                                    <thead>
                                        <tr>
                                            <th colSpan={4} className="align-self-center text-center"  >Contactos adicionados</th>
                                        </tr>
                                    </thead>  
                                    <tbody>
                                    {
                                        //let Data_AddSubcat = { 'cod_subcat' : this.state.tmp.Cod_subcategory, 'name_subcat' : this.state.tmp.Name_subcategory };                                                                                        
                                        data_deta.contacts.map( (CurrentService,i) => {                            
                                            return (
                                                <tr key={i} >
                                                    <td >{CurrentService.name_contact} </td>
                                                    <td >{CurrentService.phone_contact}</td>                                                        
                                                    <td >{CurrentService.email_contact}</td>                                                                                                            
                                                </tr>
                                            )                                                
                                        })
                                    }
                                    </tbody>  
                                </table>
                            </div>
        }    


        if(data_deta.sub_categories.length > 0){            
            LoadCategories = <div className="col-xl-12 col-md-12">                                        
                                <table className="table table-striped" >
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>C.I.I.U</th>
                                            <th>Nombre</th>                                                                                            
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {
                                            //let Data_AddSubcat = { 'cod_subcat' : this.state.tmp.Cod_subcategory, 'name_subcat' : this.state.tmp.Name_subcategory };
                                            data_deta.sub_categories.map( (CurrentService,i) => {                            
                                                return (
                                                    <tr key={i} >
                                                        <td >{CurrentService.cod_subcat} </td>
                                                        <td >{CurrentService.mcod_subcategory} </td>
                                                        <td >{CurrentService.name_subcat}</td>                                                                                                                    
                                                    </tr>
                                                )                                                
                                            })
                                        }
                                    </tbody>
                                </table> 

                            </div>     

        }

        if(data_deta.Catalogue.length>0){
            Man_Catalog =   <div style={ {marginTop:10} }className="col-xl-12 col-md-12 align-self-center text-center" >
                                <h4>
                                    <a href={icreappconfig.url_platform+data_deta.Catalogue} target="_blank">Ver Catalogo</a>
                                </h4>
                            </div>

        }else{
            Man_Catalog =   <div style={ {marginTop:10} }className="col-xl-12 col-md-12 align-self-center text-center" >
                                <h5>
                                    Aun no se ha cargado ningun catalogo
                                </h5>
                            </div>
        }

        //// 22-01-2021 Se agregan nuevos archivos de verificacion
        if(data_deta.Camara_comercio.length>0 || data_deta.Rut.length>0){
            LoadFilesVerif = <div style={ {marginTop:20} } className="col-xl-12 col-md-12 align-self-center text-center" >
                                    <h5>Archivos de verificación</h5> 

                                    <table className="table " >
                                        <thead></thead>
                                        <tbody> 
                                            <tr><td>Cámara de comercio: </td><td>{data_deta.Camara_comercio.length<=0 ? "No se agrego ningun archivo" : <a href={icreappconfig.url_platform+data_deta.Camara_comercio}  target="_blank">Ver Archivo</a> }</td></tr>
                                            <tr><td>R.U.T: </td><td>{data_deta.Rut.length<=0 ? "No se agrego ningun archivo" : <a href={icreappconfig.url_platform+data_deta.Rut} target="_blank">Ver Archivo</a> }</td></tr>
                                        </tbody>
                                    </table> 
                                </div>  ;
        }


        return data_store_obj.map(function (CurrentData,i){
            return (<div key={i}>
                        <form onSubmit={handleSubmit} >                            
                            <div style={ {margin:20} }>
                                <div className="row">      
                                    <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginBottom:20} } >
                                        <h3>Datos del perfil</h3> 
                                    </div>

                                    <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginBottom:20} } >
                                        <div className="container">
                                            <img style={ {width: '100%', maxWidth: 200, maxHeight: 200} } src={icreappconfig.url_platform+CurrentData.deta.Logo} alt="Sin logo"/>                                                  
                                        </div> 
                                    </div> 


                                    <div className="col-xl-4 col-md-4">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>N.I.T:</strong> {CurrentData.Id_store} </label>                                
                                    </div>

                                    <div className="col-xl-8 col-md-8">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Nombre o razón social:</strong> {CurrentData.Name_store} </label>                                                                
                                    </div>                                 

                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Puntuación:</strong> {CurrentData.Score_store} </label>                                                                
                                    </div>    

                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Teléfono:</strong> {CurrentData.deta.Tel_store} </label>                                                                
                                    </div>    

                                    <div className="col-xl-12 col-md-12">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Dirección:</strong> {CurrentData.deta.Address_store} </label>                                                                
                                    </div>                                    

                                    {/*LoadMaps*/}                             

                                    <div className="col-xl-12 col-md-12">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Descripción:</strong> {CurrentData.Desc_store} </label>                                
                                    </div>     

                                    <div className="col-xl-4 col-md-4">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Maneja Facturación electrónica:</strong> { ( (CurrentData.deta.FacElect==1) ? "Si" : "No"  )} </label>
                                    </div>

                                    <div className="col-xl-4 col-md-4">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Numero de empleados:</strong> {CurrentData.deta.Empleados} </label>
                                    </div>

                                    <div className="col-xl-4 col-md-4">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Años de experiencia:</strong> {CurrentData.deta.Experience} </label>                                
                                    </div>                                   

                                    {Man_Catalog}

                                    {LoadFilesVerif}

                                    <div style={ {marginTop:20} } className="col-xl-12 col-md-12 align-self-center text-center" >
                                        <h5>Contactos adicionales</h5> 
                                    </div>                                                                            
                                    
                                    
                                    {LoadContacts}
                                    
                                    <div style={ {marginTop:20} } className="col-xl-12 col-md-12 align-self-center text-center" >
                                        <h5>Actividades de trabajo</h5> 
                                    </div>

                                    {LoadCategories}                                                                            

                                    {HandlerStatus}                                                                        

                                </div>    
                            </div>

                        </form>    
                    </div>
            )
        })           

    }


    render() {

        const { open } = this.state;
        let TitleModal = "Comercio";
        let BodyPage = "";     
        let handle_add = "";   
        let handler_modal = "";      
        
        if(this.state.loading){
            BodyPage = <Loading />;
        }else{
            if(this.state.data_store != [] ){
                BodyPage = this.viewDataBody();
            }else{
                BodyPage = <h3>{this.state.Msg_load}</h3>;
            }            
        }

        return (
            <Fragment>
                <Breadcrumb title="Perfil" parent="Comercios" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        {handle_add}
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{TitleModal}</h5>
                                            </div>   
                                            {handler_modal}                                         
                                        </Modal>

                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">  
                                        {BodyPage}                                                                                                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }


}


export default View_Store
import React, { Component,Fragment } from 'react';
import {Search}  from 'react-feather';
import Loading from '../Loading'
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import ConsoleData from '../../hooks/ConsoleData'
import DecoderData from '../../hooks/DecoderData'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Activity_finder extends Component {   

    constructor(props){
        super(props)

        //console.log("DetaServices");
        //console.log(props.DataService);
        
        //this.GetDetaServices = this.GetDetaServices.bind(this);

        let DiferentAction = props.DiferentAction;
        
        if( !!(DiferentAction) ){
            //DiferentAction = "D";
        }else{
            DiferentAction = "D";
        }

        ConsoleData("Ingresa a Activity_finder");
        ConsoleData(props);
        ConsoleData(DiferentAction);

        this.state = {            
            Data: [],
            HandlerAdd : props.HandlerAdd,
            //Action: "D", //Se comentarea para agregar nueva funcionalidad
            Action: DiferentAction,
            loading: false,
            error: false,
            Msg_Error: '',
            Search: ""
        }        
        
    }

    componentDidMount(){
        //this.GetDetaServices();
    }

    handleSearch = () =>{
        //console.log("ingreso handleSearch finder");
        ConsoleData("ingreso handleSearch finder");            
        
        if(this.state.Search.length < 1){
            toast.error( "Debes ingresar mínimo una palabra para buscar las actividades relacionadas." );
            return;                        
        }

        this.setState({
            Data: [],
            loading: true,
            error: false                        
        }) 

        this.GetActivities();
    }

    handlerChange = e => {        
        this.setState({            
            [e.target.name]: e.target.value
        })                   
    }

    handlerKeyPress = (e) => {
        if (e.key === 'Enter') {
            //console.log('do validate');
            ConsoleData('do validate');                
            this.handleSearch();
        }
    }

    HandlerActivity = props => {
        //console.log(props);
        this.state.HandlerAdd(props);


    }
    GetActivities = () => {
        const Urlquery = icreappconfig.url_controller+"AdminCommerceSubcategories.php";
        //console.log(Urlquery);   
        ConsoleData(Urlquery);         

        const Params = {
            Action: this.state.Action,
            Search: this.state.Search            
        }

        //console.log(Params); 
        ConsoleData(Params);         

        axios.post(Urlquery,Params)
             .then(res => {
                //console.log(res.data);   
                ConsoleData(res.data);         
                
                if(res.data[0].Status){
                    this.setState({
                        Data: res.data[0].Data,
                        loading: false,
                        error: false                        
                    })                    
                }else{ 

                    let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       
                    toast.error(decoder);

                    this.setState({
                        Data: [],
                        loading: false,
                        error: false                        
                    })

                }
                
             })
             .catch(err => {
                    console.error(err);
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
            });
    }


    render() {

        let TableResult;

        if(this.state.loading){
            TableResult=<Loading msg="Cargando..." />;
        }else{
            if(this.state.Data.length>0){
                TableResult=<div >                    
                                <table className="table table-sm table-hover table-bordered" style={ {marginTop:20,paddingLeft:20,paddingRight:20}  } >
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Codigo</th>
                                            <th>Nombre</th>                                                            
                                        </tr>
                                    </thead>
                                    <tbody>  
                                        {
                                            this.state.Data.map( (CurrentDetaService,i) => {                            
                                                return (
                                                    <tr key={i} >
                                                        <td onClick={ () => { this.HandlerActivity([CurrentDetaService.cod_subcategory,CurrentDetaService.name_subcategory]); } } >
                                                            <span>
                                                                <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 11,color:'#0d8e3c' }}></i>
                                                            </span>
                                                        </td>
                                                        <td >{CurrentDetaService.mcod_subcategory}</td>                                            
                                                        <td >{CurrentDetaService.name_subcategory}</td>                                        
                                                    </tr>
                                                )
                                                
                                            })
                                        }                    
                                    </tbody>
                                </table>                                  
                            </div>;
            }else{
                TableResult=<></>;
            }
            
        }
             

        return(

            <div className="row">      
                <div className="col-xl-12 col-md-12" >                    
                    <label htmlFor="recipient-name" className="col-form-label" >Actividades de Trabajo</label>
                    <div className="input-group">                        
                        <input type="text" className="form-control" name="Search" onChange={this.handlerChange} placeholder="Buscar actividad de trabajo" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onKeyDown = { (e) => { this.handlerKeyPress(e); } } />
                        <div className="input-group-btn">
                            <button className="btn btn-info" type="button" onClick={this.handleSearch} >
                                <Search />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-md-12" >
                    {TableResult}
                </div>
            </div>


        );

    }
}

export default Activity_finder;
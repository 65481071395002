import React, { Component, Fragment } from 'react'
import Slider from 'react-slick';

import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { ArrowLeft,Mail } from 'react-feather';

///Librerias utilizadas IcreApp
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import DecoderData from '../../hooks/DecoderData'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
///////////////////////////////

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ConsoleData from '../../hooks/ConsoleData';

//const image_logo = icreappconfig.url_images+"iconos/Icono_logo.jpg";
const image_logo = icreappconfig.url_images+"iconos/logo_color.png";


export class Login_forgot extends Component {    

    constructor(props) {
        super(props);
        
        
        const { match: { params } } = this.props;
        //console.log(params.id)       
        let id_transaction = params.id;

        this.state = {
            Action: 'A',
            Action_Form: 'V',
            Name_user: "",
            Email_user: "",
            Man_login: true,
            Login_user: true,
            Msg_load: "",
            loading: false     
        }

        /*
        this.state = {
            activeShow: true,
            startDate: new Date()
        }
        */
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);        
        
    }

    componentDidMount(){        
        //this.GetDataUser();                
    }

    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            [e.target.name]: e.target.value
        })
        
    }

    handleSubmit = async e => {
        //console.log("Submit")
        ConsoleData("Submit");

        e.preventDefault();                         
        
        this.setState({
            loading: true
        })

        try {
            
           //console.log(this.state.form);            
           ConsoleData(this.state.form);
            
           const Urlquery = icreappconfig.url_controller+"Reg_UserLogin.php";
           //console.log(Urlquery);        
           ConsoleData(Urlquery);
   
           const Params = {
                Action : this.state.Action_Form,
                Email: this.state.Email_user
           }
   
           axios.post(Urlquery,Params)
                .then(res => {
                    //console.log("Data Login forgot");   
                    //console.log(res.data);   

                    ConsoleData("Data Login forgot");
                    ConsoleData(res.data);
                   
                   if(res.data[0].Status){
                                                
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                       
                        this.setState({   
                            Email_user: "",                         
                            Man_login: res.data[0].Status,
                            loading: false,
                            Login_user: res.data[0].Login,
                            Msg_load: decoder                                                         
                        })                    
                                                
                   }else{  

                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));                       
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                       
                        this.setState({                            
                            Man_login: res.data[0].Status,
                            loading: false,
                            Login_user: res.data[0].Login,
                            Msg_load: decoder                                                         
                        })                         

                   }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            ////this.props.history.push('/exercise')

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        

    }        

    ResetState = () => {

        this.setState({                            
            Man_login: true,
            loading: false,
            Login_user: true,
            Msg_load: ""                                                         
        })  
    }
    
    loginrouteChange = () => {
        //Se cambia a ruta temporal mientras se define dashboard
        //this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
        this.props.history.push(`${process.env.PUBLIC_URL}/auth/logout`);
    }
        
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false
        };

        let Body_panel;

        if(this.state.loading){
            Body_panel = <Loading />;
        }else{
            if(!this.state.Man_login){
                Body_panel =<div>
                                <h6>{this.state.Msg_load}</h6>
                                <div className="form-button">
                                    <button className="btn btn-warning" onClick={this.ResetState}>Volver a intentar</button>
                                </div>
                            </div>;     
                            
            }else{
                if(!this.state.Login_user){

                    Body_panel =<div>
                                    <div className="form-terms">                                                                                                                                                                                                                                                                                                                                                        
                                        <div className="text-center">
                                            <span > 
                                                <h5 >{this.state.Msg_load}</h5>
                                            </span>
                                        </div>                                                                                                                                        
                                    </div>

                                    <div className="form-button">
                                        <button className="btn btn-warning" onClick={this.loginrouteChange}>Ir a Login</button>
                                    </div>

                                </div>;

                }else{

                    Body_panel = <form className="form-horizontal auth-form" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <input required name="Email_user" type="email" className="form-control" placeholder="Ingresa el correo registrado" onChange={this.handleChange} value={this.state.Email_user} />
                            </div>                                                                                                                                           
                            
                            <div className="form-button">
                                <button className="btn btn-primary" type="submit">Enviar confirmación</button>
                            </div>
                            
                            <div className="form-footer">                                                                                                                                         
                            </div>
                        </form>;

                }

            }            
        }


        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 p-0 card-left">
                                    <div className="card bg-primary">
                                        
                                        {
                                            /*
                                            <div className="svg-icon">
                                                <img src={image_logo} className="Img-fluid" />
                                            </div>
                                             */
                                        }
                                        
                                        <div className="container" >
                                                <img src={image_logo} className="img-responsive" height="180" width="200" style={{display: "block", margin: "auto" }} />
                                        </div>
                                                                                
                                        <Slider className="single-item" {...settings}>
                                            <div>
                                                <div>
                                                    <h3 style={{color: "#095f28"}}>No te preocupes, MosquerApp</h3>
                                                    <p style={{color: "#095f28"}}>Siempre estará para ayudarte. Acá encontraras todo lo que necesitas</p>
                                                </div>
                                            </div>                                                                                        
                                        </Slider >
                                    </div>
                                </div>
                                <div className="col-md-7 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <div>
                                                <Fragment>
                                                    <Tabs>
                                                        <TabList className="nav nav-tabs tab-coupon" >
                                                            <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><Mail />Correo de verificación</Tab>
                                                        </TabList>

                                                        <TabPanel>
                                                            {Body_panel}
                                                        </TabPanel>
                                                                                                                
                                                    </Tabs>
                                                </Fragment>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>      

                            <a href="/" className="btn btn-primary back-btn"><ArrowLeft />Volver al Comercio</a>

                        </div>
                    </div>
                </div>
                <ToastContainer />
            </Fragment>
        )
    }
}

export default Login_forgot

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/listUser';
import Datatable from '../common/datatable';

/////////////////////////////////
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import ConsoleData from '../../hooks/ConsoleData'

import ExcelDownload from '../specific/data_export_excel';

export class User_list extends Component {

    constructor(props) {
        super(props);

        this.GetDataUsers = this.GetDataUsers.bind(this);
        
        this.state = {
            open: false,
            data:[],
            data_table: [],
            data_tmp_table: [],
            change_table : false,   
            Txt_search: "",
            form:{
                Name_category: "",
                Manual_cod: ""         
            },
            Action: "C",
            loading: true,
            error: false,   
            Msg_load: '',    
            User_email: '',
            User_type: '',     
            Msg_Error: ''
        };
    }


    componentDidMount(){
        const DataSession = GetDataSession(`IcreapSessionData`);        

        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataCategories();
            this.GetDataSession("IcreapSessionData");        
        }        
    }

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })
            
            ///Manejo de permisos por tipo de usuario
            if(this.state.User_type < 9){
                this.GetDataUsers([DataValue.data[0].Email,DataValue.data[0].Tipo]);
            }

        }
    }

    handleChangeFilter = e => {
        ConsoleData(`${e.target.name}: ${e.target.value}`)
        //ConsoleData(this.state.data_filter_directory);

        ///Se vuelve a generar array de datos aplicando el filtro
        const TxtData_filter = e.target.value;
        let new_data = [];
        if(TxtData_filter==""){
            new_data = this.state.data_table;
        }else{
            this.state.data_table.forEach(function(dataitem) {
                const Email = dataitem.Email;
                const Metodo = dataitem.Metodo;
                const Nombre = dataitem.Nombre;
                const Telefono = dataitem.Telefono;
                const Tipo = dataitem.Tipo;
                const Status = dataitem.status.props.children;
                
                let AddItem = false;

                //Se valida el Email
                if(Email.includes(TxtData_filter) || Email.toLowerCase().includes(TxtData_filter.toLowerCase())   ){
                    AddItem=true;
                }

                //Se valida la Metodo
                if(Metodo.includes(TxtData_filter) || Metodo.toLowerCase().includes(TxtData_filter.toLowerCase() ) ){
                    AddItem=true;
                }

                //Se valida el codigo
                if(Nombre.includes(TxtData_filter) || Nombre.toLowerCase().includes(TxtData_filter.toLowerCase() ) ){
                    AddItem=true;
                }

                //Se valida el Telefono
                if(Telefono.includes(TxtData_filter) || Telefono.toLowerCase().includes(TxtData_filter.toLowerCase() ) ){
                    AddItem=true;
                }

                //Se valida el Tipo
                if(Tipo.includes(TxtData_filter) || Tipo.toLowerCase().includes(TxtData_filter.toLowerCase() ) ){
                    AddItem=true;
                }


                //Se valida el status
                if(Status.includes(TxtData_filter) || Status.toLowerCase().includes(TxtData_filter.toLowerCase() ) ){
                    AddItem=true;
                }

                //Se agrega item si se encuentra resultado
                if(AddItem){
                    new_data.push(dataitem);     
                    //ConsoleData(Status);               
                }

              })
        }
                
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            [e.target.name]: e.target.value,
            change_table : true,
            data_tmp_table: new_data
        })
        

        //ConsoleData(new_data_directory);
                
    }

    GetDataUsers = (data_session) => {
        const Urlquery = icreappconfig.url_controller+"LoadUsers.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);  

        //console.log(data_session);
        ConsoleData(data_session);  

        const Params = {
            Action: this.state.Action,
            Email: data_session[0],
            Type: data_session[1],
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_table: [],
            data_tmp_table: [],
            loading: true            
        })

        //console.log(Params); 
        ConsoleData(Params);  

        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    //console.log(res.data);   
                    ConsoleData(res.data);  
                    
                    if(res.data[0].Status){
                        let DataUSers = [];

                        res.data[0].Data.map( (CurrentService,i) => {                            
                            
                            //console.log(CurrentService);
                            ConsoleData(CurrentService);  

                            let Type_status;
                            if(CurrentService.status == "A"){
                                Type_status= <i className="fa fa-circle font-success f-12"> Activo </i>;                             
                            }else if (CurrentService.status == "I") {
                                Type_status= <i className="fa fa-circle font-warning f-12"> Inactivo </i>;                             
                            }else if (CurrentService.status == "R") {
                                Type_status= <i className="fa fa-circle font-warning f-12"> Pendiente Confirmación</i>;                             
                            }else{
                                Type_status= <i className="fa fa-circle font-danger f-12"> Bloqueado </i>;                             
                            }

                            DataUSers[i] = {                            
                                id: parseInt(CurrentService.cod_user),
                                Nombre: CurrentService.name_user,
                                Email: CurrentService.email,
                                Telefono: CurrentService.phone_user,
                                Tipo: CurrentService.name_usertype,
                                Metodo: CurrentService.method,                            
                                status: Type_status                        
                            }                                                
                            
                        })

                        this.setState({
                            data: res.data[0].Data,
                            data_table: DataUSers,
                            data_tmp_table: DataUSers,
                            loading: false,
                            error: false                        
                        })                    
                    }else{     
                        this.setState({
                            data: [],
                            data_table: [],
                            data_tmp_table: [],
                            loading: false,
                            Msg_load: res.data[0].Msg,
                            error: false                        
                        })                                   
                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }

    render() {

        let man_table;      
        let man_linkuser;  
        if(this.state.data_table.length == 0){
            if(this.state.loading){
                man_table = <Loading />
            }else{
                man_table = <h6>{this.state.Msg_load}</h6>
            }            
        }else{

            if(this.state.User_type<=2){
                man_linkuser = <Link to="/users/user_create" className="btn btn-secondary">Crear solicitud de usuario</Link>;
                man_table = <div>
                                <div className="row">
                                    < ExcelDownload  Sheet = {this.state.data} 
                                            FileName = "listado_usuarios" />
                                </div>    
                                <Datatable
                                    multiSelectOption={false}
                                    myData={this.state.data_tmp_table} 
                                    pageSize={10} 
                                    pagination={true}
                                    class="-striped -highlight"  
                                    editHandler={this.editHandler}
                                    removeHandler={this.editHandler}
                                />
                            </div>;
                                                                 
            }else if(this.state.User_type>2 && this.state.User_type<5){
                man_linkuser = <Link to="/users/user_create" className="btn btn-secondary">Crear solicitud de usuario</Link>;
                man_table = <div>
                                <div className="row">
                                    < ExcelDownload  Sheet = {this.state.data} 
                                            FileName = "listado_usuarios" />
                                </div>    
                                <Datatable
                                multiSelectOption={false}
                                myData={this.state.data_tmp_table} 
                                pageSize={10} 
                                pagination={true}
                                class="-striped -highlight"  
                                editHandler={this.editHandler}                    
                                />
                            </div>;   
            }else if(this.state.User_type>=5 && this.state.User_type<9){
                man_linkuser = <h5>No tienes permisos para crear solicitudes de usuario</h5>;
                man_table = <div>
                                <div className="row">
                                    < ExcelDownload  Sheet = {this.state.data} 
                                            FileName = "listado_usuarios" />
                                </div>    
                                <Datatable
                                    multiSelectOption={false}
                                    myData={this.state.data_tmp_table} 
                                    pageSize={10} 
                                    pagination={true}
                                    class="-striped -highlight"                      
                                />   
                            </div>;                                                   
                
            }else{
                man_table = <h5>No tienes permisos para ver esta opción</h5>
            }


            if(this.state.change_table){
                man_table = <div></div>;
                this.setState({
                    change_table : false,                                        
                })
            }
            
        }


        return (
            <Fragment>
                <Breadcrumb title="Lista de Usuarios" parent="Usuarios" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Detalles de los usuarios</h5>
                        </div>
                        <div className="card-body">
                            <div className="row" style={{marginBottom:20}}>
                                <div className="col-xl-6 col-md-6 align-self-center text-center" >                                                                            
                                    <input type="text" className="form-control" name="Txt_search" placeholder="Ingresa tu búsqueda" value={this.state.Txt_search} onChange={this.handleChangeFilter} />                                        
                                </div>    
                                <div className="col-xl-6 col-md-6 align-self-right text-right" >                                                                            
                                    <Link to="/users/user_request" >
                                        <button className="btn btn-primary" type="button" ><i className="fa fa-plus"></i>Crear solicitud de usuario</button>
                                    </Link>    
                                </div>                                                                
                            </div>  
                            {
                                /*Se cambia para manejo visual distinto
                                    <div className="btn-popup pull-right">
                                            <Link to="/users/user_create" className="btn btn-secondary">Crear solicitud de usuario</Link>
                                    </div>
                                */
                            }
                            

                            <div className="clearfix"></div>
                            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                {man_table}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default User_list

import React, { Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import ConsoleData from '../../hooks/ConsoleData'
import Activity_finder from '../specific/activity_finder'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class HandlerEmployPost extends Component {
    
    constructor(props) {
        super(props);

        this.getDataLocalStore = this.getDataLocalStore.bind(this);      
        this.imagePost = this.imagePost.bind(this);  
        this.updateBidding = this.updateBidding.bind(this);  

        this.state = {
            open: false,
            open_mfile: false,
            data_store: [],    
            data_post: [],                         
            Action: "D",
            Action_form: "T",
            IdPost: "",
            loading: true,
            error: true,   
            Msg_load: '',    
            User_email: '',
            User_type: '',     
            Msg_Error: ''
        };
    }
    
    componentDidMount(){
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{            
            this.GetDataSession("IcreapSessionData");
        }        
    }

    onCloseModal = () => {        
        this.setState({            
            open: false,
            form : {
                Status_bidding: ""
            }, 
        });
    }

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })
            
            this.getDataLocalStore();                                    
            
        }
    }
    

    getDataLocalStore = () => {
        //this.GetDataStore = this.GetDataStore.bind(this);
        let Id_post = sessionStorage.getItem("Id_post")        

        if (Id_post !== null) {
            
            ///Se quita para evitar que al recargar se vea doble recarga
            //sessionStorage.removeItem("Data_bidding");
            this.setState({                
                IdPost : Id_post
            })
            
            setTimeout(() => {
                this.GetDataPost();
            }, 1000);
        }else{                        
            ConsoleData("No se encontraron datos de oferta");
            this.props.history.push(`${process.env.PUBLIC_URL}/employ/register_bidding`);
        }

    }

    GetDataPost = () => {
        const Urlquery = icreappconfig.url_controller+"AdminEmployment.php";
        //console.log(Urlquery);        

        const formData = new FormData(); 
        formData.append('Action',this.state.Action);            
        formData.append('Id_post',this.state.IdPost);            

        /*
        const Params = {
            Action: this.state.Action,              
            Id_post: this.state.IdPost
        }
        */

        ///Se reinician estados para control de datos
        this.setState({
            data_post : [],
            loading : true,
            error : true,
            Msg_load: ""            
        })

        //console.log(Params); 
        try{
            axios
            .post(Urlquery,formData)
            .then(res => {
                ConsoleData(res.data);   
                
                if(res.data[0].Status){                                                   
                    let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                        
                    
                    let Man_data = res.data[0].Data[0];
                                                                
                    this.setState({                            
                        data_post : Man_data,                                                      
                        data_groups: [],
                        Msg_load: decoder,
                        loading: false,
                        error: false                        
                    });      
                                    
                }else{                         
                    //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                    let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                

                    this.setState({
                        data_post : [],
                        loading: false,
                        Msg_load: decoder,
                        error: true                        
                    });
                }                
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    data_post : [],
                    loading: false,
                    error: true,
                    Msg_Error: err
                })
            });
        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }        
    }

    handleSubmit = async e => {
        ConsoleData("Submit")        
        e.preventDefault();  

        try {            
            //Se hacen validaciones previas
            if(this.state.data_store.Status_change == undefined || this.state.data_store.Status_change == this.state.data_store.Status ){
                toast.error("No se ha seleccionado ningún cambio de estado. Por esto no se puede generar el cambio.");
                return;
            }

            if(this.state.data_store.Desc_Status == undefined || this.state.data_store.Desc_Status.length < 10  ){
                toast.error("Debes ingresar una observación mayor a 10 caracteres.");
                return;
            }

            this.setState({
                loading: true
            })

            const Urlquery = icreappconfig.url_controller+"AdminStores.php";
            ConsoleData(Urlquery);                  



            
            const Params = {
                Action : this.state.Action_form,
                User_email : this.state.User_email,
                User_type : this.state.User_type,
                Status : this.state.data_store.Status_change,
                Cod_store : this.state.data_store.Cod_store,
                Desc_status : this.state.data_store.Desc_Status
            }

            ConsoleData(Params);  

            axios.post(Urlquery,Params)
                .then(res => {
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        toast.success(decoder);
                        
                        this.GetDataStore();

                    }else{     
                        
                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                
                        toast.error(decoder);

                        this.setState({                            
                            loading: false,
                            Msg_load: decoder,
                            error: true                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({                            
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
                                        
    }
    
    handleChangeDeta = e => {        
        this.setState({
            data_store:{
                ...this.state.data_store,
                [e.target.name]: e.target.value                
            }            
        })                   
    }

    imagePost = (event) => {
        const Id_Post = this.state.IdPost;
        const Urlquery = icreappconfig.url_controller + "AdminEmployment.php";

        ConsoleData("imagePost");   
    
        const formData = new FormData();
        formData.append("Action", "I");
        formData.append("Id_post", Id_Post);

        this.setState({
            loading: true
        });


        try {
          axios
            .post(Urlquery, formData)
            .then((res) => {
              //console.log(res.data);
              ConsoleData(res.data);
              if(res.data[0].Status){
                let DataPost = [];                

                this.setState({
                    loading: false
                });

                ///Se utiliza para cerrar el modal despues de registrar el cambio                        
                let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                       
                toast.success(decoder);  
                ///////////////////////////////////////

              } 
            })
            .catch((err) => {
              console.error(err);
              this.setState({
                loading: false,
                error: true,
                Msg_Error: err,
              });
            });
        } catch (error) {
          this.setState({
            loading: false,
            error,
          });
        }
    }; 

    updateBidding = (props) => {
        ConsoleData(props);

        sessionStorage.removeItem("Id_post");      
        sessionStorage.setItem("Id_post", props );

        this.props.history.push(`${process.env.PUBLIC_URL}/employ/update_bidding`);

    }

    viewDataBody = () => {

        /*
        const handleChangeCheck = this.handleChangeCheck;        
        const viewHandlerBiddingsPost = this.viewHandlerBiddingsPost;        
        const data_biddingpost = this.state.data_biddingpost;        
        */        
        
        const HandlerDataDeta = this.viewDataDetaBody;
        const imagePost = this.imagePost;
        const updateBidding = this.updateBidding;
        const Id_Post = this.state.IdPost;

        const data_post = this.state.data_post;
        const data_post_obj = [this.state.data_post];
        const data_post_deta = this.state.data_post.data;



        const handleChangeDeta = this.handleChangeDeta;

        let LoadCategories=<div></div>;
        let LoadContacts = <div></div>;
        let LoadFilesVerif = <div></div>;
        let HandlerStatus = <div></div>;
        let Man_Catalog = "";

        let LoadDeta = <div></div>;

        ConsoleData("viewDataBody")
        ConsoleData(data_post.Status)      
        //ConsoleData(data_store.Desc)      

        /*
        data_post_deta.map(function (CurrentData,i){

            ConsoleData("viewDataDeta")
            ConsoleData(CurrentData.name)      

            if(CurrentData.data.length>0){

            }else{

                LoadDeta += <div className="col-xl-6 col-md-6">
                                <label htmlFor="recipient-name" className="col-form-label" > <strong>{CurrentData.name}:</strong> {CurrentData.value.length>0 ? CurrentData.value : CurrentData.description } </label>                                                                
                            </div>;    
            }

        });
        */
        

        return data_post_obj.map(function (CurrentData,i){

            //ConsoleData("data_post_obj");
            //ConsoleData(CurrentData);

            return (<div key={i}>
                        <form >                            
                            <div style={ {margin:20} }>
                                <div className="row">      


                                    <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginBottom:20} } >
                                        <img style={ {width: '100%', maxWidth: 600} } src={icreappconfig.url_platform+"images/Employment/Generate/Employment_"+Id_Post+".jpeg?"+Date.now()} alt="Sin formato"/>      
                                    </div>                                    
                                    

                                    <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginBottom:20} } >
                                        <h3>Datos de la Publicación #{Id_Post} </h3> 
                                    </div>                                    

                                    <div className="col-xl-4 col-md-4">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>N.I.T:</strong> {CurrentData.id_store} </label>                                
                                    </div>

                                    <div className="col-xl-8 col-md-8">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Nombre o razón social:</strong> {CurrentData.name_store} </label>                                                                
                                    </div>                                 

                                    <div className="col-xl-4 col-md-4">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Persona encargada del proceso:</strong> {CurrentData.name_person} </label>                                                                
                                    </div>    

                                    <div className="col-xl-4 col-md-4">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Teléfono de contacto:</strong> {CurrentData.phone_person} </label>                                                                
                                    </div>    

                                    <div className="col-xl-4 col-md-4">
                                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Email de contacto:</strong> {CurrentData.email_store} </label>                                                                
                                    </div>     


                                    <div className="form-group divider bg-dark col-md-12"> </div>                               

                                    
                                    {HandlerDataDeta(data_post_deta)}

                                    <div className="form-group divider bg-dark col-md-12 mt-4"> 
                                    </div>

                                    <div className="col-xs-12 col-md-4 py-2 text-center">
                                        <button
                                            className="btn btn-xs btn-success"
                                            type="button"
                                            onClick={() => { updateBidding(Id_Post) }}
                                        >
                                            Modificar Oferta
                                        </button>
                                    </div>

                                    <div className="col-xs-12 col-md-4 py-2 text-center">
                                        <button
                                            className="btn btn-xs btn-warning"
                                            type="button"
                                            onClick={imagePost}
                                        >
                                            Generar Imagen Oferta
                                        </button>
                                    </div>

                                    <div className="col-xs-12 col-md-4 py-2 text-center">
                                        <a download target={"_blank"} href={icreappconfig.url_platform+"images/Employment/Generate/Employment_"+Id_Post+".jpeg?"+Date.now()} className="btn btn-xs btn-info">Descargar Imagen</a>
                                    </div>                                                            

                                </div>    
                            </div>

                        </form>    
                    </div>
            )
        })           

    }
    
    viewDataDetaBody = (data_deta) => {

        let DataDetaChild = this.viewDataDetaChildBody;

        return (
            data_deta.map(function (CurrentData,i){

                ConsoleData("viewDataDeta")
                ConsoleData(CurrentData.name)      

                if(CurrentData.data.length>0){

                    ConsoleData("viewDataDeta IF")
                    ConsoleData(CurrentData.data[0])      


                    return ( <div className="col-xl-6 col-md-6">
                                <label htmlFor="recipient-name" className="col-form-label" > <strong>{DecoderData({Data: CurrentData.name, Type: "S"})}:</strong> </label>
                                <ul className="list-group">
                                    {DataDetaChild(CurrentData.data[0])}                                    
                                </ul>                                    
                            </div> );    

                }else{
                    ConsoleData( "viewDataDetaBody else" )
                    ConsoleData( CurrentData.name )
                    ConsoleData( CurrentData.value+" - "+CurrentData.description )                    

                    return ( <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" > <strong>{DecoderData({Data: CurrentData.name, Type: "S"})}:</strong> 
                                        { DecoderData({Data: (CurrentData.value.length>0 ? (CurrentData.description.length>0 ? CurrentData.value+" - "+CurrentData.description : CurrentData.value )  : CurrentData.description), Type: "S"})  } </label>                                                                
                                </div> );    
                }

            }
            )        
        );


    }

    viewDataDetaChildBody = (data_deta) => {

        return (
            data_deta.map(function (CurrentDataChild,i){

                ConsoleData("viewDataDetachild")
                ConsoleData(CurrentDataChild)                      
                ConsoleData(CurrentDataChild.name_child)                      

                return ( <li className="list-group-item">
                    {DecoderData({Data: CurrentDataChild.name_child+((CurrentDataChild.name_child.length>0 && CurrentDataChild.description.length>0) ? " - " :"")+CurrentDataChild.description , Type: "S"}) }
                    {/* DecoderData({Data: (CurrentData.name_child.length>0 ? CurrentData.name_child : CurrentData.value), Type: "S"}) */ }
                </li>
                );    
            
            }
            )        
        );


    }



    render() {

        const { open } = this.state;
        let TitleModal = "Comercio";
        let BodyPage = "";     
        let handle_add = "";   
        let handler_modal = "";      
        
        if(this.state.loading){
            BodyPage = <Loading />;
        }else{
            if(this.state.data_store != [] ){
                BodyPage = this.viewDataBody();
                //BodyPage = <h3>Cargo Info</h3>;
            }else{
                BodyPage = <h3>{this.state.Msg_load}</h3>;
            }            
        }

        return (
            <Fragment>
                <Breadcrumb title="Publicación" parent="Empleo" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        {handle_add}
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{TitleModal}</h5>
                                            </div>   
                                            {handler_modal}                                         
                                        </Modal>

                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">  
                                        {BodyPage}                                                                                                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }


}


export default HandlerEmployPost
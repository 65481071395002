import React from 'react'
import Loading from '../../Loading'
import icreappconfig from '../../../Icreappconfig'
import user from '../../../assets/images/dashboard/user.png';
import ConsoleData from '../../../hooks/ConsoleData';
import { ToastContainer, toast } from 'react-toastify';



const Body_Sidebar= ({data, loading, event}) => {

    ConsoleData("Body_Sidebar");
    //ConsoleData(data);
    
    if(loading)
        return <Loading />    


    if(Object.values(data).length>0){        
        return (        
            data.map( (CurrentData,i) => {
                ////Se maneja nombre de Store para que no se vea dividido
                let name_store = CurrentData.store;    
                let date = CurrentData.date_update;
                date = date.split(" ");       

                let split_name = name_store.split(" ");
                //ConsoleData("modif_name");
                //ConsoleData(split_name);
                
                
                name_store = "";
                for(let i=0;i<split_name.length;i++){
                    //ConsoleData(split_name[i]);
                    let acumname=name_store+" "+split_name[i];
                    if(acumname.length<15){
                        name_store=name_store+split_name[i]+" ";
                    }
                }
                
                if(name_store.length==0 && split_name.length>0){
                    //ConsoleData("se deja la primer palabra del nombre");
                    name_store=split_name[0];
                }
                ////////////////////////////////////////////////////////////

                const customIdCH = "chat_p";
                const objSideBar = document.querySelector(".right-sidebar");

                if(CurrentData.count>0){                                        

                    ////Se debe verificar que el elemento aun exista o sino presentara errores
                    if (objSideBar !== null) {

                        if(objSideBar.classList.contains("show")){
                            ConsoleData("Show objSideBar");
                        }else{
                            ConsoleData("Hide objSideBar");

                            if(CurrentData.count>1){
                                toast.info("Tienes "+CurrentData.count+" mensajes pendientes de "+CurrentData.store+", ingresa a la opción de chat para ver todos tus mensajes.", {                          
                                        autoClose: 10000,
                                        onClick: () => document.querySelector(".right-sidebar").classList.add('show'),
                                        toastId: customIdCH                            
                                    });                    
                            }else{
                                toast.info("Tienes "+CurrentData.count+" mensaje pendiente de "+CurrentData.store+", ingresa a la opción de chat para ver todos tus mensajes", {                          
                                    autoClose: 10000,
                                    onClick: () => document.querySelector(".right-sidebar").classList.add('show'),
                                    toastId: customIdCH                        
                                    });                    
                            }

                        }
                    }


                    
                    
                }

                
                return (
                    <li className="clearfix" key={i} onClick={()=>event(CurrentData)}>                                            
                        <img className="rounded-circle user-image" src={CurrentData.logo == "" ? user: icreappconfig.url_platform+CurrentData.logo} alt="" />                                                                                        
                        <div className="status-circle">
                            <span className="badge badge-pill badge-primary pull-left notification-badge">{CurrentData.count}</span>
                        </div>
                        <div className="about">
                            <div className="name">{name_store}</div>
                            <div className="status"> {date[0]}</div>
                            <div className="status"> {date[1]}</div>
                        </div>
                    </li>
                )
            })
            
        )
    }else{
        return(
            <li className="clearfix" >                                                                                            
                    Aun no se tiene ningún comercio aliado                
            </li>
        )
    }

}

export default Body_Sidebar
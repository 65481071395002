import React, { Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal';
import Datatable from '../common/datatable';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import ConsoleData from '../../hooks/ConsoleData'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Commerce_subcategory extends Component {
    constructor(props) {
        super(props);

        this.GetDataSubCategories = this.GetDataSubCategories.bind(this);        

        this.state = {
            open: false,
            data_table: [],
            data_tmp_table: [],
            data_category: [],
            Txt_search : "",     
            form:{
                Cod_category: "",
                Name_subcategory: "",
                Manual_cod: ""         
            },
            Action: "C",
            loading: true,
            error: false,   
            Msg_load: '',    
            User_email: '',
            User_type: '',     
            Msg_Error: ''
        };
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount(){
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            this.GetDataSubCategories();
            this.GetDataSession("IcreapSessionData");        
        }        
    }

    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })
        
    }

    handleSubmit = async e => {
        //console.log("Submit")
        ConsoleData("Submit");        

        e.preventDefault()         
        this.setState({
            loading: true
        })
        
        try {
            
            //console.log(this.state.form);            
            ConsoleData(this.state.form);

            
            
            const Urlquery = icreappconfig.url_controller+"AdminCommerceSubcategories.php";
            //console.log(Urlquery);        
            ConsoleData(Urlquery);
   
            const Params = {
                Action : "R",
                User_email : this.state.User_email,
                User_type : this.state.User_type,
                Form : this.state.form
            }
   
            //console.log(Params); 
            ConsoleData(Params);
   
            axios.post(Urlquery,Params)
                .then(res => {
                    //console.log(res.data);   
                    ConsoleData(res.data);

                    
                   
                    if(res.data[0].Status){
                        this.setState({
                            loading: false,
                            error: false
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio
                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 
                        toast.success(decoder);                                                
                        this.onCloseModal();              
                        this.GetDataSubCategories();          
                    }else{  
                        //alert(res.data[0].Msg); 
                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));                       
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 
                        
                        toast.error( decoder );
                            this.setState({
                                loading: false,
                                error: true,
                                Msg_Error: res.data[0].Msg
                            })
                        
                    }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            ////this.props.history.push('/exercise')

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        

    }

    handleChangeFilter = e => {
        ConsoleData(`${e.target.name}: ${e.target.value}`)
        //ConsoleData(this.state.data_filter_directory);

        ///Se vuelve a generar array de datos aplicando el filtro
        const TxtData_filter = e.target.value;
        let new_data = [];
        if(TxtData_filter==""){
            new_data = this.state.data_table;
        }else{
            this.state.data_table.forEach(function(dataitem) {
                const Categoria = dataitem.Categoria;                
                const Cod_interno = dataitem.Cod_interno;                
                const Sub_categoria = dataitem.Sub_categoria;                
                const Status = dataitem.status.props.children;
                
                let AddItem = false;

                //Se valida el Categoria
                if(Categoria.includes(TxtData_filter) || Categoria.toLowerCase().includes(TxtData_filter.toLowerCase())   ){
                    AddItem=true;
                }

                //Se valida el Cod_interno
                if(Cod_interno.includes(TxtData_filter) || Cod_interno.toLowerCase().includes(TxtData_filter.toLowerCase())   ){
                    AddItem=true;
                }

                //Se valida el Sub_categoria
                if(Sub_categoria.includes(TxtData_filter) || Sub_categoria.toLowerCase().includes(TxtData_filter.toLowerCase())   ){
                    AddItem=true;
                }
                

                //Se valida el status
                if(Status.includes(TxtData_filter) || Status.toLowerCase().includes(TxtData_filter.toLowerCase() ) ){
                    AddItem=true;
                }

                //Se agrega item si se encuentra resultado
                if(AddItem){
                    new_data.push(dataitem);     
                    //ConsoleData(Status);               
                }

              })
        }
                
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            [e.target.name]: e.target.value,
            change_table : true,
            data_tmp_table: new_data
        })
        

        //ConsoleData(new_data_directory);
                
    }

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

        }
    }

    GetDataSubCategories = () => {
        const Urlquery = icreappconfig.url_controller+"AdminCommerceSubcategories.php";
        //console.log(Urlquery);        

        const Params = {
            Action: this.state.Action
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_table: [],
            data_tmp_table: [],
            loading: true            
        })

        //console.log(Params); 
        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    //console.log(res.data);   
                    ConsoleData(res.data);                    
                    
                    if(res.data[0].Status){
                        let DataCategories = [];

                        res.data[0].Data.map( (CurrentService,i) => {                            
                                                        
                            //console.log(CurrentService);
                            ConsoleData(CurrentService);                            

                            let Type_status;
                            if(CurrentService.status == "A"){
                                Type_status= <i className="fa fa-circle font-success f-12" > Activo </i>;                             
                            }else if (CurrentService.status == "I") {
                                Type_status= <i className="fa fa-circle font-warning f-12" > Inactivo </i>;                             
                            }else{
                                Type_status= <i className="fa fa-circle font-danger f-12" > Bloqueado </i>;                             
                            }

                            DataCategories[i] = {                                                            
                                id: CurrentService.cod_subcategory,  
                                Categoria: CurrentService.name_category,                                     
                                Cod_interno: CurrentService.mcod_subcategory,                     
                                Sub_categoria: CurrentService.name_subcategory,                                                       
                                status: Type_status                        
                            }                                                
                            
                        })

                        this.setState({
                            data_table: DataCategories,
                            data_tmp_table: DataCategories,
                            data_category: res.data[0].Category,
                            loading: false,
                            error: false                        
                        })                    
                    }else{     
                        this.setState({
                            data_table: [],
                            data_tmp_table: [],
                            data_category: res.data[0].Category,
                            loading: false,
                            Msg_load: res.data[0].Msg,
                            error: false                        
                        })                                   
                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }

    LoadOptionSelect(props){
        
        //console.log("LoadOptionStatus")
        //console.log(props)

        return props.map(function (CurrentData,i){
            return (
                <option value={CurrentData.cod}> {CurrentData.name} </option>                
            ) 
        })

    }

    render() {
        const { open } = this.state;
        ///Se valida si se muestra la tabla o no
        let man_table;        
        if(this.state.data_table.length == 0){
            if(this.state.loading){
                man_table = <Loading />
            }else{
                man_table = <h6>{this.state.Msg_load}</h6>
            }            
        }else{
            man_table = <Datatable
                    multiSelectOption={false}
                    //myData={this.state.data_table} 
                    myData={this.state.data_tmp_table} 
                    pageSize={10} 
                    pagination={true}
                    class="-striped -highlight"                     
                    
                />;   
        }

        ///Se agrega cambio para garantizar la actualizacion de la tabla
        if(this.state.change_table){
            man_table = <div></div>;
            this.setState({
                change_table : false,                                        
            })
        }

        ///Manejo loading modal
        let LoadModal;
        if(this.state.loading){
            LoadModal = <Loading />;            
        }else{
            LoadModal = <form onSubmit={this.handleSubmit}>
                            <div className="modal-body">                                                
                                    <div className="form-group">
                                        <select className="custom-select custom-select-sm" value={this.state.form.Cod_category} onChange={this.handleChange} name="Cod_category" id="Cod_category" required>            
                                            <option value="">Seleccionar categoria:</option>
                                            {this.LoadOptionSelect(this.state.data_category)}                                                                    
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name" className="col-form-label" >Cod Interno sub categoría:</label>
                                        <input type="text" className="form-control" name="Manual_cod" value={this.state.form.Manual_cod} onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name" className="col-form-label" >Nombre sub categoría:</label>
                                        <input type="text" className="form-control" name="Name_subcategory" value={this.state.form.Name_subcategory} onChange={this.handleChange} required/>
                                    </div>
                                    {
                                        /*                                                    
                                            <div className="form-group">
                                                <label htmlFor="message-text" className="col-form-label">Category Image :</label>
                                                <input className="form-control" id="validationCustom02" type="file" />
                                            </div>
                                        */
                                    }                                                                                                            
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" >Crear</button>
                                <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal()}>Cerrar</button>
                            </div>
                        </form>;    
        }



        return (
            <Fragment>
                <Breadcrumb title="Sub Categorias" parent="Comercios" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">
                                    
                                    <div className="row" >
                                        <div className="col-xl-6 col-md-6 align-self-center text-center" >                                                                            
                                            <input type="text" className="form-control" name="Txt_search" placeholder="Ingresa tu búsqueda" value={this.state.Txt_search} onChange={this.handleChangeFilter}  />                                        
                                        </div>                                            
                                    </div>  


                                    <div className="btn-popup pull-right">

                                        <button type="button" className="btn btn-primary" onClick={this.onOpenModal} data-toggle="modal" data-original-title="test" data-target="#exampleModal">adicionar sub categoría</button>
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">Adicionar Sub Categoría</h5>
                                            </div>
                                            {LoadModal}
                                        </Modal>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">                                        
                                        {man_table}                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }
}

export default Commerce_subcategory


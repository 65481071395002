import React, { Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal';
import Datatable from '../common/datatable';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import ConsoleData from '../../hooks/ConsoleData'
import Handler_bidding_deta from '../specific/handler_bidding_deta'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export class Bidding_postulation extends Component {

    constructor(props) {
        super(props);                

        this.state = {
            Action: "C",
            open: false,
            Cod_bidding : "",
            Cod_store : "",
            data_store: [],   
            data_bidding: [],
            data_biddingpost: [], 
            data_detabiddingpost: [],                                    
            form : {                
            },
            formTMP : {                
            },
            Msg_load: "",
            User_email: "",
            User_type: "",
            loading: true,   
            rightSidebar: true,         
            error: false            
        };
    }

    showRightSidebar = () => {
        if (this.state.rightSidebar) {
            this.setState({ rightSidebar: false })
            document.querySelector(".right-sidebar").classList.add('show');
        } else {
            this.setState({ rightSidebar: true })
            document.querySelector(".right-sidebar").classList.remove('show');
        }
    }

    componentDidMount(){        
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataSubCategories();
            this.GetDataSession("IcreapSessionData");        
        }        
    }


    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)        
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })
        
    }
    
    handleChangeTMP = e => {
        
        ConsoleData(`${e.target.name}: ${e.target.value}`)        
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name

        if(e.target.name == "Rating" || e.target.name == "Note"){

            this.setState({            
                formTMP:{                
                    ...this.state.formTMP,
                    [e.target.name]: e.target.value                    
                }            
            })

        }else{
            this.setState({            
                formTMP:{                
                    Cod_biddingPost: e.target.name,
                    Status_change: e.target.value                 
                }            
            })
        }

        
        
    }

    handleChangeCheck = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        
        let Val_asign = e.target.value;
        ///console.log(`${e.target.name}: ${e.target.value}`)
        const Val_form = this.state.form;           
        
        if(Val_form[e.target.name] === e.target.value){
            //Val_asign = "";
            delete Val_form[e.target.name];
            
            this.setState({
                form: Val_form           
            })

        }else{

            // Esto se utiliza para guardar los valores en los estados                
            // Metodo nuevo - computer propierti name
            this.setState({
                form:{
                    ...this.state.form,
                    [e.target.name]: Val_asign
                }            
            })

        }
        
        
    }
    
    
    handleChangeCheckTMP = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        
        let Val_asign = e.target.value;
        ///console.log(`${e.target.name}: ${e.target.value}`)
        const Val_form = this.state.formTMP;           
        
        if(Val_form[e.target.name] === e.target.value){
            //Val_asign = "";
            delete Val_form[e.target.name];
            
            this.setState({
                formTMP: Val_form           
            })

        }else{

            // Esto se utiliza para guardar los valores en los estados                
            // Metodo nuevo - computer propierti name
            this.setState({
                formTMP:{
                    ...this.state.formTMP,
                    [e.target.name]: Val_asign
                }            
            })

        }
        
        
    }

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);            
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            this.getDataLocalStore();
            //this.GetDataStore([DataValue.data[0].Email,DataValue.data[0].Tipo])
        }
    }

    getDataLocalStore = () => {
        //this.GetDataStore = this.GetDataStore.bind(this);
        let Data_bidding = sessionStorage.getItem("Data_bidding")        

        if (Data_bidding !== null) {
            Data_bidding = JSON.parse(Data_bidding);
            ///Se quita para evitar que al recargar se vea doble recarga
            //sessionStorage.removeItem("Data_bidding");
            this.setState({
                Cod_bidding : Data_bidding.Cod_bidding,
                Cod_store : Data_bidding.Cod_store
            })
            
            setTimeout(() => {
                this.getDataBiddingPost();
            }, 1000);
        }else{                        
            ConsoleData("No se encontraron datos de oferta");
            this.props.history.push(`${process.env.PUBLIC_URL}/bidding/bidding_made`);
        }

    }

    onOpenModal = () => {
        //console.log("onOpenModal");
        //console.log(e);
        this.setState({            
            open: true            
        });
    };

    onCloseModal = () => {        
        this.setState({            
            open: false,
            data_detabiddingpost: [],
            formTMP: {}
        });
    }

    getDataBiddingPost = () => {

        const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);         

        const formData = new FormData();           

        formData.append('Action','D');    
        //formData.append('User_email',this.state.User_email);    
        //formData.append('User_type',this.state.User_type);    
        formData.append('Cod_store',this.state.Cod_store); 
        formData.append('Cod_bidding',this.state.Cod_bidding); 

        //console.log(formData);                

        this.setState({                            
            loading: true,
            data_bidding: [],
            error: false,
            Msg_error: ""                                               
        });    
        
        try{
            axios.post(Urlquery,formData)
                .then(res => {
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                                                                        
                        ConsoleData(res.data[0].DataBidding);   

                        this.setState({                            
                            data_bidding: res.data[0].DataBidding,     
                            data_biddingpost : res.data[0].DataBiddingPost,
                            data_detabiddingpost: [],                                    
                            Msg_load: decoder,                            
                            form:{
                                Cod_store: this.state.Cod_store,
                                Cod_bidding: this.state.Cod_bidding,   
                                Nota: "",                             
                                Status: ( res.data[0].DataBidding[0].Status_bidding == "A" ? "O" : "F" )
                            }, 
                            loading: false,
                            error: false,                            
                        })                    
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            data_bidding: [],
                            data_biddingpost : [],
                            data_detabiddingpost: [],                                   
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }



    }

    handleSubmit = async e => {
        ConsoleData("Submit")        
        e.preventDefault();  
                     
        /*
        if(Object.values(this.state.form).length<=0){
            toast.error("No se puede finalizar la oferta sin escoger almenos una propuesta.")
            return;
        }
        */

        if(this.state.form.Status=="O"){

            let Man_Acept = false;
            for(let index=0;index<Object.values(this.state.data_biddingpost).length;index++){
                ConsoleData(this.state.data_biddingpost[index].Status_bidding);
                if(this.state.data_biddingpost[index].Status_bidding=="A"){
                    Man_Acept=true;
                }
            }               

            if(!Man_Acept){
                toast.error("No se puede cerrar la oferta sin escoger al menos una propuesta. Si desea cancelarla debe hacer el cambio de estado en la ventana que se abre antes de ingresar a las postulaciones.")
                return;
            }

            if (!window.confirm('¿Esta seguro de cerrar la oferta?. Al cerrarla todas las postulaciones que están en estado PENDIENTE pasaran a NO ACEPTADAS, y solo se podrá trabajar con las ACEPTADAS.')) {
                return false;
            }
            
        }
                
        try {

            const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
            //console.log(Urlquery);        
            ConsoleData(Urlquery);         

            const formData = new FormData();           

            formData.append('Action','U');                      
            formData.append('User_email',this.state.User_email);    
            formData.append('User_type',this.state.User_type);    
            formData.append('form',JSON.stringify(this.state.form)); 
            
            //console.log(formData);                

            this.setState({                            
                loading: true,
                data_bidding: [],
                error: false,
                Msg_error: ""                        
            }); 

            axios.post(Urlquery, formData)              
                .then(res => {
                    ConsoleData(res.data);   
                
                    if(res.data[0].Status){

                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       
                        
                        this.setState({                            
                            error: false                            
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio                        
                        toast.success(decoder);                                                                        
                        this.getDataBiddingPost();                                                  

                    }else{  
                        //alert(res.data[0].Msg); 
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 

                        toast.error( decoder );
                        this.setState({
                            loading: false,
                            error: false,
                            Msg_Error: res.data[0].Msg
                        })                        
                }
                
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    
            
            
        }catch (error) {
                
            ConsoleData(error);

            this.setState({
                loading: false,
                error
            }) 
        }



    }

    handleSubmitStatus = async e => {
        //console.log("Submit")        
        ConsoleData("Submit");         

        e.preventDefault();  
                                
        if(Object.values(this.state.formTMP).length<=0){
            toast.error("Debe llenar los campos para actualizar el estado")
            return false;
        }             
        
        if (!window.confirm('¿Esta seguro de cambiar el estado?. Luego de cambiarlo se notificara al comercio y no se podrá volver a hacer ningún cambio')) {
            return false;
        }
        
        try {

            const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
            //console.log(Urlquery);        
            ConsoleData(Urlquery);         

            const formData = new FormData();           

            formData.append('Action','E');    
            formData.append('User_email',this.state.User_email);    
            formData.append('User_type',this.state.User_type);            
            formData.append('form',JSON.stringify(this.state.formTMP)); 

            
            //console.log(formData);                

            this.setState({                            
                loading: true,
                data_bidding: [],
                error: false,
                Msg_error: ""                        
            }); 

            axios.post(Urlquery, formData)              
                .then(res => {
                    ConsoleData(res.data);   
                
                    if(res.data[0].Status){

                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       
                        
                        this.setState({
                            loading: false,
                            error: false                            
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio                        
                        toast.success(decoder);                                                
                        this.onCloseModal();        
                        this.getDataBiddingPost();  
                        
                        document.querySelector(".right-sidebar").classList.add('show');

                    }else{  
                        //alert(res.data[0].Msg); 
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 

                        toast.error( decoder );
                        this.setState({
                            loading: false,
                            error: false,
                            Msg_Error: res.data[0].Msg
                        })                        
                }
                
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    
            
            
        }catch (error) {
                
            ConsoleData(error);

            this.setState({
                loading: false,
                error
            }) 
        }

    }

    viewCommerce = (props) => {
        ConsoleData(props);

        sessionStorage.removeItem("Cod_store");      
        sessionStorage.setItem("Cod_store", props );

        this.props.history.push(`${process.env.PUBLIC_URL}/store/view_store`);

    }

    viewHandlerBiddingsPost = (props) => {
        ConsoleData("viewHandlerBiddingsPost");
        ConsoleData(props);

        this.setState({                                        
            data_detabiddingpost: props,
            open: true
        });   

    }

    viewDataBody = () => {

        //const handleChangeCheck = this.handleChangeCheck;
        const handleSubmit = this.handleSubmit;
        const handleChange = this.handleChange;
        const viewHandlerBiddingsPost = this.viewHandlerBiddingsPost;
        const data_bidding = this.state.data_bidding;
        const data_biddingpost = this.state.data_biddingpost;
        const form_data = this.state.form;       

        const TypeUser = parseInt(this.state.User_type);
        let Actionsdefined= {display:'none'};
        if(TypeUser>9){
            Actionsdefined = {display:'block'};
        }

        let LoadBiddingPost;

        ConsoleData("data_biddingpost.length");
        ConsoleData(data_biddingpost.length);

        if(data_biddingpost.length>0){

            ////Manejo de estado de la oferta
            let ManFormStatus = <div></div>;
                        
            ConsoleData(data_bidding[0].Status_bidding)

            if(data_bidding[0].Status_bidding=="A"){
                {/*<div style={ {marginTop:20} } className="row"> */}
                ManFormStatus = <>
                                    <div className="col-xl-12 col-md-12" style={Actionsdefined}>
                                        <label htmlFor="recipient-name" className="col-form-label" >                                        
                                            <strong>                                                        
                                                Al cerrar la oferta todas las postulaciones que están pendientes pasaran a NO ACEPTADAS, y solo se podrá trabajar con las ACEPTADAS. Recuerde que al cerrar ya no recibirá mas postulaciones.                                                
                                            </strong>    
                                            <br/><br/>
                                            <p>                                                    
                                                Si la fecha de expiración es mayor a la actual no recibirá mas postulaciones, pero se debe continuar con el proceso para terminar correctamente la oferta.
                                            </p>                                                       
                                        </label>                                    
                                    </div>
                                    <div className="col-xl-12 col-md-12" style={Actionsdefined}>
                                        <button type="submit" className="btn btn-primary" >Cerrar Oferta</button>                                                                                            
                                    </div>                                                                
                                </>
            }else if(data_bidding[0].Status_bidding=="O"){
                ManFormStatus = <>
                                    <div className="col-xl-12 col-md-12" style={Actionsdefined} >
                                        <p>                                                    
                                            Por favor ingresa el valor total real invertido en las postulaciones, esto nos ayudara a seguir mejorando la plataforma y continuar adicionando más herramientas que te ayuden a seguir creciendo. Gracias  
                                        </p>                                                                                           
                                    </div>

                                    <div className="col-xl-4 col-md-4" style={Actionsdefined}>
                                        <label htmlFor="recipient-name" className="col-form-label" >Valor máximo pre establecido</label>
                                        <input type="text" className="form-control" value={DecoderData({Data:  data_bidding[0].Valmax, Type: "C"})} disabled/>                                
                                    </div>

                                    <div className="col-xl-4 col-md-4" style={Actionsdefined}>
                                        <label htmlFor="recipient-name" className="col-form-label" >Valor total Postulaciones</label>
                                        <input type="text" className="form-control" value={DecoderData({Data:  data_bidding[0].Valpost, Type: "C"})} disabled/>                                
                                    </div>                                                                        

                                    <div className="col-xl-4 col-md-4" style={Actionsdefined}>
                                        <label htmlFor="recipient-name" className="col-form-label" >Valor total gastado, fuera de impuestos</label>
                                        <input type="number" className="form-control" min={0} name="ValTotal" value={form_data.ValTotal} onChange={handleChange} required />                                
                                    </div>

                                    <div className="col-xl-12 col-md-12" style={Actionsdefined} >
                                        <label htmlFor="recipient-name" className="col-form-label" >Nota u Observación</label>
                                        <textarea className="form-control" name="Nota" rows="3" value={form_data.Nota} onChange={handleChange} ></textarea>                                    
                                    </div>

                                    <div className="col-xl-12 col-md-12" style={Actionsdefined}>
                                        <button style={ {marginTop:20} } type="submit" className="btn btn-primary" >Finalizar Oferta</button>                                                                                            
                                    </div>                                                                
                                </>
            }


            LoadBiddingPost = <div className="row" style={{margin:10}} >    
                                    <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop:20} } >
                                        <h5>Postulaciones</h5> 
                                    </div>                             

                                    <div className="col-xl-12 col-md-12" style={ {marginTop:20} } >
                                        <table className="table table-striped">
                                            <thead>
                                                <tr className="align-self-center text-center">
                                                    {/*<td style={Actionsdefined}>Seleccionar</td> */}
                                                    <td>Estado</td>
                                                    <td>Propuesta</td>
                                                    <td>Comercio</td>
                                                    <td>Valor</td>
                                                    <td>Entrega</td>
                                                    <td>Parcial</td>
                                                    <td>Ver</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data_biddingpost.map( (CurrentData,i) => {   
                                                        //ConsoleData(Object.values(form_data).includes(CurrentData.Cod_biddingpost));

                                                        return (
                                                            <tr key={i}>

                                                                {
                                                                    /*
                                                                        Se cambia visualizacion para no dejar uno a uno la seleccion 
                                                                        <td className="align-self-center text-center" style={Actionsdefined}> 
                                                                            <div>
                                                                                <span >
                                                                                    <input 
                                                                                        type="checkbox" 
                                                                                        //defaultChecked={form_data.includes("BiddingPost"+CurrentData.Cod_biddingpost)} 
                                                                                        checked={Object.values(form_data).includes(CurrentData.Cod_biddingpost)} 
                                                                                        value = {CurrentData.Cod_biddingpost}
                                                                                        name={"BiddingPost"+CurrentData.Cod_biddingpost} 
                                                                                        onChange={handleChangeCheck}  />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    */
                                                                <td className="align-self-center text-center" style={Actionsdefined}> 
                                                                        <strong className={(CurrentData.Status_bidding=="A" || CurrentData.Status_bidding=="F" ) ? "text-success" : ( (CurrentData.Status_bidding=="R" || CurrentData.Status_bidding=="N")? "text-danger" : "text-warning"  )  }>
                                                                            {CurrentData.Status_bidding=="A" ? "Aceptado" : ( CurrentData.Status_bidding=="R" ? "Rechazado" : ( CurrentData.Status_bidding=="N" ? "No aceptado" : ( CurrentData.Status_bidding=="F" ? "Finalizada" : "Pendiente"  )  )  )  }
                                                                        </strong>
                                                                </td>
                                                                }
                                                                

                                                                <td>
                                                                    {CurrentData.Adjunto.length>0 ?  <a href={icreappconfig.url_platform+CurrentData.Adjunto} target="_blank" >Ver archivo propuesta</a> :  "Sin Archivo"   }
                                                                    
                                                                </td>                                      

                                                                <td>{CurrentData.Name_store}</td>

                                                                <td>{DecoderData({Data: CurrentData.Total_value, Type: "C"})}</td>

                                                                <td>{CurrentData.Delivery}</td>

                                                                <td>{(CurrentData.Partial == "1" ? "Si" : "No, propuesta completa" )}</td>

                                                                <td>
                                                                    <span onClick={() => { viewHandlerBiddingsPost([CurrentData]); }}>
                                                                        <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11,color:'rgb(40, 167, 69)' }}></i>
                                                                    </span>
                                                                </td>                                                            

                                                            </tr>    
                                                        )                                                
                                                    })
                                                }                                        
                                            </tbody>        
                                        </table>   
                                    </div>
                                    {ManFormStatus}
                            </div>
        }else{
            LoadBiddingPost = <div className="row" style={{margin:40}}>
                                <h3 className="text-warning text-center">
                                    Aun no se ha registrado ninguna propuesta, verifica los filtros que asignaste al generar la oferta esto puede ser un motivo por el cual nadie se haya postulado.
                                </h3>
                            </div>;
        }

        return data_bidding.map(function (CurrentData,i){
            return (<div key={i}>
                        <form onSubmit={handleSubmit} >
                            <div className="row">

                                <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop:20} } >
                                    <h4>Datos aplicados a la oferta</h4> 
                                </div>                                 

                                <div className="col-xl-4 col-md-4">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Estado:</strong> {(CurrentData.Status_bidding=="A" ? "Activa" : (CurrentData.Status_bidding=="C" ? "Cancelado" : (CurrentData.Status_bidding=="F" ? "Finalizado" : (CurrentData.Status_bidding=="O" ? "Cerrada" : "Sin definir" ) )  ) )}</label>
                                </div>                                            

                                <div className="col-xl-4 col-md-4">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Expira:</strong> {CurrentData.Date_expire}</label>                                                                
                                </div>

                                <div style={ {marginTop:20} } className="col-xl-12 col-md-12 align-self-center text-center">                                            
                                    <h4>
                                        {CurrentData.Adjunto.length>0 ? <a href={icreappconfig.url_platform+CurrentData.Adjunto} target="_blank" >Ver archivo de la oferta</a> : "" }                                        
                                    </h4>
                                </div>   

                                <div style={ {marginTop:10} } className="col-xl-12 col-md-12 align-self-center text-center" >                                        
                                        <Handler_bidding_deta 
                                            Type = {"ViewMade"}
                                            Data={ CurrentData.deta.deta_bidding }
                                            HandlerData = {null}/>                                        
                                </div>                             

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Descripción:</strong> {CurrentData.Desc}</label>                                                                
                                </div> 

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Políticas de pago:</strong> {CurrentData.PolPag}</label>                                                                
                                </div> 


                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Solicitud:</strong>{(CurrentData.Type_bidding == "1" ? "Pública" : (CurrentData.Type_bidding == "2" ? "Directa" : "Sin definir")  )}</label>                                
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Tiempo máximo entrega "días":</strong> {CurrentData.Delivery}</label>                                 
                                </div>                                            


                            </div>

                            {LoadBiddingPost}
                            

                        </form>    
                    </div>
            )
        })   

        

    }

    handlerViewModal = () => {        
        //console.log("handlerViewModal");
        //console.log(props.length);    

        /*                
        const name_store = this.state.name_store;
        const data_detabidding = this.state.data_detabidding;
        */        
        const viewCommerce = this.viewCommerce;
        const onCloseModal = this.onCloseModal;        
        const handleChangeTMP = this.handleChangeTMP;
        const handleChangeCheckTMP = this.handleChangeCheckTMP;
        const handleSubmitStatus = this.handleSubmitStatus;
        const formTMP_data = this.state.formTMP;
        const data_detabiddingpost = this.state.data_detabiddingpost;        

        ConsoleData("data_detabiddingpost");
        ConsoleData(data_detabiddingpost);

        let Div_rechazado =<>                                 
                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" >Ingrese una nota del porque rechaza la propuesta</label>
                                    <textarea className="form-control" name="Note" rows="3" value={formTMP_data.Nota != undefined ? formTMP_data.Nota : formTMP_data.Nota} onChange={handleChangeTMP} required></textarea>                                                                    
                                </div>

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" >Como calificarias la propuesta del comercio, siendo 1 Muy mala - 5 Muy buena </label>
                                    <input type="number" className="form-control" min={1} max={5} step={0.5} name="Rating" value={formTMP_data.Rating != undefined ? formTMP_data.Rating : ""} onChange={handleChangeTMP} required />                                
                                </div>                                

                                <div className="col-xl-12 col-md-12">  
                                    <div className="form-check" style={ {marginTop:20} }>                                  
                                        <input type="checkbox" className="form-check-input"
                                            //defaultChecked={form_data.includes("BiddingPost"+CurrentData.Cod_biddingpost)} 
                                            checked={formTMP_data.ReportStore != undefined ? true : false} 
                                            value = {"R"}
                                            name={"ReportStore"} 
                                            onChange={handleChangeCheckTMP}  />
                                        <label className="form-check-label" ><h5><strong>Quieres Reportar este comercio por subir informacion que no corresponde</strong></h5></label>                                    
                                    </div>                                
                                </div>                                
                            </>    
        
        return data_detabiddingpost.map(function (CurrentData,i){
            ConsoleData("map data_detabiddingpost");
            ConsoleData(CurrentData.deta.deta_bidding);
            return (<form key={i} onSubmit={handleSubmitStatus}>
                        <div className="modal-body" >                                                                                        
                            
                            <div className="row" style={{margin:10}}>                                

                                <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop:20} } >
                                    <h4>Datos aplicados a la oferta</h4> 
                                </div>                                 

                                <div className="col-xl-12 col-md-12" style={{marginTop:10,marginBottom:10}}>
                                    <label htmlFor="recipient-name" className="col-form-label" >Estado</label>
                                    <select className="custom-select " value={formTMP_data.Status_change != undefined ? formTMP_data.Status_change : CurrentData.Status_bidding } onChange={handleChangeTMP} name={CurrentData.Cod_biddingpost} disabled={( CurrentData.Status_bidding != "P" ? true : false )} required>                                                    
                                        <option value="P">Pendiente</option>                                        
                                        <option value="A">Aceptado</option>
                                        <option value="R">Rechazada</option>                                        
                                        <option value="N" disabled>No Aceptado</option>                                        ç
                                        <option value="C" disabled>Cancelada</option>  
                                        <option value="F" disabled>Finalizada</option>                                        
                                    </select>
                                </div>

                                {formTMP_data.Status_change == "R" ? Div_rechazado : <div></div> }

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" >
                                        <strong>Comercio: </strong>{DecoderData({Data: CurrentData.Name_store, Type: "S"})}
                                        <span onClick={() => { viewCommerce(CurrentData.Cod_store); }}>
                                            <i className="fa fa-eye" style={{ width: 35, fontSize: 20, padding: 11,color:'rgb(40, 167, 69)' }}></i>
                                        </span>
                                    </label>                                
                                </div>
                                
                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Postulado: </strong>{CurrentData.Date_create}</label>                                
                                </div>                                

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Maneja parciales:</strong>{(CurrentData.Partial == "1" ? "Si" : "No, propuesta completa" )}</label>                                
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Valor total de la propuesta:</strong>{DecoderData({Data: CurrentData.Total_value, Type: "C"})}</label>                                
                                </div>                                

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Tiempo de entrega "días":</strong>{CurrentData.Delivery}</label>                                
                                </div>

                                <div style={ {marginTop:20} } className="col-xl-12 col-md-12 align-self-center text-center">
                                    <h4>
                                        {CurrentData.Adjunto.length>0 ? <a href={icreappconfig.url_platform+CurrentData.Adjunto} target="_blank" >Ver archivo de la propuesta</a> : "" }                                             
                                    </h4>
                                </div>

                                <div style={ {marginTop:10} } className="col-xl-12 col-md-12 align-self-center text-center" >                                        
                                        <Handler_bidding_deta 
                                            Type = {"ViewPostulation"}
                                            Data={ CurrentData.deta.deta_bidding }
                                            HandlerData = {null}/>                                        
                                </div>

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Políticas de pago de la oferta:</strong>{CurrentData.PolPag}</label>                                
                                </div>

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Valor agregado de la oferta:</strong>{CurrentData.Plus}</label>                                
                                </div>

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Nota adicional:</strong>{CurrentData.Note}</label>                                
                                </div>                                                               
                    
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={ (formTMP_data.Status_change == CurrentData.Status_bidding || CurrentData.Status_bidding!="P" ) ? true : false } >Registrar cambio de estado</button>                            
                            <button type="button" className="btn btn-secondary" onClick={() => onCloseModal()}>Cerrar</button>                
                        </div>
                    </form>
            )
        })        
    }

    render() {

        const { open } = this.state;
        let TitleModal = "Oferta postulada";
        let BodyPage = "";     
        let handle_add = "";   
        let handler_modal = "";      
        
        if(this.state.loading){
            BodyPage = <Loading />;
        }else{
            if(this.state.data_bidding == []){
                handle_add = <h3>{this.state.Msg_load}</h3>;
            }else{
                //console.log(this.state.data_bidding.length)
                if(this.state.data_bidding.length>0){
                    handle_add = <></>;
                    BodyPage = this.viewDataBody();
                }else{
                    handle_add = <h3>{this.state.Msg_load}</h3>;
                    BodyPage = <div></div>
                }                

            }

            if(this.state.data_detabiddingpost == []){
                handler_modal = <div className="modal-body"> <div className="modal-footer"></div> </div>;
            }else{
                handler_modal = this.handlerViewModal();
            }

        }

        return (
            <Fragment>
                <Breadcrumb title="Postulaciones y Estado de la Oferta" parent="Ofertas realizadas" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        {handle_add}
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{TitleModal}</h5>
                                            </div>   
                                            {handler_modal}                                         
                                        </Modal>

                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">  
                                        {BodyPage}                                                                                                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }

}

export default Bidding_postulation
import React from 'react';
import icreappconfig from '../Icreappconfig'


const ConsoleData = Data => {
    try {
        if(icreappconfig.in_debugger){
            console.log(Data);
        }    
    } catch (error) {
        
    }
    
}

export default ConsoleData
import React, { Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal';
import Datatable from '../common/datatable';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConsoleData from '../../hooks/ConsoleData';

export class Bidding_cancel extends Component {

    constructor(props) {
        super(props);        

        this.state = {
            Action: "V",
            open: false,
            data_bidding : [],            
            Msg_load: "",
            loading: true,            
            error: false            
        };
    }

    componentDidMount(){        
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataSubCategories();
            this.GetDataSession("IcreapSessionData");        
        }        
    }
    
    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            this.GetDataBidding()
        }
    }


    GetDataBidding = () => {
        const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);        

        setTimeout(() => {
            this.setState({
                loading: false,
                Msg_load: "No hay ofertas canceladas"
            })
        },1000);         

        return;

        const formData = new FormData(); 
        formData.append('Action',this.state.Action);    

        ///Se reinician estados para control de datos
        this.setState({
            data_bidding: [],
            loading: true            
        })
        
        try{
            axios.post(Urlquery,formData)
                .then(res => {
                    //console.log(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        
                        let Man_bidding = [];
                        if(res.data[0].Data_bidding.length != 0){
                            
                            res.data[0].Data_bidding.map( (CurrentService,i) => {                                                                                        
    
                                Man_bidding[i] = {                            
                                    id: parseInt(CurrentService.cod_bidding),                                    
                                    Codigo: parseInt(CurrentService.cod_store),                                    
                                    Comercio: CurrentService.name_store         
                                }                                                
                                
                            })                            

                        }                        


                        this.setState({                            
                            data_bidding : Man_bidding, 
                            loading : false,                           
                            Msg_load: decoder,                            
                            error: false                        
                        })           
                                                
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            data_bidding : res.data[0].Man_bidding,
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }




    onOpenModal = () => {
        
        this.setState({         
            open: true            
        });

    };

    onCloseModal = () => {        
        this.setState({
            open: false                                           
        })  
    };



    viewBiddingPost = (props) => {
        ConsoleData(props.datos.id);
        //ConsoleData(props[0].Codigo);
                
        const Data_bidding = { Cod_bidding: props.datos.id, Cod_store: props.datos.Codigo };
        ConsoleData(Data_bidding);
        
        sessionStorage.removeItem("Data_bidding");      
        sessionStorage.setItem("Data_bidding", JSON.stringify(Data_bidding) );
        this.props.history.push(`${process.env.PUBLIC_URL}/bidding/bidding_postulation`);

    }

    render() {

        const { open } = this.state;
        let TitleModal = "Ofertas activas";
        let BodyPage = "";     
        let handle_add = "";   
        let handler_modal = "";      
        
        if(this.state.loading){
            BodyPage = <Loading />;
        }else{
            if(this.state.data_bidding == []){
                handle_add = <h3>{this.state.Msg_load}</h3>;
            }else{
                //console.log(this.state.data_bidding.length)
                if(this.state.data_bidding.length>0){
                    handle_add = <></>;
                    BodyPage = <Datatable
                                    multiSelectOption={false}
                                    myData={this.state.data_bidding} 
                                    pageSize={10} 
                                    pagination={true}
                                    class="-striped -highlight"     
                                    viewHandler={this.viewBiddingPost}                                    
                                />
                }else{
                    handle_add = <h3>{this.state.Msg_load}</h3>;
                    BodyPage = <div></div>;
                }                

            }

        }

        return (
            <Fragment>
                <Breadcrumb title="Ofertas Canceladas" parent="Ofertas" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        {handle_add}
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{TitleModal}</h5>
                                            </div>   
                                            {handler_modal}                                         
                                        </Modal>

                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">  
                                        {BodyPage}                                                                                                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }


}

export default Bidding_cancel
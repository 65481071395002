import React, { Component, Fragment } from 'react'
import Slider from 'react-slick';

import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { ArrowLeft,Mail,UserCheck } from 'react-feather';

///Librerias utilizadas IcreApp
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import DecoderData from '../../hooks/DecoderData'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
///////////////////////////////

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ConsoleData from '../../hooks/ConsoleData';

//const image_logo = icreappconfig.url_images+"iconos/Icono_logo.jpg";
const image_logo = icreappconfig.url_images+"iconos/logo_color.png";


export class TermCond extends Component {    

    constructor(props) {
        super(props);        
    }
    
    
    loginrouteChange = () => {
        //Se cambia a ruta temporal mientras se define dashboard
        //this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
        this.props.history.push(`${process.env.PUBLIC_URL}/auth/logout`);
    }
        
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false
        };

        let Body_panel = <div>
                <p style={{color: "#000000"}}>
                Declaro que autorizo a la plataforma MosquerApp y a la Alcaldía de Mosquera para la recolección y tratamiento de mis datos personales, 
                conforme a la política de tratamiento de datos personales de la administración municipal; entiendo que los datos serán objeto de recolección, 
                almacenamiento, uso, circulación, supresión, transferencia, transmisión, cesión y todo el tratamiento, con la finalidad de obtener información 
                para los servicios y actividades realizados por la Alcaldía y sus dependencias. Declaro que se me ha informado que, como titular de la información
                , tengo derecho a conocer, actualizar y rectificar mis datos personales, solicitar prueba de la autorización otorgada para su tratamiento
                , ser informado sobre el uso que se ha dado a los mismos, presentar quejas ante la Superintendencia de Industria y Comercio por infracción a la ley
                , revocar la autorización y/o solicitar la supresión de mis datos en los casos en que sea procedente y acceder en forma gratuita a los mismos. 
                Así mismo, conozco que las consultas y reclamos podrán ser presentados a la Alcaldía de Mosquera, como responsable del tratamiento de la información, 
                a través del correo electrónico <span style={{color: "#095f28"}}>contactenos@mosquera-cundinamarca.gov.co</span> y los teléfonos fijos <span style={{color: "#095f28"}}>+57 1 8276366 / 8276666</span>
                , en cumplimiento de la Ley 1581 de 2012, el Decreto No. 1377 de 2013 y demás normas concordantes.
                </p>                
            </div>;




        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 p-0 card-left">
                                    <div className="card bg-primary">
                                        
                                        {
                                            /*
                                            <div className="svg-icon">
                                                <img src={image_logo} className="Img-fluid" />
                                            </div>
                                             */
                                        }
                                        
                                        <div className="container" >
                                                <img src={image_logo} className="img-responsive" height="180" width="200" style={{display: "block", margin: "auto" }} />
                                        </div>
                                                                                
                                        <Slider className="single-item" {...settings}>
                                            <div>
                                                <div>
                                                    <h3 style={{color: "#095f28"}}>No te preocupes, MosquerApp</h3>
                                                    <p style={{color: "#095f28"}}>Siempre estará para ayudarte. Acá encontraras todo lo que necesitas</p>
                                                </div>
                                            </div>                                                                                        
                                        </Slider >
                                    </div>
                                </div>
                                <div className="col-md-7 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <div>
                                                <Fragment>
                                                    <Tabs>
                                                        <TabList className="nav nav-tabs tab-coupon" >
                                                            <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><UserCheck />Terminos y Condiciones</Tab>
                                                        </TabList>

                                                        <TabPanel>
                                                            {Body_panel}
                                                        </TabPanel>
                                                                                                                
                                                    </Tabs>
                                                </Fragment>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>      

                            <a href="/" className="btn btn-primary back-btn"><ArrowLeft />Volver al Comercio</a>

                        </div>
                    </div>
                </div>
                <ToastContainer />
            </Fragment>
        )
    }
}

export default TermCond
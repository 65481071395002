import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable'
import data from '../../assets/data/media';
import Modal from 'react-responsive-modal';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConsoleData from '../../hooks/ConsoleData';


export class Publication_apps extends Component {
    

    constructor(props) {
        super(props);        

        this.state = {
            Action: "C",
            Action_form: "R",
            open: false,
            datatable_publication : [], 
            data_publication: [],  
            Max_size : 1*1000000, //Se maneja máximo de 1MB 
            form : {
                id: 0,
                Title:"",
                Desc: "",
                Status: "A",
                Type_file: "P",
                Orden: 0,
                Grupo: ""               
            },      
            file: null,  
            file_name: "",
            Msg_load: "",
            loading: true,  
            loading_modal: false,
            error: false            
        };
    }

    componentDidMount(){        
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataSubCategories();
            this.GetDataSession("IcreapSessionData");        
        }        
    }
    
    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            this.GetDataPublication()
        }
    }

    GetDataPublication = () => {
        const Urlquery = icreappconfig.url_controller+"AdminPublications.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);

        const formData = new FormData(); 
        formData.append('Action',this.state.Action);    

        ///Se reinician estados para control de datos
        this.setState({
            datatable_publication : [], 
            data_publication: [],  
            loading: true            
        })

        try{
            axios.post(Urlquery,formData)
                .then(res => {
                    
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        
                        let Man_publication = [];
                        if(res.data[0].Data.length != 0){
                            
                            res.data[0].Data.map( (CurrentService,i) => {           
                                
                                let Type_publication = "";
                                let Status_publication;
                                if(CurrentService.status == "A"){
                                    Status_publication = <i className="fa fa-circle font-success f-12" > Activo </i>;                                                               
                                }else if (CurrentService.status == "I") {
                                    Status_publication = <i className="fa fa-circle font-danger f-12" > Inactivo </i>;                             
                                }else if (CurrentService.status == "P") {
                                    Status_publication = <i className="fa fa-circle font-secundary f-12" > Prueba </i>;                             
                                }else{
                                    Status_publication = <i className="fa fa-circle font-secundary f-12" > Borador </i>;                             
                                }

                                if(CurrentService.type_publication == "N"){
                                    Type_publication = "Noticia";
                                }else if(CurrentService.type_publication == "T"){
                                    Type_publication = "Turismo";
                                }else{
                                    Type_publication = "Sin Asignar";
                                }
    
                                Man_publication[i] = {                            
                                    id: i,    
                                    title: CurrentService.title,                                 
                                    //descripcion: CurrentService.description, 
                                    image: <div><img src={icreappconfig.url_platform+"images/Iconos/"+CurrentService.image_icon} height="100" width="100" /></div>,
                                    Type: Type_publication,
                                    public_date: CurrentService.public_date,
                                    status : Status_publication                                                                              
                                }                                                
                                
                            })                            

                        }                        


                        this.setState({                            
                            datatable_publication : Man_publication, 
                            data_publication: res.data[0].Data,
                            loading : false,                           
                            Msg_load: decoder,                            
                            error: false                        
                        })           
                                                
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            datatable_publication : [], 
                            data_publication: [],
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            datatable_publication : [], 
                            data_publication: [],
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
    
    
    }


    onOpenModal = () => {

        //ConsoleData("onOpenModal");
        //console.log(props);
        
        this.setState({         
            open: true,            
            file: null,
            name_file: ""
        });
        

    };

    onCloseModal = () => {        
        this.setState({
            open: false,
            form : {
                id: 0,
                Desc: "",
                Status: "A",
                Type_file: "P",
                Orden: 0,
                Grupo: ""                
            },      
            file: null,  
            file_name: ""            
        })  
    };

    handleSubmit = async e => {
        ConsoleData("Submit")

        e.preventDefault();                

        if(this.state.file == null && this.state.form.id == 0){
            toast.error("Debes seleccionar un archivo correcto")
            return;
        }

        if(this.state.form.Desc.length<15){
            toast.error("La descripción debe ser mayor a 15 caracteres")
            return;            
        }


        try {                        
                
            const Urlquery = icreappconfig.url_controller+"upload_file.php";
            ConsoleData(Urlquery);            

            const formData = new FormData();   

            formData.append("Public",this.state.file);
            formData.append('Action',this.state.Action_form);    
            formData.append('User_email',this.state.User_email);    
            formData.append('User_type',this.state.User_type);    
            formData.append('form',JSON.stringify(this.state.form)); 

            //console.log(formData);                

            this.setState({                            
                loading_modal: true,
                error: false,
                Msg_error: ""                        
            });        
            
            axios.post(Urlquery, formData)            
                .then(res => {
                    ConsoleData(res.data);   
                   
                    if(res.data[0].Status){

                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       
                        
                        this.setState({
                            loading_modal: false,
                            Msg_load: decoder,
                            error: false                            
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio                        
                        toast.success(decoder);                                                
                        this.onCloseModal();        
                        this.GetDataPublication();                              
                    }else{  
                        //alert(res.data[0].Msg); 
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 

                        toast.error( decoder );
                        this.setState({
                            loading_modal: false,
                            error: false,
                            Msg_load: decoder
                        })
                        
                   }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading_modal: false,
                        error: true,
                        err
                    })
                });                                    

            ////this.props.history.push('/exercise')

        } catch (error) {
            this.setState({
                loading_modal: false,
                error
            }) 
        }
        

        
    }

    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })
        
    }    

    handleChangeFile = e => {
        ConsoleData(e.target.files[0]);

        ///Se valida el tamaño máximo de los archivos
        if(e.target.files[0].size > this.state.Max_size ){
            this.setState({       
                file: null,    
                file_name: "",    
                error: true,
                Msg_error: "El archivo no puede superar el tamaño de "+(this.state.Max_size/1000000)+" MB"
            }) ;

            toast.error("El archivo no puede superar el tamaño de "+(this.state.Max_size/1000000)+" MB");
        }else{
            this.setState({
                file : e.target.files[0],
                file_name: e.target.value,    
                loading: false,                
                error: false,
                Msg_error: ""                
            });
        }
        
    }


    handlerViewModal = () => {
        
        //ConsoleData("handlerViewModal");
        ///ConsoleData(props);    

        /*        
        const handleChangeFile = this.handleChangeFile;        
        const name_store = this.state.name_store;
        */

        const onCloseModal = this.onCloseModal;
        const form_data = this.state.form;
        const handleChange = this.handleChange;
        const handleSubmit = this.handleSubmit;        
        
        let MsgButton;
        if(form_data.id != 0 ){
            MsgButton = "Publicar Cambios";
        }else{
            MsgButton = "Publicar Archivo";
        }

        return (<form onSubmit={handleSubmit} >
                    <div className="modal-body" >                                
                        <div className="row">                            

                            <div className="col-xl-12 col-md-12">
                                <label htmlFor="recipient-name" className="col-form-label" >Descripción del archivo</label>
                                <textarea className="form-control" name="Desc" rows="3" value={form_data.Desc} onChange={handleChange} required></textarea>
                            </div> 

                            <div className="col-xl-12 col-md-12">
                                <label htmlFor="recipient-name" className="col-form-label" >{ form_data.id != 0 ? "Cambiar adjunto" : "Seleccionar Adjunto" }</label>
                                <input type="file" className="form-control" onChange={this.handleChangeFile} required={form_data.id !=0 ? false : true}/>
                            </div>


                            <div className="col-xl-12 col-md-12">
                                <label htmlFor="recipient-name" className="col-form-label" >Tipo de Archivo</label>
                                <select className="custom-select" value={form_data.Type_file} onChange={handleChange} name="Type_file" required>                                                
                                    <option value="P">Publico</option>
                                    <option value="V">Privado</option>                                    
                                    <option value="H">Ayuda</option>                                    
                                </select>
                            </div>

                            <div className="col-xl-6 col-md-6">
                                <label htmlFor="recipient-name" className="col-form-label" >Orden de visualización</label>
                                <input type="number" min={0} step={1} className="form-control" name="Orden" value={form_data.Orden} onChange={handleChange} />
                            </div> 

                            <div className="col-xl-6 col-md-6">
                                <label htmlFor="recipient-name" className="col-form-label" >Grupo</label>
                                <input type="text" className="form-control" name="Grupo" value={form_data.Grupo} onChange={handleChange} />
                            </div> 

                            <div className="col-xl-12 col-md-12">
                                <label htmlFor="recipient-name" className="col-form-label" >Estado del archivo</label>
                                <select className="custom-select " value={form_data.Status} onChange={handleChange} name="Status" disabled={(form_data.id ==0 ? true : false )} required>                                                
                                    <option value="A">Activo</option>
                                    <option value="I">Inactivo</option>                                    
                                </select>
                            </div>


                        </div>
                    </div>   
                    <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" >{MsgButton}</button>
                            <button type="button" className="btn btn-secondary" onClick={() => onCloseModal()}>Cerrar</button>                
                    </div> 
                </form>);       
    }

    viewFileSelect = (props) => {
        ConsoleData(props.datos);
        //ConsoleData(props[0].Codigo);   
        const DataFile = this.state.data_publication[props.datos.id];
        ConsoleData(DataFile);

        this.setState({
            open: true,            
            file: null,
            form : {
                id: DataFile.id_file,
                Desc: DataFile.desc_file,
                Status: DataFile.status,
                Type_file: DataFile.type_file,
                Orden: DataFile.orden,
                Grupo: DataFile.grupo               
            }
        });                

    }



    render() {

        const { open } = this.state;
        let TitleModal = "Publicaciones App";
        let BodyPage = "";     
        let handle_add = "";           
        let LoadModal = "";  

        if(this.state.loading){
            BodyPage = <Loading />
            LoadModal = <div></div>;
        }else{            

            handle_add = <button type="button" className="btn btn-primary" 
                            onClick={() => this.onOpenModal({Type: "N", Data: []})} 
                            data-toggle="modal" data-original-title="test" 
                            data-target="#exampleModal">
                                Nueva publicación
                        </button>;
            
            if(this.state.loading_modal){
                LoadModal = <Loading />;
            }else{
                LoadModal = this.handlerViewModal();
            }
            

            if(this.state.datatable_publication.length == 0){
                BodyPage = <h3>{this.state.Msg_load}</h3>;                
            }else{
                BodyPage = <Datatable
                                multiSelectOption={false}
                                myData={this.state.datatable_publication}
                                pageSize={10}
                                pagination={true}
                                class="-striped -highlight"
                                editHandler={this.viewFileSelect}                                    
                            />                
            }            

        }
        

        return (
            <Fragment>
                <Breadcrumb title="Publicaciones App" parent="Publicaciones" />
                <div className="container-fluid bulk-cate">                    
                    <div className="card">
                        <div className="card-header">
                            <h5>Publicaciones App</h5>

                            <div className="btn-popup pull-right">
                                {handle_add}
                                <Modal open={open} onClose={this.onCloseModal} >
                                    <div className="modal-header">
                                        <h5 className="modal-title f-w-600" id="exampleModalLabel2">{TitleModal}</h5>
                                    </div>
                                    {LoadModal}
                                </Modal>
                            </div>

                        </div>                        

                        <div className="card-body">
                            <div id="batchDelete" className="category-table media-table coupon-list-delete">
                                {BodyPage}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }
}

export default Publication_apps

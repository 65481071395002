import React, { Component,Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';

///Librerias utilizadas IcreApp
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import ConsoleData from '../../hooks/ConsoleData'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
///////////////////////////////

export class User_request extends Component {

    constructor(props) {
        super(props);

        //this.GetDataCategories = this.GetDataCategories.bind(this);   
        //this.editHandler = this.editHandler.bind(this);     

        this.state = {
            open: false,
            data_type: [],            
            form:{
                Name: "",
                Phone: "",
                Email: "",
                Type_user: ""
            },
            Action: "T",
            loading: true,
            error: false,   
            Msg_load: '',    
            User_email: '',
            User_type: '',     
            Msg_Error: ''
        };    
    }

    componentDidMount(){
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataTypes();
            this.GetDataSession("IcreapSessionData");        
        }        
    }

    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })        
    }

        
    LoadOptionSelect(props){
        
        //console.log("LoadOptionSelect")
        //console.log(props)

        return props.map(function (CurrentData,i){
            return (
                <option value={CurrentData.cod}> {CurrentData.name} </option>                
            ) 
        })

    }

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            this.GetDataTypes([DataValue.data[0].Email,DataValue.data[0].Tipo])

        }
    }

    GetDataTypes = (SessionData) => {
        const Urlquery = icreappconfig.url_controller+"LoadUsers.php";
        //console.log(Urlquery);    
        ConsoleData(Urlquery);      

        const Params = {
            Action: this.state.Action,
            Email: SessionData[0],
            Type: SessionData[1]            
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_type: [],
            loading: true            
        })

        //console.log(Params); 
        ConsoleData(Params);  

        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    //console.log(res.data); 
                    ConsoleData(res.data);    
                    
                    if(res.data[0].Status){                        

                        this.setState({
                            data_type: res.data[0].Data,
                            loading: false,
                            error: false                        
                        })                    
                    }else{     
                        this.setState({    
                            data_type: [],                        
                            loading: false,
                            Msg_load: res.data[0].Msg,
                            error: false                        
                        })                                   
                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }

    handleSubmit = async e => {
        //console.log("Submit")
        ConsoleData("Submit");
        
        e.preventDefault();

        this.setState({
            loading: true
        })

        try {
            
            //console.log(this.state.form);            
            ConsoleData(this.state.form);
            
            const Urlquery = icreappconfig.url_controller+"Reg_UserLogin.php";
            //console.log(Urlquery);        
            ConsoleData(Urlquery);
    
            const Params = {
                Action : "P",
                Email : this.state.User_email,
                Type : this.state.User_type,
                Form : this.state.form
            }
    
            //console.log(Params); 
            ConsoleData(Params);
    
            axios.post(Urlquery,Params)
                .then(res => {
                //console.log(res.data);   
                ConsoleData(res.data);
                
                if(res.data[0].Status){
                        
                    this.setState({
                            loading: false,
                            form:{
                                Name: "",
                                Phone: "",
                                Email: "",
                                Type_user: ""
                            },
                            error: false
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio
                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 
                        toast.success(decoder);                                                                        
                }else{  
                    //alert(res.data[0].Msg); 
                    //let decoder = decodeURIComponent(escape(res.data[0].Msg));                       

                    let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 
                    toast.error( decoder );
                    
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: res.data[0].Msg
                    })
                    
                }
                
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

                ////this.props.history.push('/exercise')

        }catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }

    
    }

    render() {

        let body_panel;

        if(this.state.loading){
            body_panel = <Loading />;
        }else{
            body_panel = <form className="needs-validation user-add" onSubmit={this.handleSubmit} >                                                
                            <div className="form-group row">                                
                                <label className="col-xl-3 col-md-4"><span>*</span>Tipo de Invitacion</label>
                                <select className="custom-select col-xl-8 col-md-7" value={this.state.form.Type_user} onChange={this.handleChange} name="Type_user" id="Type_user" required>            
                                    <option value="">Selecciona el tipo:</option>
                                    {this.LoadOptionSelect(this.state.data_type)}
                                </select>
                            </div>      

                            <div className="form-group row">
                                <label className="col-xl-3 col-md-4"><span>*</span>Nombre Completo</label>
                                <input className="form-control col-xl-8 col-md-7" value={this.state.form.Name} onChange={this.handleChange} name="Name" id="Name" type="text" required />
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-md-4"><span>*</span>Numero de contacto</label>
                                <input className="form-control col-xl-8 col-md-7" value={this.state.form.Phone} onChange={this.handleChange} name="Phone" id="Phone" type="tel" required />
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-md-4"><span>*</span> Email</label>
                                <input className="form-control col-xl-8 col-md-7" value={this.state.form.Email} onChange={this.handleChange} name="Email" id="Email" type="email" required/>
                            </div>                            

                            <div className="form-group row">                                          
                                <div className="pull-right">
                                    <button type="submit" className="btn btn-primary">Enviar Solicitud</button>
                                </div>
                            </div>      
                        </form>;
        }


        return (
            <Fragment>
                <Breadcrumb title="Solicitud de usuario" parent="Usuarios" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                {
                                /*
                                <div className="card-header">
                                    <h5>Adicionar Usuario</h5>
                                </div>
                                */
                                }
                                
                                <div className="card-body">
                                <Fragment>
                                    <Tabs>
                                        <TabList className="nav nav-tabs tab-coupon" >
                                            <Tab className="nav-link">Solicitud</Tab>
                                        </TabList>
                                        <TabPanel>
                                            {body_panel}
                                        </TabPanel>                
                                    </Tabs>                                    
                                </Fragment>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </Fragment>
        )
    }
}

export default User_request

import React from 'react';

////Manejo de modal para asignacion
//import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ManualForm_services from './manual_form_services'
import ConsoleData from '../../hooks/ConsoleData'


const formatterPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
})

function ModalFormData(props) {
    //console.log(props);
    ConsoleData(props);    

    return (
        <Modal        
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Crear servicio manual
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>            
            <ManualForm_services DataService={props} />                        
        </Modal.Body>
        <Modal.Footer>
            {
                /*
                <Button onClick={props.onHide}>Close</Button>                
                */
            }            
        </Modal.Footer>
      </Modal>
    );
}

function  ManualTableServices(props) {

    //console.log(props);
    ConsoleData(props);    
    
    const [modalShow, setModalShow] = React.useState(false);
    const [ManCurrendServices, setCurrentservices] = React.useState("");

    return(
        <>                        
            <ModalFormData
                show={modalShow}
                onHide={() => setModalShow(false)}   
                RefreshData = {props.RefreshData}             
                DataServices = {ManCurrendServices}                           
            />
                        
            <button type="button" className="btn btn-primary" onClick={() => { setModalShow(true); }}>Crear Servicio</button>
                    
            <table className="table table-striped" style={ {marginTop:20,paddingLeft:20,paddingRight:20}  } >
                <thead>
                    <tr>
                        <th>Servicio Agendado</th>
                        <th>Usuario</th>
                        <th>Telefono</th>
                        <th>Placa o Serie</th>
                        <th>Dirección</th>                            
                        <th>Metodo de Pago</th>
                        <th>Costo Total</th>                                    
                    </tr>
                </thead>
                <tbody>                                    

                    {

                        props.DataService.map( (CurrentService,i) => {                            
                            return (
                                <tr key={i} >
                                    <td >{CurrentService.agenda_date} {CurrentService.agenda_time} </td>
                                    <td >{CurrentService.name_user}</td>
                                    <td >{CurrentService.phone_user}</td>
                                    <td >{CurrentService.name_type} - {CurrentService.serie}</td>
                                    <td >{CurrentService.city} - {CurrentService.address_client}</td>                                        
                                    <td >{CurrentService.Payment}</td>
                                    <td >{formatterPeso.format(CurrentService.total_cost)}</td>                                                                
                                </tr>
                            )
                            
                        })

                    }


                </tbody>
            </table>
            
        </>    
    )

    
}


export default ManualTableServices;



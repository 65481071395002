import React, { Component,Fragment } from 'react';
import Loading from '../Loading'
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import ConsoleData from '../../hooks/ConsoleData'

export class Form_services extends Component {    
        
    constructor(props){
        super(props)        

        //console.log("Form_services");
        //console.log(props.DataService);

        this.state = {
            onHide: props.DataService.onHide,
            RefreshData : props.DataService.RefreshData,
            UpdateData: props.UpdateData,
            Cod_Agenda: props.DataService.DataServices.cod_agenda,            
            Action: "S",
            form: {
                Status_Agenda: '',
                Status_Cliente: '',
                Status_Delivery: '',
                Cod_Delivery: '',
                ManStatusDelivery: false,
                Cod_Agenda: props.DataService.DataServices.cod_agenda
            },               
            loading: true,
            error: null            
        }        

        this.GetStatusServices = this.GetStatusServices.bind(this);
        this.GetDataSession = this.GetDataSession.bind(this);

    }

    componentDidMount(){
        this.GetStatusServices();
        ///Se traen datos de sesion
        this.GetDataSession("IcreapSessionData");        
    }    

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

        }
    }


    GetStatusServices = () => {
        const Urlquery = icreappconfig.url_controller+"LoadStatusServices.php";
        //console.log(Urlquery);        

        const Params = {
            Action : this.state.Action,            
            Cod_agenda : this.state.Cod_Agenda            
        }

        //console.log(Params); 

        axios.post(Urlquery,Params)
             .then(res => {

                //console.log("FormService query");   
                //console.log(res.data);   
                
                if(res.data[0].Status){
                    
                    ///Manejo de status delivery
                    let BoolManStatusDeliver = false;
                    if (res.data[0].DataStatus[0].name_status_delivery == "Sin Asignar"){
                        BoolManStatusDeliver = true;
                    } 

                    this.setState({
                        DataStatus: res.data[0].DataStatus,
                        Delivery: res.data[0].Delivery,
                        StatusCustomer: res.data[0].StatusCustomer,
                        StatusDelivery: res.data[0].StatusDelivery,
                        StatusStore: res.data[0].StatusStore,
                        form: {
                            Status_Agenda: res.data[0].DataStatus[0].cod_status_store,
                            Status_Cliente: res.data[0].DataStatus[0].cod_status_customer,
                            Status_Delivery: res.data[0].DataStatus[0].cod_status_delivery,
                            Cod_Delivery: res.data[0].DataStatus[0].cod_delivery,
                            ManStatusDelivery: BoolManStatusDeliver,
                            Cod_Agenda: this.state.Cod_Agenda
                        },
                        loading: false,
                        error: false                        
                    })                    
                }else{                    
                }
                
             })
             .catch(err => {
                    console.error(err);
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
            });
    }

    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })
        
    }

    handleSubmit = async e => {
        //console.log("Submit")
        ConsoleData("Submit");            

        e.preventDefault() 
        this.setState({
            loading: true
        })

        try {
            
            //console.log(this.state.form);
            ConsoleData(this.state.form);                            
            
            this.setState({
                loading: false
            })
            


           const Urlquery = icreappconfig.url_controller+"ChangeStatusServices.php";
           //console.log(Urlquery);        
           ConsoleData(Urlquery);    
   
           const Params = {
                Action : "B",
                User_email : this.state.User_email,
                User_type : this.state.User_type,
                Form : this.state.form
           }
   
           //console.log(Params); 
           ConsoleData(Params);    
   
           axios.post(Urlquery,Params)
                .then(res => {
                   //console.log(res.data);   
                   ConsoleData(res.data);    
                   
                   if(res.data[0].Status){
                        this.setState({
                            loading: false,
                            error: false
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio
                        alert(res.data[0].Msg);  
                        this.state.RefreshData();
                        this.state.onHide()            

                   }else{  
                    alert(res.data[0].Msg);                        
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: res.data[0].Msg
                        })
                    
                   }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            ////this.props.history.push('/exercise')

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        //console.log(this.state)
    }

    LoadOptionStatus(props){
        
        //console.log("LoadOptionStatus")
        //console.log(props)

        return props.map(function (CurrentData,i){
            return (
                <option value={CurrentData.cod_status}> {CurrentData.name_status} </option>                
            ) 
        })

    }

    LoadDelivery(props){
        return props.map(function (CurrentData,i){
            return (
                <option value={CurrentData.cod_delivery}> {CurrentData.alias+" - "+CurrentData.name_delivery} </option>                
            ) 
        })
    }



    render(){                

        //console.log(this.state.DataStatus);
        ConsoleData(this.state.DataStatus);         

        if(this.state.loading)
            return <Loading msg="Grabando..." />

        return(
            <div className="border border-warning" style={ {marginLeft: 20, marginRight: 20, marginTop: 20, padding: 20 } }>                                           
                
                <h4 className="align-self-center text-center"> <strong>Asignaciones</strong> </h4>

                <form onSubmit={this.handleSubmit} style={ { marginTop: 10 } }>

                    <div className="row align-items-center">
                        <div className="col">                    

                            <div className="form-group" >
                                <label >Estado actual del Servicio: <strong style={ { marginLeft: 10 } }>{this.state.DataStatus[0].name_status_store}</strong></label> 
                            </div>

                        </div>
                        <div className="col">                                                
                            
                            <select className="custom-select custom-select-sm" value={this.state.form.Status_Agenda} onChange={this.handleChange} name="Status_Agenda" id="Status_Agenda" style={ { marginLeft: 10 } }>            
                                <option value="">Cambiar estado:</option>
                                {this.LoadOptionStatus(this.state.StatusStore)}                                                                    
                            </select>
                        
                        </div>    
                    </div>

                    <div className="row align-items-center">
                        <div className="col">                    

                            <div className="form-group" >
                                <label >Estado actual del Cliente: <strong style={ { marginLeft: 10 } }>{this.state.DataStatus[0].name_status_customer}</strong></label> 
                            </div>

                        </div>
                        <div className="col">                                                
                            
                            <select className="custom-select custom-select-sm" value={this.state.form.Status_Cliente} onChange={this.handleChange} name="Status_Cliente" id="Status_Cliente" style={ { marginLeft: 10 } }  disabled>            
                                <option value="">Cambiar estado</option>
                                {this.LoadOptionStatus(this.state.StatusCustomer)}                                                                    
                            </select>
                        
                        </div>    
                    </div>

                    <div className="row align-items-center">
                        <div className="col">                    

                            <div className="form-group" >
                                <label >Estado actual del Akualiver: <strong style={ { marginLeft: 10 } }>{this.state.DataStatus[0].name_status_delivery}</strong></label> 
                            </div>

                        </div>
                        <div className="col">                    
                                                    
                            <select className="custom-select custom-select-sm" value={this.state.form.Status_Delivery} onChange={this.handleChange} name="Status_Delivery" id="Status_Delivery" style={ { marginLeft: 10 } }  disabled={ this.state.form.ManStatusDelivery ? 'disabled' : '' }>            
                                <option value="">Cambiar estado</option>
                                {this.LoadOptionStatus(this.state.StatusDelivery)}                                                                    
                            </select>
                                                
                        </div>    
                    </div>

                    <div className="row align-items-center">
                        <div className="col">                    

                            <div className="form-group" >
                                <label >Akualiver Seleccionado: <strong style={ { marginLeft: 10 } }>{this.state.DataStatus[0].name_user}</strong></label> 
                            </div>

                        </div>
                        <div className="col">                    
                                                        
                            <select className="custom-select custom-select-sm" value={this.state.form.Cod_Delivery} onChange={this.handleChange} name="Cod_Delivery" id="Cod_Delivery" style={ { marginLeft: 10 } }>            
                                <option value="">Cambiar Akualiver</option>
                                {this.LoadDelivery(this.state.Delivery)}                                                                    
                            </select>                        

                        </div>    
                    </div>
                    
                    <div className="form-row align-self-center text-center" >                        
                        <button type="submit" className="btn btn-primary" > Guardar Cambios </button>                        
                    </div>    
                </form>
                                
            </div>
        )


    }

}

export default Form_services
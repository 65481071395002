import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal';
import Datatable from '../common/datatable';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import ConsoleData from '../../hooks/ConsoleData'
import DecoderData from '../../hooks/DecoderData'
import Activity_finder from '../specific/activity_finder'
import File_upload from './file_upload'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Plus, User } from 'react-feather';

const bannerheader = "";


export class Data_Store extends Component {

    constructor(props) {
        super(props);

        this.GetDataStore = this.GetDataStore.bind(this);

        this.state = {
            open: false,
            open_mfile: false,
            data_store: [],
            data_groups: [],
            data_cat: [],
            data_subcat: [],
            Type_file: "C",
            Max_size: icreappconfig.max_size_verif * 1000000, //Se maneja máximo de 7MB 
            file_cc: null,
            file_rut: null,
            tmp: {
                Cod_subcategory: "",
                Name_subcategory: ""
            },
            tmp_contacts: {
                Name_contact: "",
                Phone_contact: "",
                Email_contact: ""
            },
            form: {
                deta: {
                    sub_categories: [],
                    contacts: [],
                    Empleados: "",
                    Experience: "",
                    FacElect: "",
                    Tel_store: "",
                    Address_store: "",
                    Lat_store: "",
                    Long_store: ""
                },
                Id_store: "",
                Name_store: "",
                Desc_store: ""
            },
            Action: "V",
            Action_form: "R",
            loading: true,
            error: true,
            Msg_load: '',
            User_email: '',
            User_type: '',
            Msg_Error: ''
        };
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };


    onOpenModalFile = (type_file) => {

        this.setState({
            Type_file: type_file,
            open_mfile: true
        });

    };

    onCloseModalFile = e => {

        this.setState({ open_mfile: false });
        if (e == "A") {
            this.GetDataSession("IcreapSessionData");
        }

    }

    onCloseModal = () => {

        let DataStoreLoad = this.state.data_store;

        if (this.state.data_store.length == 0) {

            this.setState({
                open: false,
                tmp: {
                    Cod_subcategory: "",
                    Name_subcategory: ""
                },
                form: {
                    deta: {
                        sub_categories: [],
                        contacts: [],
                        Empleados: "",
                        Experience: "",
                        FacElect: "",
                        Tel_store: "",
                        Address_store: "",
                        Lat_store: "",
                        Long_store: ""
                    },
                    Id_store: "",
                    Name_store: "",
                    Desc_store: ""
                }
            });


        } else {

            this.setState({
                open: false,
                tmp: {
                    Cod_group: "",
                    Cod_category: "",
                    Cod_subcategory: "",
                    Name_subcategory: ""
                },
                form: DataStoreLoad
            });

            this.GetDataSession("IcreapSessionData");

        }
    };

    componentDidMount() {
        const DataSession = GetDataSession(`IcreapSessionData`);
        if (!DataSession) {
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        } else {
            this.GetDataSession("IcreapSessionData");
        }
    }

    handleSubcategories = (props) => {

        if (this.state.tmp.Cod_subcategory == "" && props[0] == "A") {
            toast.error("Debes seleccionar una Sub Categoría para poder adicionar la actividad a tu perfil.");
            return;
        }

        //console.log(props);

        if (props[0] == "A") { //Adiciona subcategoria
            let TMP_NomSubcategory = this.state.tmp.Name_subcategory;
            let Tam_Subcat = this.state.form.deta.sub_categories.length;
            let Data_subcat = this.state.form.deta.sub_categories;
            //console.log(Tam_Subcat);

            if (Tam_Subcat != 0) {

                //Se valida que el dato a agregar no se encuentre ya definido
                for (let _i = 0; _i < Tam_Subcat; _i++) {
                    if (Data_subcat[_i].cod_subcat == this.state.tmp.Cod_subcategory) {
                        toast.error("No puedes agregar dos veces la misma actividad. " + Data_subcat[_i].name_subcat);
                        return;
                    }
                }
                /////////////////////////////////////////

                let Data_AddSubcat = { 'cod_subcat': this.state.tmp.Cod_subcategory, 'name_subcat': this.state.tmp.Name_subcategory };
                Data_subcat.push(Data_AddSubcat);

                this.setState({
                    tmp: {
                        ...this.state.tmp,
                        Cod_subcategory: "",
                        Name_subcategory: ""
                    },
                    form: {
                        ...this.state.form,
                        deta: {
                            ...this.state.form.deta,
                            sub_categories: Data_subcat
                        }
                    }
                })

                toast.success("Se agrego correctamente la actividad. " + TMP_NomSubcategory + ". ");
                return;
            } else {
                let Data_AddSubcat = [{ 'cod_subcat': this.state.tmp.Cod_subcategory, 'name_subcat': this.state.tmp.Name_subcategory }];
                //console.log(Data_AddSubcat);
                this.setState({
                    tmp: {
                        ...this.state.tmp,
                        Cod_subcategory: "",
                        Name_subcategory: ""
                    },
                    form: {
                        ...this.state.form,
                        deta: {
                            ...this.state.form.deta,
                            sub_categories: Data_AddSubcat
                        }
                    }
                })

                toast.success("Se agrego correctamente la actividad. " + TMP_NomSubcategory + ". ");
                return;

            }
        } else if (props[0] == "D") {
            let Tam_Subcat = this.state.form.deta.sub_categories.length;
            let Data_subcat = this.state.form.deta.sub_categories;
            let Data_newsubcat = [];
            let Name_delete = "";

            //Se valida que dato se eliminara y no se agrega al array
            for (let _i = 0; _i < Tam_Subcat; _i++) {
                if (Data_subcat[_i].cod_subcat != props[1]) {
                    Data_newsubcat.push(Data_subcat[_i]);
                } else {
                    Name_delete = Data_subcat[_i].name_subcat
                }
            }

            if (window.confirm('¿Esta seguro de eliminar la actividad ' + Name_delete + '?')) {
                this.setState({
                    form: {
                        ...this.state.form,
                        deta: {
                            ...this.state.form.deta,
                            sub_categories: Data_newsubcat
                        }
                    }
                })

                toast.success("Se elimino correctamente la actividad. " + Name_delete + ". ");
                return;
            }
            /////////////////////////////////////////

        }

    }


    RouteChangeToast = (props) => {

        ConsoleData("RouteChangeToast");
        ConsoleData(props);

        switch (props) {
            case "P": //Pendientes
                this.props.history.push(`${process.env.PUBLIC_URL}/bidding/bidding_made`);
                break;
            case "A": //Aceptadas
                this.props.history.push(`${process.env.PUBLIC_URL}/bidding/bidding_apply`);
                break;
        }
    }

    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })

    }

    handleChangeFile = e => {

        ConsoleData(e.target.files[0]);
        ConsoleData(e.target.name);

        ///Se valida el tamaño máximo de los archivos
        if (e.target.files[0].size > this.state.Max_size) {

            let Msg_toast = "El archivo no puede superar el tamaño de " + (this.state.Max_size / 1000000) + " MB";

            if (e.target.name == "file_cc") {

                this.setState({
                    file_cc: null,
                    error: true,
                    Msg_error: "El archivo no puede superar el tamaño de " + (this.state.Max_size / 1000000) + " MB"
                });

            } else if (e.target.name == "file_rut") {

                this.setState({
                    file_rut: null,
                    error: true,
                    Msg_error: "El archivo no puede superar el tamaño de " + (this.state.Max_size / 1000000) + " MB"
                });

            } else {
                Msg_toast = "Se esta subiendo un archivo erroneo.";
            }

            toast.error(Msg_toast);

        } else {

            if (e.target.name == "file_cc") {

                this.setState({
                    file_cc: e.target.files[0],
                    error: true,
                    Msg_error: "El archivo no puede superar el tamaño de " + (this.state.Max_size / 1000000) + " MB"
                });

            } else if (e.target.name == "file_rut") {

                this.setState({
                    file_rut: e.target.files[0],
                    error: true,
                    Msg_error: "El archivo no puede superar el tamaño de " + (this.state.Max_size / 1000000) + " MB"
                });

            } else {
                toast.error("Se esta subiendo un archivo erroneo.");
            }

            /*
                this.setState({
                    file : e.target.files[0],                 
                    loading: false,                
                    error: false,
                    Msg_error: ""                
                });
            */
        }

    }

    handleContactTMP = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            tmp_contacts: {
                ...this.state.tmp_contacts,
                [e.target.name]: e.target.value
            }
        })
    }

    handleContacts = (props) => {

        //console.log(props);

        if (props[0] == "A") { //Adiciona subcategoria

            if (this.state.tmp_contacts.Name_contact == "" || this.state.tmp_contacts.Phone_contact == "" || this.state.tmp_contacts.Email_contact == "") {
                toast.error("Debes llenar correctamente los campos para poder adicionar un contacto.");
                return;
            }

            let TMP_Name_contact = this.state.tmp_contacts.Name_contact;
            let TMP_Phone_contact = this.state.tmp_contacts.Phone_contact;
            let TMP_Email_contact = this.state.tmp_contacts.Email_contact;

            ///Se hacen validaciones mínimas para los datos
            if (TMP_Name_contact.length < 4) {
                toast.error("Debes ingresar un nombre correcto.");
                return;
            }

            if (TMP_Phone_contact.length < 7) {
                toast.error("Debes ingresar un telefono correcto.");
                return;
            }

            if (TMP_Email_contact.length < 4) {
                toast.error("Debes ingresar un email correcto.");
                return;
            } else {
                if (TMP_Email_contact.indexOf("@") < 0 || TMP_Email_contact.indexOf(".") < 0) {
                    toast.error("Debes ingresar un email correcto.");
                    return;
                }
            }

            ///////////////////////////////////

            let Tam_contacts = this.state.form.deta.contacts.length;
            let Data_contacts = this.state.form.deta.contacts;
            //console.log(Tam_contacts);

            if (Tam_contacts != 0) {

                //Se valida que el dato a agregar no se encuentre ya definido
                for (let _i = 0; _i < Tam_contacts; _i++) {
                    if (Data_contacts[_i].name_contact == TMP_Name_contact && Data_contacts[_i].email_contact == TMP_Email_contact && Data_contacts[_i].phone_contact == TMP_Phone_contact) {
                        toast.error("No puedes agregar dos veces el mismo contacto. " + Data_contacts[_i].name_contact);
                        return;
                    }
                }
                /////////////////////////////////////////

                let Data_Addcontacts = { 'name_contact': TMP_Name_contact, 'email_contact': TMP_Email_contact, 'phone_contact': TMP_Phone_contact };
                Data_contacts.push(Data_Addcontacts);

                this.setState({
                    tmp_contacts: {
                        Name_contact: "",
                        Phone_contact: "",
                        Email_contact: ""
                    },
                    form: {
                        ...this.state.form,
                        deta: {
                            ...this.state.form.deta,
                            contacts: Data_contacts
                        }
                    }
                })

                toast.success("Se agrego correctamente el contacto. " + TMP_Name_contact + ". ");
                return;
            } else {

                let Data_Addcontacts = [{ 'name_contact': TMP_Name_contact, 'email_contact': TMP_Email_contact, 'phone_contact': TMP_Phone_contact }];
                //console.log(Data_Addcontacts);
                this.setState({
                    tmp_contacts: {
                        Name_contact: "",
                        Phone_contact: "",
                        Email_contact: ""
                    },
                    form: {
                        ...this.state.form,
                        deta: {
                            ...this.state.form.deta,
                            contacts: Data_Addcontacts
                        }
                    }
                })

                toast.success("Se agrego correctamente el contacto. " + TMP_Name_contact + ". ");
                return;

            }
        } else if (props[0] == "D") {
            let Tam_contacts = this.state.form.deta.contacts.length;
            let Data_contacts = this.state.form.deta.contacts;
            let Data_newcontacts = [];
            let Name_delete = "";

            //Se valida que dato se eliminara y no se agrega al array
            for (let _i = 0; _i < Tam_contacts; _i++) {
                if (_i != props[1]) {
                    Data_newcontacts.push(Data_contacts[_i]);
                } else {
                    Name_delete = Data_contacts[_i].name_contact + " " + Data_contacts[_i].phone_contact;
                }
            }

            if (window.confirm('¿Esta seguro de eliminar el contacto ' + Name_delete + '?')) {
                this.setState({
                    form: {
                        ...this.state.form,
                        deta: {
                            ...this.state.form.deta,
                            contacts: Data_newcontacts
                        }
                    }
                })

                toast.success("Se elimino correctamente el contacto. " + Name_delete + ". ");
                return;
            }
            /////////////////////////////////////////

        }

    }


    handlerAddSubcategory = e => {
        ConsoleData("handlerAddSubcategory");
        //console.log(e);

        this.setState({
            tmp: {
                Cod_subcategory: e[0],
                Name_subcategory: e[1]
            }
        });

        setTimeout(() => {
            this.handleSubcategories(["A", ""]);
        }, 1000);

    }

    /*
    handleChangeFile = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })        
    }
    */

    handleChangeDeta = e => {
        console.log('Here Miguel');
        this.setState({
            form: {
                ...this.state.form,
                deta: {
                    ...this.state.form.deta,
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    handleSubmit = async e => {
        ConsoleData("Submit")

        e.preventDefault()

        ///Validaciones adicionales
        if (this.state.form.Id_store.length < 7) {
            toast.error("El N.I.T no puede ser menor a 7 caracteres. ");
            return;
        }

        if (this.state.form.Name_store.length < 5) {
            toast.error("La razón social no puede ser menor a 5 caracteres.");
            return;
        }

        if (this.state.form.Desc_store.length < 15) {
            toast.error("La descripción debe ser mas extensa.");
            return;
        }

        if (this.state.form.deta.sub_categories.length == 0) {
            toast.error("No puedes guardar el perfil sin seleccionar ninguna actividad.");
            return;
        }

        if (this.state.form.deta.contacts.length == 0) {
            toast.error("No puedes guardar el perfil sin adicionar ningun contacto adicional.");
            return;
        }

        if (this.state.file_cc == null && this.state.file_rut == null && this.state.data_store.length === 0) {
            toast.error("Por favor, subir como mínimo el RUT para continuar con el proceso de verificación de datos. ¡Gracias!");
            return;
        }

        ////////////////////////////////

        try {

            //console.log(this.state.form);            

            const Urlquery = icreappconfig.url_controller + "AdminStores_v2.php";
            console.log(Urlquery);

            const formData = new FormData();
            formData.append("File_CC", this.state.file_cc);
            formData.append("File_RUT", this.state.file_rut);

            formData.append('Action', this.state.Action_form);
            formData.append('User_email', this.state.User_email);
            formData.append('User_type', this.state.User_type);
            formData.append('Form', JSON.stringify(this.state.form));

            /*
            const Params = {
                Action : this.state.Action_form,
                User_email : this.state.User_email,
                User_type : this.state.User_type,
                Form : this.state.form
            }
            console.log(Params);           
            */

            this.setState({
                loading: true,
                error: false,
                Msg_error: ""
            });

            //axios.post(Urlquery,Params)
            axios.post(Urlquery, formData)
                .then(res => {

                    ConsoleData(res.data);

                    if (res.data[0].Status) {
                        this.setState({
                            loading: false,
                            error: false
                        })

                        ///Se utiliza para cerrar el modal despues de registrar el cambio
                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                        let decoder = DecoderData({ Data: res.data[0].Msg, Type: "S" });

                        toast.success(decoder);
                        this.onCloseModal();
                        this.GetDataSession("IcreapSessionData");

                    } else {
                        //alert(res.data[0].Msg);
                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));                       
                        let decoder = DecoderData({ Data: res.data[0].Msg, Type: "S" });

                        toast.error(decoder);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: res.data[0].Msg
                        })
                    }

                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });

            ////this.props.history.push('/exercise')

        } catch (error) {
            this.setState({
                loading: false,
                error
            })
        }


    }

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key) {
        let SessionDataValue = sessionStorage.getItem(key);

        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            //this.GetDataSubCategories();
            this.GetDataStore([DataValue.data[0].Email, DataValue.data[0].Tipo])
        }
    }

    GetDataStore = (Data_Sesion) => {
        const Urlquery = icreappconfig.url_controller + "AdminStores.php";

        const Params = {
            Action: this.state.Action,
            User_email: Data_Sesion[0],
            User_type: Data_Sesion[1]
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_store: [],
            loading: true,
            error: true,
            Msg_load: ""
        })

        try {
            axios.post(Urlquery, Params)
                .then(res => {

                    if (res.data[0].Status) {

                        let decoder = DecoderData({ Data: res.data[0].Msg, Type: "S" });

                        let Man_dataStore = [];
                        if (res.data[0].Data_store.length != 0) {
                            Man_dataStore = res.data[0].Data_store[0];
                        }

                        let Data_form = {
                            deta: {
                                sub_categories: [],
                                contacts: [],
                                Empleados: "",
                                Experience: "",
                                FacElect: "",
                                Tel_store: "",
                                Address_store: "",
                                Lat_store: "",
                                Long_store: "",
                                Camara_comercio: "",
                                Rut: ""
                            },
                            Id_store: "",
                            Name_store: "",
                            Desc_store: ""
                        };

                        if (Man_dataStore.length != 0) {
                            Data_form = res.data[0].Data_store[0]
                        }

                        this.setState({
                            data_store: Man_dataStore,
                            form: Data_form,
                            data_groups: [],
                            Msg_load: decoder,
                            loading: false,
                            error: false
                        })

                    } else {
                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                        let decoder = res.data[0].Msg;
                        decoder = decoder.replace("´", "'");
                        decoder = decodeURIComponent(escape(decoder));

                        this.setState({
                            data_store: res.data[0].Data_store,
                            loading: false,
                            Msg_load: decoder,
                            error: true
                        })

                    }

                })
                .catch(err => {
                    console.error(err);
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            })
        }

    }

    /*
    LoadOptionSelect(props){
        
        //console.log("LoadOptionStatus")
        //console.log(props)

        return props.map(function (CurrentData,i){
            return (
                <option value={CurrentData.cod} key={i}> {CurrentData.name} </option>                
            ) 
        })

    }
    */

    render() {
        const { open, open_mfile } = this.state;

        ///Manejo loading modal
        let LoadModal_Mfile;
        let LoadModal;
        let LoadCategories = <div></div>;
        let LoadContacts = <div></div>;
        if (this.state.loading) {
            LoadModal = <Loading />;
        } else {

            /////Manejo de contactos MODAL
            if (this.state.form.deta.contacts.length > 0) {
                LoadContacts = <div style={{ margin: 20 }} >
                    <table className="table table-striped" >
                        <thead>
                            <tr>
                                <th colSpan={4} className="align-self-center text-center"  >Contactos adicionados</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                //let Data_AddSubcat = { 'cod_subcat' : this.state.tmp.Cod_subcategory, 'name_subcat' : this.state.tmp.Name_subcategory };                                                                                        
                                this.state.form.deta.contacts.map((CurrentService, i) => {
                                    return (
                                        <tr key={i} >
                                            <td >{CurrentService.name_contact} </td>
                                            <td >{CurrentService.phone_contact}</td>
                                            <td >{CurrentService.email_contact}</td>
                                            <td >
                                                <i className="fa fa-trash" style={{ width: 35, fontSize: 20, color: '#e4566e' }} onClick={() => this.handleContacts(["D", i])} ></i>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }


            /////Manejo de actividades MODAL
            if (this.state.form.deta.sub_categories.length > 0) {
                LoadCategories = <div style={{ margin: 20 }} >
                    <table className="table table-striped" >
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Nombre</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                //let Data_AddSubcat = { 'cod_subcat' : this.state.tmp.Cod_subcategory, 'name_subcat' : this.state.tmp.Name_subcategory };                                                                                        
                                this.state.form.deta.sub_categories.map((CurrentService, i) => {
                                    return (
                                        <tr key={i} >
                                            <td >{CurrentService.cod_subcat} </td>
                                            <td >{CurrentService.name_subcat}</td>
                                            <td >
                                                <i className="fa fa-trash" style={{ width: 35, fontSize: 20, color: '#e4566e' }} onClick={() => this.handleSubcategories(["D", CurrentService.cod_subcat])} >
                                                </i>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>;
            }

            if (this.state.Type_file == "C") {
                LoadModal_Mfile = <File_upload
                    name_file="Store_Catalog"
                    max_size={icreappconfig.max_size_catalogs}
                    close_modal={this.onCloseModalFile}
                    MsgAuth="" />
            } else if (this.state.Type_file == "L") {
                LoadModal_Mfile = <File_upload
                    name_file="Store_Logo"
                    max_size={icreappconfig.max_size_logos}
                    close_modal={this.onCloseModalFile}
                    MsgAuth="Permite el uso del logo en Mosquerapp." />
            }


            //console.log("Data_Store");
            //console.log(this.state.data_store.length == 0);

            LoadModal = <form onSubmit={this.handleSubmit}>
                <div className="modal-body">

                    <div className="row">
                        <div className="col-xl-12 col-md-12 align-self-center text-center" >
                            <h4>Datos Iniciales</h4>
                        </div>

                        <div className="col-xl-3 col-md-3">
                            <label htmlFor="recipient-name" className="col-form-label" >N.I.T</label>
                            <input type="text" disabled={!(this.state.data_store.length == 0)} className="form-control" name="Id_store" value={this.state.form.Id_store} onChange={this.handleChange} required />
                        </div>

                        <div className="col-xl-6 col-md-6">
                            <label htmlFor="recipient-name" className="col-form-label" >Nombre o razón social</label>
                            <input type="text" disabled={!(this.state.data_store.length == 0)} className="form-control" name="Name_store" value={this.state.form.Name_store} onChange={this.handleChange} required />
                        </div>

                        <div className="col-xl-3 col-md-3">
                            <label htmlFor="recipient-name" className="col-form-label" >Telefono</label>
                            <input type="tel" className="form-control" name="Tel_store" id="Tel_store" value={this.state.form.deta.Tel_store} onChange={this.handleChangeDeta} required />
                        </div>

                        <div className="col-xl-6 col-md-6">
                            <label htmlFor="recipient-name" className="col-form-label" >Dirección</label>
                            <input type="text" className="form-control" name="Address_store" value={this.state.form.deta.Address_store} onChange={this.handleChangeDeta} required />
                        </div>

                        <div className="col-xl-3 col-md-3">
                            <label htmlFor="recipient-name" className="col-form-label" >Latitud</label>
                            <input type="number" className="form-control" name="Lat_store" value={this.state.form.deta.Lat_store} onChange={this.handleChangeDeta} />
                        </div>

                        <div className="col-xl-3 col-md-3">
                            <label htmlFor="recipient-name" className="col-form-label" >Longitud</label>
                            <input type="number" className="form-control" name="Long_store" value={this.state.form.deta.Long_store} onChange={this.handleChangeDeta} />
                        </div>

                        <div className="col-xl-12 col-md-12">
                            <label htmlFor="recipient-name" className="col-form-label" >Descripción productos y/o servicios ofertados</label>
                            <textarea className="form-control" name="Desc_store" rows="3" value={this.state.form.Desc_store} onChange={this.handleChange} ></textarea>
                        </div>


                        <div style={{ marginTop: 20 }} className="col-xl-12 col-md-12 align-self-center text-center" >
                            <h4>Archivos de verificacion</h4>
                        </div>

                        <div className="col-xl-6 col-md-6">
                            <label htmlFor="recipient-name" className="col-form-label" >Subir Camara de Comercio</label>
                            <input type="file" className="form-control" onChange={this.handleChangeFile} name="file_cc" />
                            {this.state.form.deta.Camara_comercio && (
                                <a href={this.state.form.deta.Camara_comercio} target="_blank" rel="noopener noreferrer">Ver archivo actual</a>
                            )}
                        </div>

                        <div className="col-xl-6 col-md-6">
                            <label htmlFor="recipient-name" className="col-form-label" >Subir R.U.T</label>
                            <input type="file" className="form-control" onChange={this.handleChangeFile} name="file_rut" />
                            {this.state.form.deta.Rut && (
                                <a href={this.state.form.deta.Rut} target="_blank" rel="noopener noreferrer">Ver archivo actual</a>
                            )}
                        </div>

                        <div style={{ marginTop: 20 }} className="col-xl-12 col-md-12 align-self-center text-center" >
                            <h4>Datos detallados y selección de actividades de trabajo</h4>
                        </div>

                        <div className="col-xl-4 col-md4">
                            <label htmlFor="recipient-name" className="col-form-label" >Maneja Facturación electrónica</label>
                            <select className="custom-select " value={this.state.form.deta.FacElect} onChange={this.handleChangeDeta} name="FacElect" required >
                                <option value="">Seleccione una opción</option>
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>

                        </div>

                        <div className="col-xl-4 col-md4">
                            <label htmlFor="recipient-name" className="col-form-label" >Numero de empleados</label>
                            <input type="number" className="form-control" name="Empleados" min="0" value={this.state.form.deta.Empleados} onChange={this.handleChangeDeta} required />
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <label htmlFor="recipient-name" className="col-form-label" >Años de experiencia</label>
                            <input type="number" className="form-control" name="Experience" min="0" value={this.state.form.deta.Experience} onChange={this.handleChangeDeta} required />
                        </div>

                        <div className="col-xl-12 col-md-12" style={{ marginTop: 10 }}>
                            <h5>
                                <label htmlFor="recipient-name" className="col-form-label" >Contactos Adicionales</label>
                            </h5>
                        </div>

                        <div className="col-xl-4 col-md-4">
                            <label htmlFor="recipient-name" className="col-form-label" >Nombre</label>
                            <input type="text" className="form-control" name="Name_contact" min="0" value={this.state.tmp_contacts.Name_contact} onChange={this.handleContactTMP} />
                        </div>

                        <div className="col-xl-4 col-md-4">
                            <label htmlFor="recipient-name" className="col-form-label" >Telefono</label>
                            <input type="number" className="form-control" name="Phone_contact" min="0" value={this.state.tmp_contacts.Phone_contact} onChange={this.handleContactTMP} />
                        </div>

                        <div className="col-xl-4 col-md-4">

                            <label htmlFor="recipient-name" className="col-form-label" >Email</label>
                            <input type="email" className="form-control" name="Email_contact" min="0" value={this.state.tmp_contacts.Email_contact} onChange={this.handleContactTMP} />

                        </div>

                        <div className="col-xl-12 col-md-12">
                            {LoadContacts}
                        </div>

                        <div style={{ marginTop: 10 }} className="col-xl-12 col-md-12">
                            <button className="btn btn-warning" type="button" onClick={() => this.handleContacts(["A", ""])} >
                                <span className="fa fa-plus"></span> Adicionar contacto
                            </button>
                        </div>

                        <div style={{ marginTop: 20 }} className="col-xl-12 col-md-12 align-self-center text-center" >
                            <Activity_finder
                                HandlerAdd={this.handlerAddSubcategory} />
                        </div>

                        <div className="col-xl-12 col-md-12">
                            {LoadCategories}
                        </div>

                    </div>

                </div>
                <div className="modal-footer">
                    <button type="submit" className="btn btn-primary" >{((this.state.data_store.length == 0) ? 'Registrar' : 'Guardar Cambios')}</button>
                    <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal()}>Cerrar</button>
                </div>
            </form>;
        }


        let Msg_boton = "";
        let Man_Catalog = "";
        let Man_logo = "";

        if (this.state.data_store.length == 0) {
            Msg_boton = "Configurar datos de perfil";
        } else {
            Msg_boton = "Modificar datos de perfil";
            if (this.state.data_store.deta.Catalogue.length > 0) {
                Man_Catalog = <>
                    <div style={{ marginTop: 10 }} className="col-xl-6 col-md-6" >
                        <h4>
                            <a href={icreappconfig.url_platform + this.state.data_store.deta.Catalogue} target="_blank">Ver Catalogo</a>
                        </h4>
                    </div>
                    <div style={{ marginTop: 20 }} className="col-xl-12 col-md-12 align-self-center text-center" >
                        <button type="button" className="btn btn-warning btn-xs" data-toggle="modal" onClick={() => { this.onOpenModalFile("C") }} >Actualizar Catalogo</button>
                    </div>
                </>;
            } else {
                Man_Catalog = <div style={{ marginTop: 20 }} className="col-xl-12 col-md-12 align-self-center text-center" >
                    <button type="button" className="btn btn-warning btn-xs" data-toggle="modal" onClick={() => { this.onOpenModalFile("C") }} >Subir Catalogo</button>
                </div>;
            }

            if (this.state.data_store.deta.Logo.length > 0) {

                Man_logo = <div className="container">
                    <img style={{ width: '100%', maxWidth: 300, maxHeight: 300 }} src={icreappconfig.url_platform + this.state.data_store.deta.Logo} alt="Sin formato" />
                    <span onClick={() => { this.onOpenModalFile("L") }}>
                        <i className="fa fa-pencil text-info" style={{ width: 35, fontSize: 20, padding: 11 }}></i>
                    </span>
                </div>;
            } else {
                Man_logo = <div className="container">
                    <button type="button" className="btn btn-info btn-xs" data-toggle="modal" onClick={() => { this.onOpenModalFile("L") }} >Subir Logo</button>
                </div>;
            }

            ///Se agrega alerta de postulaciones pendientes y aceptadas
            const customIdBP = "bidding_p";
            if (this.state.data_store.CountPost != undefined && this.state.data_store.CountPost > 0) {
                if (this.state.data_store.CountPost > 1) {
                    toast.info("Tienes " + this.state.data_store.CountPost + " postulaciones en estado pendiente. Revisa tus requerimientos activos.", {
                        autoClose: 15000,
                        toastId: customIdBP,
                        onClick: () => this.RouteChangeToast("P")
                    });
                } else {
                    toast.info("Tienes " + this.state.data_store.CountPost + " postulación en estado pendiente. Revisa tus requerimientos activos.", {
                        autoClose: 15000,
                        toastId: customIdBP,
                        onClick: () => this.RouteChangeToast("P")
                    });
                }
            }

            const customIdBA = "bidding_a";
            if (this.state.data_store.CountPostAcept != undefined && this.state.data_store.CountPostAcept > 0) {
                if (this.state.data_store.CountPostAcept > 1) {
                    toast.success("Tienes " + this.state.data_store.CountPostAcept + " postulaciones aceptadas. Revisa tus Ofertas Aplicadas.", {
                        autoClose: 12000,
                        toastId: customIdBA,
                        onClick: () => this.RouteChangeToast("A")
                    });
                } else {
                    toast.success("Tienes " + this.state.data_store.CountPostAcept + " postulación aceptada. Revisa tus Ofertas Aplicadas.", {
                        autoClose: 12000,
                        toastId: customIdBA,
                        onClick: () => this.RouteChangeToast("A")
                    });
                }
            }
            ///////////////////////////////////////
        }

        ///Se carga el body con los datos traidos 
        let LoadBody = "";
        let LoadMaps = "";
        if (this.state.data_store.length == 0) {
            LoadBody = <div><h3>{this.state.Msg_load}</h3></div>;
        } else if (this.state.data_store.Status_store == "V") {
            LoadBody = <div><h3>{this.state.Msg_load}</h3></div>;
        } else {

            if (this.state.data_store.deta.Lat_store.length > 0 && this.state.data_store.deta.Long_store.length > 0) {
                LoadMaps = <div className="col-xl-12 col-md-12 align-self-center text-center" >
                    <iframe src={"http://maps.google.com/maps?q=" + this.state.data_store.deta.Lat_store + "," + this.state.data_store.deta.Long_store + "&z=17&output=embed"} width={600} height={450} style={{ border: 0 }} allowFullScreen="" >  </iframe>
                </div>;
            }

            LoadBody = <div style={{ margin: 20 }}>
                <div className="row">
                    <div className="col-xl-12 col-md-12 align-self-center text-center" style={{ marginBottom: 20 }} >
                        <h3>Datos del perfil</h3>
                    </div>

                    <div className="col-xl-12 col-md-12 align-self-center text-center" style={{ marginBottom: 20 }} >
                        {Man_logo}
                    </div>

                    <div className="col-xl-3 col-md-3">
                        <label htmlFor="recipient-name" className="col-form-label" > <strong>N.I.T:</strong> {this.state.data_store.Id_store} </label>
                    </div>

                    <div className="col-xl-6 col-md-6">
                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Nombre o razón social:</strong> {this.state.data_store.Name_store} </label>
                    </div>

                    <div className="col-xl-3 col-md-3">
                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Telefono:</strong> {this.state.data_store.deta.Tel_store} </label>
                    </div>

                    <div className="col-xl-12 col-md-12">
                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Dirección:</strong> {this.state.data_store.deta.Address_store} </label>
                    </div>

                    {/*LoadMaps*/}

                    <div className="col-xl-12 col-md-12">
                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Descripción:</strong> {this.state.data_store.Desc_store} </label>
                    </div>

                    <div className="col-xl-4 col-md-4">
                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Maneja Facturación electrónica:</strong> {((this.state.data_store.deta.FacElect == 1) ? "Si" : "No")} </label>
                    </div>

                    <div className="col-xl-4 col-md-4">
                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Numero de empleados:</strong> {this.state.data_store.deta.Empleados} </label>
                    </div>

                    <div className="col-xl-4 col-md-4">
                        <label htmlFor="recipient-name" className="col-form-label" > <strong>Años de experiencia:</strong> {this.state.data_store.deta.Experience} </label>
                    </div>

                    {Man_Catalog}


                    <div style={{ marginTop: 20 }} className="col-xl-12 col-md-12 align-self-center text-center" >
                        <h5>Archivos de verificación</h5>

                        <table className="table " >
                            <thead></thead>
                            <tbody>
                                <tr><td>Cámara de comercio: </td><td>{this.state.data_store.deta.Camara_comercio.length <= 0 ? "No se agrego ningun archivo" : <a href={icreappconfig.url_platform + this.state.data_store.deta.Camara_comercio} target="_blank">Ver Archivo</a>}</td></tr>
                                <tr><td>R.U.T: </td><td>{this.state.data_store.deta.Rut.length <= 0 ? "No se agrego ningun archivo" : <a href={icreappconfig.url_platform + this.state.data_store.deta.Rut} target="_blank">Ver Archivo</a>}</td></tr>
                            </tbody>
                        </table>
                    </div>



                    <div style={{ marginTop: 20 }} className="col-xl-12 col-md-12 align-self-center text-center" >
                        <h5>Contactos adicionales</h5>
                    </div>

                    <div className="col-xl-12 col-md-12">

                        <table className="table " >
                            <thead>
                            </thead>
                            <tbody>
                                {
                                    //let Data_AddSubcat = { 'cod_subcat' : this.state.tmp.Cod_subcategory, 'name_subcat' : this.state.tmp.Name_subcategory };
                                    this.state.data_store.deta.contacts.map((CurrentService, i) => {
                                        return (
                                            <tr key={i} >
                                                <td >{CurrentService.name_contact} </td>
                                                <td >{CurrentService.phone_contact}</td>
                                                <td >{CurrentService.email_contact}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div>

                    <div style={{ marginTop: 20 }} className="col-xl-12 col-md-12 align-self-center text-center" >
                        <h5>Actividades de trabajo</h5>
                    </div>

                    <div className="col-xl-12 col-md-12">

                        <table className="table table-striped" >
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>C.I.I.U</th>
                                    <th>Nombre</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    //let Data_AddSubcat = { 'cod_subcat' : this.state.tmp.Cod_subcategory, 'name_subcat' : this.state.tmp.Name_subcategory };
                                    this.state.data_store.deta.sub_categories.map((CurrentService, i) => {
                                        return (
                                            <tr key={i} >
                                                <td >{CurrentService.cod_subcat} </td>
                                                <td >{CurrentService.mcod_subcategory} </td>
                                                <td >{CurrentService.name_subcat}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>;
        }

        return (
            <Fragment>
                <Breadcrumb title="Datos del Perfil" parent="Perfil" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-popup pull-right">

                                        <button type="button" disabled={this.state.error} className="btn btn-primary" onClick={this.onOpenModal} data-toggle="modal" data-original-title="test" data-target="#exampleModal">{Msg_boton}</button>

                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{Msg_boton}</h5>
                                            </div>

                                            {LoadModal}
                                        </Modal>

                                    </div>

                                    <div className="btn-popup pull-right">

                                        <Modal open={open_mfile} onClose={this.onCloseModalFile} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{this.state.Type_file == "C" ? "Catalogo" : "Logo"}</h5>
                                            </div>

                                            {LoadModal_Mfile}
                                        </Modal>

                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" >
                                        {/*Datos a mostrar del detalle*/}
                                        {LoadBody}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }
}

export default Data_Store


import React, { Component,Fragment } from 'react';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import ConsoleData from '../../hooks/ConsoleData'

const formatterPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
})

export class DetaServices extends Component {                   

    constructor(props){
        super(props)

        //console.log("DetaServices");
        //console.log(props.DataService);

        this.GetDetaServices = this.GetDetaServices.bind(this);

        this.state = {
            Cod_Agenda: props.DataService.cod_agenda,
            Data: [],
            Action: "D",
            loading: true,
            error: false,
            Msg_Error: ''
        }        
        
    }

    componentDidMount(){
        this.GetDetaServices();
    }

    GetDetaServices = () => {
        const Urlquery = icreappconfig.url_controller+"LoadStatusServices.php";
        //console.log(Urlquery);    
        ConsoleData(Urlquery);    

        const Params = {
            Action: this.state.Action,
            Cod_agenda: this.state.Cod_Agenda            
        }

        //console.log(Params);
        ConsoleData(Params); 

        axios.post(Urlquery,Params)
             .then(res => {
                //console.log(res.data);   
                ConsoleData(res.data);
                
                if(res.data[0].Status){
                    this.setState({
                        Data: res.data[0].Data,
                        loading: false,
                        error: false                        
                    })                    
                }else{                    
                }
                
             })
             .catch(err => {
                    console.error(err);
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
            });
    }

    render(){

        if(this.state.loading)
            return <Loading msg="Cargando..." />
        
        return(
            <div className="align-self-center text-center">
                
                <table className="table table-sm table-hover table-bordered" style={ {marginTop:20,paddingLeft:20,paddingRight:20}  } >
                    <thead>
                        <tr>
                            <th>Nombre Servicio</th>
                            <th>Cantidad</th>
                            <th>Costo</th>                            
                            <th>Akualiver</th>                            
                            <th>Estado Akualiver</th>                            
                        </tr>
                    </thead>
                    <tbody>  
                        {
                            this.state.Data.map( (CurrentDetaService,i) => {                            
                                return (
                                    <tr key={i} >
                                        <td >{CurrentDetaService.name_service}</td>
                                        <td >{CurrentDetaService.units}</td>
                                        <td >{formatterPeso.format(CurrentDetaService.service_cost)}</td>
                                        <td >{CurrentDetaService.alias_delivery+" - "+CurrentDetaService.name_delivery}</td>
                                        <td >{CurrentDetaService.name_status_delivery}</td>                                        
                                    </tr>
                                )
                                
                            })


                        }                    
                    </tbody>
                </table>                                  
            </div>
        )

    }

}

export default DetaServices


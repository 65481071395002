import React, { useRef } from 'react';
import styled from 'styled-components';

import EmailEditor from 'react-email-editor';
import sample from './sample.json';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: #61dafb;
  color: #000;
  padding: 10px;
  display: flex;
  max-height: 40px;
  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
  }
  button {
    flex: 1;
    
    margin-left: 5px;
    
    font-size: 12px;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    border: 0px;
    max-width: 150px;
    cursor: pointer;
  }
`;

const EmailEditorTemplate = (props) => {
  const emailEditorRef = useRef(null);
  let IdTemplate = props.IdTemplate;
  let DataTemplate = props.DataTemplate;

  const RegisterTemplateData = props.RegisterTemplateData;

  const saveDesign = () => {
    
    let Design_Template = "";
    let Design_HTML = "";
    
    /*
    emailEditorRef.current.editor.saveDesign((design) => {      
      console.log('saveDesign', design);
      alert('Design JSON has been logged in your developer console.');
    });
    */

    emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        Design_Template = design;    
        Design_HTML = html;
        console.log('saveDesign 2', design);
        RegisterTemplateData(Design_Template,Design_HTML);
        //console.log('exportHtml', html);
        //alert('Output HTML has been logged in your developer console.');
    });      

  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log('exportHtml', html);
      alert('Output HTML has been logged in your developer console.');
    });
  };

  const onDesignLoad = (data) => {
    console.log('onDesignLoad', data);
  };

  const onLoad = () => {
    console.log('onLoad');

    emailEditorRef.current.editor.addEventListener(
      'design:loaded',
      onDesignLoad
    );
    
    if(DataTemplate != null){
      emailEditorRef.current.editor.loadDesign(DataTemplate);      
    }else{
      emailEditorRef.current.editor.loadDesign(sample);
    }
    
  }

  const onReady = () => {
    console.log('onReady');

    console.log("IdTemplate");
    console.log(IdTemplate);

  };

  return (
    <Container>
      <Bar>
        <h1>Generador de Plantillas Email</h1>

        <button onClick={saveDesign}>Guardar Diseño</button>                        
      </Bar>

      <React.StrictMode>
        <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
      </React.StrictMode>
    </Container>
  );
};

export default EmailEditorTemplate;
import React, { Component } from 'react'
import man from '../../../assets/images/dashboard/man.png'

export class User_panel extends Component {



    render() {
        
        ///Se traen los datos de session
        let NameUser = "";
        let EmailUser = "";
        let SessionDataValue = sessionStorage.getItem('IcreapSessionData');
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);       
            
            //console.log(DataValue.data[0]);
            NameUser = DataValue.data[0].Name;
            EmailUser = DataValue.data[0].Email;
        }

        return (
            <div>
                <div className="sidebar-user text-center">                    
                    <div>                        
                        {/*
                            <img className="img-60 rounded-circle lazyloaded blur-up" src={man} alt="#" />
                        */}                        
                    </div>
                    <h6 className="mt-3 f-14">{NameUser}</h6>                    
                    <p>{EmailUser}</p>
                    {/* <p>general manager.</p> */} 
                </div>
            </div>
        )
    }
}

export default User_panel


import React, { Component, Fragment} from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal'
import Datatable from '../common/datatable'
import axios from 'axios'
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import Activity_finder from '../specific/activity_finder'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ConsoleData from '../../hooks/ConsoleData'

import ExcelDownload from '../specific/data_export_excel'

export class ListEmailTemplate extends Component {

    constructor(props) {
        super(props);        

        this.state = {
            Action: "C",
            open: false,
            data : [],
            data_template : [],    
            data_tmp_template : [],    
            Txt_search : "",        
            Msg_load: "",
            User_email: "",
            User_type: "",
            loading: true,            
            error: false            
        };
    }

    componentDidMount(){        
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataSubCategories();
            this.GetDataSession("IcreapSessionData");        
        }        
    }
    
    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            this.GetDataTemplate()
        }
    }

    handleChangeFilter = e => {
        ConsoleData(`${e.target.name}: ${e.target.value}`)
        //ConsoleData(this.state.data_filter_directory);

        ///Se vuelve a generar array de datos aplicando el filtro
        const TxtData_filter = e.target.value;
        let new_data = [];
        if(TxtData_filter==""){
            new_data = this.state.data_template;
        }else{
            this.state.data_template.forEach(function(dataitem) {
                const Description = dataitem.Description;                
                const Status = dataitem.status.props.children;
                
                let AddItem = false;

                //Se valida Description
                if(Description.includes(TxtData_filter) || Description.toLowerCase().includes(TxtData_filter.toLowerCase())   ){
                    AddItem=true;
                }
                

                //Se valida el status
                if(Status.includes(TxtData_filter) || Status.toLowerCase().includes(TxtData_filter.toLowerCase() ) ){
                    AddItem=true;
                }

                //Se agrega item si se encuentra resultado
                if(AddItem){
                    new_data.push(dataitem);     
                    //ConsoleData(Status);               
                }

              })
        }
                
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            [e.target.name]: e.target.value,
            change_table : true,
            data_tmp_template: new_data
        })
        

        //ConsoleData(new_data_directory);
                
    }
    
    GetDataTemplate = () => {
        const Urlquery = icreappconfig.url_controller+"AdminEmailsUsers.php";
        //console.log(Urlquery);   
        ConsoleData(Urlquery);            

        const Params = {
            Action: this.state.Action            
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_template: [],
            data_tmp_template : [],    
            loading: true            
        })

        //console.log(Params);
        ConsoleData(Params);       

        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    //console.log(res.data);   
                    ConsoleData(res.data);       
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        
                        let Man_dataTemplate = [];
                        if(res.data[0].Data.length != 0){
                            
                            res.data[0].Data.map( (CurrentService,i) => {                            
                            
                                //console.log(CurrentService);
                                ConsoleData(CurrentService);     
    
                                let Status_template;
                                if(CurrentService.Status == "A"){
                                    Status_template = <i className="fa fa-check-circle-o font-success f-12" > Activo </i>;                                                               
                                }else if (CurrentService.Status == "I") {
                                    Status_template = <i className="fa fa-ban font-danger f-12" > Inactivo </i>;                             
                                }else if (CurrentService.Status == "B") {
                                    Status_template = <i className="fa fa-ban font-danger f-12" > Bloqueado </i>;                             
                                }else if (CurrentService.Status == "E") {
                                    Status_template = <i className="fa fa-clock-o font-warning f-12">En espera</i>;                             
                                }else{
                                    Status_template = <i className="fa fa-circle font-danger f-12" > Prueba </i>;                             
                                }
    
                                Man_dataTemplate[i] = {                            
                                    id: parseInt(CurrentService.Id_template),                                    
                                    Descripcion: CurrentService.Description,                                                                                                                                        
                                    status: Status_template                                                        
                                }                                                
                                
                            })                            

                        }                        


                        this.setState({  
                            data: res.data[0].Data,                          
                            data_template : Man_dataTemplate, 
                            data_tmp_template : Man_dataTemplate,    
                            loading : false,                           
                            Msg_load: decoder,                            
                            error: false                        
                        })           
                                                
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            data: res.data[0].Data,
                            data_template : res.data[0].Data,
                            data_tmp_template : res.data[0].Data,
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }



    viewHandlerTemplate = (e) => {
        ConsoleData(e);
        ConsoleData(e.datos.id);
        
        this.viewCommerce(e.datos.id);
        
    }

    viewCommerce = (props) => {
        ConsoleData(props);

        /*
        sessionStorage.removeItem("Cod_store");      
        sessionStorage.setItem("Cod_store", props );

        this.props.history.push(`${process.env.PUBLIC_URL}/store/view_store`);
        */

    }



    render() {

        const { open } = this.state;
        let TitleModal = "Email Template";
        let BodyPage = "";     
        let handle_add = "";   
        let handler_modal = "";      
        
        if(this.state.loading){
            BodyPage = <Loading />;
        }else{
            if(this.state.data_template == []){
                handle_add = <h3>{this.state.Msg_load}</h3>;
            }else{
                //console.log(this.state.data_bidding.length)
                if(this.state.data_template.length>0){
                    handle_add = <></>;
                    BodyPage = <div>
                                    <div className="row">
                                        < ExcelDownload  Sheet = {this.state.data} 
                                            FileName = "listado_email_template" />
                                    </div>    

                                    <Datatable
                                        multiSelectOption={false}
                                        myData={this.state.data_tmp_template} 
                                        pageSize={10} 
                                        pagination={true}
                                        class="-striped -highlight"     
                                        viewHandler={this.viewHandlerTemplate}                                    
                                    />
                                </div>;    
                }else{
                    handle_add = <h3>{this.state.Msg_load}</h3>;
                    BodyPage = <div></div>;
                }   
                
                ///Se agrega cambio para garantizar la actualizacion de la tabla
                if(this.state.change_table){
                    BodyPage = <div></div>;
                    this.setState({
                        change_table : false,                                        
                    })
                }

            }

        }

        return (
            <Fragment>
                <Breadcrumb title="Templastes Creados" parent="Notificaciones" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">

                                    <div className="row" >
                                        <div className="col-xl-6 col-md-6 align-self-center text-center" >                                                                            
                                            <input type="text" className="form-control" name="Txt_search" placeholder="Ingresa tu búsqueda" value={this.state.Txt_search} onChange={this.handleChangeFilter}  />                                        
                                        </div>    
                                        <div className="col-xl-6 col-md-6 align-self-right text-right" >                                                                            
                                            <Link to="/notification/admin-email-template" >
                                                <button className="btn btn-primary" type="button" ><i className="fa fa-plus"></i>Crear Email Template</button>
                                            </Link>    
                                        </div>                                                                                                        
                                    </div>  


                                    <div className="btn-popup pull-right">
                                        {handle_add}
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{TitleModal}</h5>
                                            </div>   
                                            {handler_modal}                                         
                                        </Modal>

                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">  
                                        {BodyPage}                                                                                                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }


}

export default ListEmailTemplate
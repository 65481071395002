import React, { Component, Fragment} from 'react'
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import ConsoleData from '../../hooks/ConsoleData'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class FileUploadForm extends Component {

    UPLOAD_ENDPOINT = icreappconfig.url_controller+'upload_file.php';
    
    constructor(props) {
        super(props);

        //console.log(props);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.uploadFile = this.uploadFile.bind(this);     
        
        let ManAction = "";
        if(props.name_file=="Store_Catalog"){
            ManAction = "C";
        }else if(props.name_file=="Store_Logo"){
            ManAction = "I";
        }

        this.state ={
          file : null,
          Action_file : ManAction,
          name_file : props.name_file,
          Max_size : props.max_size*1000000, ///Se multiplica para dejar el valor en MB
          loading: false,
          Error : false,
          AuthLogo : "Si",
          MsgAuth : props.MsgAuth,
          Msg_error : "",
          User_email: "",
          User_type: ""
        }
    }

    componentDidMount(){
        this.GetDataSession("IcreapSessionData");     
    }

    async onSubmit(e){
        e.preventDefault(); 
        
        if(this.state.file==null){
            toast.error("debes selecionar un archivo, correctamente");
            return;
        }

        this.uploadFile(this.state.file);
        //console.log(res.data);
    }
    
    handleChangeCheckAuth = e => {
        ConsoleData("handleChangeCheckAuth");

        let Val_asign = "Si";
        ConsoleData(`${e.target.name}: ${e.target.value}`)
        const Val_form = this.state.AuthLogo;           
        
        if(Val_form === e.target.value){
            //Val_asign = "";            
            
            this.setState({
                [e.target.name]: ""            
            })

        }else{

            // Esto se utiliza para guardar los valores en los estados                
            // Metodo nuevo - computer propierti name
            this.setState({
                [e.target.name]: Val_asign         
            })

        }
        
    }


    onChange(e) {
        //console.log(e.target.files[0]);
        ConsoleData(e.target.files[0]);                     

        ///Se valida el tamaño máximo de los archivos
        if(e.target.files[0].size > this.state.Max_size ){
            this.setState({       
                file: null,
                loading: false,                
                Error: true,
                Msg_error: "El archivo no puede superar el tamaño de "+(this.state.Max_size/1000000)+" MB"
            }) 
        }else{
            this.setState({
                file : e.target.files[0],
                loading: false,                
                Error: false,
                Msg_error: ""                
            });
        }

        
    }


    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })                        
        }
    }

    async uploadFile(file){
        const formData = new FormData();    
        
        this.setState({                            
            loading: true,
            Error: false,
            Msg_error: ""                        
        });

        formData.append(this.state.name_file,file);
        formData.append('Action',this.state.Action_file);    
        formData.append('User_email',this.state.User_email);    
        formData.append('User_type',this.state.User_type);    
        formData.append('AuthLogo',this.state.AuthLogo);    
        
        
        ConsoleData("uploadFile");
        ConsoleData(this.UPLOAD_ENDPOINT);
        ConsoleData(this.state.AuthLogo);
        ConsoleData(this.state.Action_file);
        //console.log(formData);                   

        axios.post(this.UPLOAD_ENDPOINT, formData)
                .then(res => {
                    //console.log(res);   
                    ConsoleData(res);       
                    
                    if(res.data[0].Status){

                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 

                        toast.success(decoder);

                        this.setState({                            
                            loading: false,
                            Error: false                        
                        })    
                        
                        this.props.close_modal("A");
                        
                    }else{                    
                        //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 
                        
                        toast.error(decoder);

                        this.setState({                            
                            loading: false,
                            Error: true,
                            Msg_error: decoder
                        })                    

                    }

                }).catch(err => {
                    
                    
                    if(err != undefined){
                        console.error(err);                                                
                    }                     
                    
                });                                        
    }


    render() {

        let ManButtonError,ManLoading,MsgButton,ManAuthorization; 

        if(this.state.name_file=="Store_Catalog"){
            MsgButton = "Subir Catalogo";
            ManAuthorization = <></>;
        }else if(this.state.name_file=="Store_Logo"){
            MsgButton = "Subir Logo";
            
            ManAuthorization = <div className="form-check">
                                    <input className="form-check-input" 
                                        type="checkbox" 
                                        name="AuthLogo" 
                                        id="AuthLogo" 
                                        checked={this.state.AuthLogo=="Si"} 
                                        value = "Si"                                        
                                        onChange={this.handleChangeCheckAuth}/>

                                    <label className="form-check-label" htmlFor="AuthLogo">
                                        <h5>{this.state.MsgAuth}</h5>
                                    </label>
                                </div>;

        }else{
            MsgButton = "Subir Archivo";
        }

        if(this.state.Error){
            ManButtonError = <>                        
                                <button type="button" className="btn btn-secondary" onClick={() => this.props.close_modal("S") }>Cerrar</button>
                            </>;
        }else{            
            ManButtonError = <>
                                <button type="submit" className="btn btn-primary">{MsgButton}</button>
                                <button type="button" className="btn btn-secondary" onClick={() => this.props.close_modal("S") } >Cerrar</button>
                            </> ;
        }

        if(this.state.loading){
            ManLoading = <Loading />;
        }else{
            ManLoading = <input type="file" onChange={ this.onChange } required/>;
        }

        return (

            <form onSubmit={ this.onSubmit }>
                <div className="modal-body">     
                    <div className="row">      
                        <div className="col-xl-12 col-md-12 align-self-center text-center" >
                            {ManLoading}
                        </div>

                        <div className="col-xl-12 col-md-12 align-self-center text-center" style={{margin:20}} >
                            {ManAuthorization}
                        </div>   

                        <div className="col-xl-12 col-md-12 align-self-center text-center" >
                            <p className="text-danger" style={{margin:20}}>{this.state.Msg_error}</p>
                        </div>                                          
                    </div>                                                          
                </div> 
                <div className="modal-footer">
                    {ManButtonError}                    
                </div>                   
                
            </form>
       )
    }


}
export default FileUploadForm;
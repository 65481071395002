import React, { Component } from 'react';

// imgaes import
import user from '../../assets/images/dashboard/user.png';
import user1 from '../../assets/images/dashboard/user1.jpg';
import user2 from '../../assets/images/dashboard/user2.jpg';
import user3 from '../../assets/images/dashboard/user3.jpg';
import man from '../../assets/images/dashboard/man.png';
import user5 from '../../assets/images/dashboard/user5.jpg';
import designer from '../../assets/images/dashboard/designer.jpg'


/////Imports ICreapp
import ConsoleData from '../../hooks/ConsoleData';
import DecoderData from '../../hooks/DecoderData';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'

///adicionales 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Body_Sidebar from './rightbar_components/body_sidebar'
import Modal_Sidebar from './rightbar_components/modal_sidebar'
import Modal from 'react-responsive-modal';
/////////////////////////////////


export class Right_sidebar extends Component {
    
    constructor(props) {
        super(props);                

        this.state = {
            Action: "C",
            Action_form: "D",
            Data: {},
            Data_deta: {},
            Data_rem:{},
            open: false,    
            TitleModal: "",        
            form: {},            
            User_email: "",
            User_type: "",
            loading: true,            
            error: false ,
            update_data: false,
            intervalServicesUpdate: null            
        };
                
        this.VerifUpdateData = this.VerifUpdateData.bind(this); 
    }



    
    
    componentDidMount(){        
        ConsoleData("Muestra Right_sidebar");  
        this.GetDataSession("IcreapSessionData");       
        
        /*============ SE AGREGA VALIDACION PARA ACTUALIZAR DATOS DE LOS ALIADOS ==============*/
        if(this.state.intervalServicesUpdate != null ){
            clearInterval(this.state.intervalServicesUpdate);
        }

        let intervalCountServicesId = setInterval(this.VerifUpdateData, 5000);
        this.setState({  
            intervalServicesUpdate: intervalCountServicesId
        }); 
        /*=====================================================================================*/

    }

    componentDidUpdate(){
        ConsoleData("Se actualizan datos");  
        setTimeout(() => {
            if(this.state.intervalCountServicesId != undefined && !this.state.open){
                clearInterval(this.state.intervalCountServicesId);
            }
        }, 500);

        if(this.state.open){
            
        }
        
    }
    
    componentWillUnmount() {
        if(this.state.intervalServicesUpdate != null ){
            clearInterval(this.state.intervalServicesUpdate);
        }        

        if(this.state.intervalCountServicesId != undefined){
            clearInterval(this.state.intervalCountServicesId);
        }
    }

    VerifUpdateData(){
        const objSideBar = document.querySelector(".right-sidebar");
        //ConsoleData("VerifUpdateData");
        //ConsoleData(objSideBar.classList.contains("show"));        

        ////Se debe verificar que el elemento aun exista o sino presentara errores
        if (objSideBar !== null) {

            if(objSideBar.classList.contains("show")){
                if(!this.state.update_data){
                    ConsoleData("Actualiza VerifUpdateData");
                    this.getDataSideBar(this.state.Action);
                    this.setState({  
                        update_data : true
                    });                             
                }
            }else{
                if(this.state.update_data){
                    this.setState({  
                        update_data : false
                    }); 
                }
            }
        }
        ////////////////////////////////
        
    }

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);            
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            setTimeout(() => {
                this.getDataSideBar(this.state.Action);
            }, 500);
            //this.GetDataStore([DataValue.data[0].Email,DataValue.data[0].Tipo])
        }
    }

    LoadModalChat = (data_chat) => {
        ConsoleData("data_chat");
        ConsoleData(data_chat.id_chat);
        
        this.setState({  
            TitleModal: data_chat.store,   
            loading:true,          
            form: {
                id_chat : data_chat.id_chat
            }
        });        

        
        ////Se define intervalo de chat
        let intervalCountServicesId = setInterval(this.intervalChat, 5000);
        this.setState({intervalCountServicesId: intervalCountServicesId}); 

        ///Se carga una consulta previa al intervalo
        setTimeout(() => {
            this.intervalChat();
        }, 500);
        //////////////////////////////////////////////
        
        
    }

    intervalChat = () => {
        this.getDataSideBar(this.state.Action_form);
        if(!this.state.open){
            this.onOpenModal();                    
        }
    }

    onOpenModal = () => {
        //console.log("onOpenModal");
        //console.log(e);
        this.setState({            
            open: true            
        });
    };

    onCloseModal = () => {        
        this.setState({            
            open: false,
            TitleModal: ""    
        });
    }


    TxtDataChat = (DataTXT) => {
        ConsoleData("TxtDataChat");
        ConsoleData(DataTXT);

        this.setState({              
            form: {
                id_chat: this.state.form.id_chat,
                data_txt: DataTXT 
            }
        });

        setTimeout(() => {
            this.putDataChat("T");
            this.onOpenModal();        
        }, 300);
    }


    putDataChat = (Action_query) => {
        ConsoleData("putDataChat");
        const Urlquery = icreappconfig.url_controller+"AdminChat.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);

        const formData = new FormData();           

        formData.append('Action',Action_query);    
        formData.append('User_email',this.state.User_email);    
        formData.append('User_type',this.state.User_type); 
        formData.append('form',JSON.stringify(this.state.form) );    

        try{
            axios.post(Urlquery,formData)
                .then(res => {
                                        
                    ConsoleData("putDataChat request");   
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});  
                        
                        ///Se cargan nuevamente los datos a pintar
                        this.getDataSideBar(this.state.Action_form);
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        toast.error(decoder);

                        this.setState({                                                                                                           
                            Msg_load: decoder,
                            loading: false,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }


    }

    getDataSideBar = (Action_query) => {
        ConsoleData("getDataSideBar");

        if(Action_query==undefined || Action_query==""){
            return false;
        }

        const Urlquery = icreappconfig.url_controller+"AdminChat.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);

        const formData = new FormData();           

        formData.append('Action',Action_query);    
        formData.append('User_email',this.state.User_email);    
        formData.append('User_type',this.state.User_type); 
        formData.append('form',JSON.stringify(this.state.form) );            

        //console.log(formData);                
        if(Action_query==this.state.Action){
            this.setState({                            
                loading: true,                      
                error: false,
                Msg_error: ""                        
            });    
        }
        
        try{
            axios.post(Urlquery,formData)
                .then(res => {
                                        
                    ConsoleData("getDataSideBar request");   
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                                                 
                        ConsoleData("Side-bar DATA");   
                        ConsoleData(res.data[0].Data);   

                        if(Action_query==this.state.Action){
                            this.setState({                            
                                Data : res.data[0].Data,                                 
                                Msg_load: decoder,
                                loading: false,
                                error: false                        
                            })                    
                        }else if(Action_query==this.state.Action_form){
                            this.setState({                            
                                Data_deta : res.data[0].Data,                                 
                                Data_rem : res.data[0].Rem,                                 
                                Msg_load: decoder,
                                loading: false,
                                error: false                        
                            })                    

                        }                                                
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        if(Action_query==this.state.Action){
                            this.setState({                            
                                Data : {},                                                        
                                Msg_load: decoder,
                                loading: false,
                                error: false                        
                            })                    
                        }else if(Action_query==this.state.Action_form){
                            this.setState({                            
                                Data_deta : {},                                 
                                Data_rem : {},                                 
                                Msg_load: decoder,
                                loading: false,
                                error: false                        
                            })                    

                        }

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }



    }        
    
    
    render() {

        const data_body = this.state.Data;
        const data_body_modal = this.state.Data_deta;
        const data_rem_modal = this.state.Data_rem;

        const loading = this.state.loading;
        const event_chat = this.LoadModalChat;      
        const TxtDataChat = this.TxtDataChat;  
        const { open,TitleModal } = this.state;           

        return (
            <div>
                <div className="right-sidebar" id="right_side_bar">
                    <div>
                        <div>

                            <Modal open={open} onClose={this.onCloseModal} >
                                <div className="modal-header">
                                    <h5 className="modal-title f-w-600" id="exampleModalLabel2">{TitleModal}</h5>
                                </div>   
                                <Modal_Sidebar 
                                        data={data_body_modal} 
                                        rem={data_rem_modal} 
                                        loading={loading} 
                                        eventchat={TxtDataChat} 
                                        eventfile={null}
                                        />    

                            </Modal>
                        </div>
                        
                        <div className="container p-0">
                            <div className="modal-header p-l-20 p-r-20">
                                <div className="col-sm-8 p-0">
                                    <h6 className="modal-title font-weight-bold">{/*FRIEND LIST */}Comercios Aliados</h6>
                                </div>
                                <div className="col-sm-4 text-right p-0"><i className="mr-2" data-feather="settings"></i></div>
                            </div>
                        </div>
                        
                        {
                        /*
                            <div className="friend-list-search mt-0">
                                <input type="text" placeholder="buscar aliado" /><i className="fa fa-search" ></i>
                            </div>
                        */
                        }

                        
                        <div className="p-l-30 p-r-30">
                            <div className="chat-box">
                                <div className="people-list friend-list" style={{overflow:'hidden'}} >
                                    <ul className="list">


                                        <Body_Sidebar 
                                            data = {data_body}
                                            loading = { loading }
                                            event = { event_chat}
                                        />                                        


                                        {
                                        /*

                                            <li className="clearfix"><img className="rounded-circle user-image" src={user} alt="" />
                                                <div className="status-circle online"></div>
                                                <div className="about">
                                                    <div className="name">Vincent Porter</div>
                                                    <div className="status"> Online</div>
                                                </div>
                                            </li>
                                            <li className="clearfix"><img className="rounded-circle user-image" src={user1} alt="" />
                                                <div className="status-circle away"></div>
                                                <div className="about">
                                                    <div className="name">Ain Chavez</div>
                                                    <div className="status"> 28 minutes ago</div>
                                                </div>
                                            </li>
                                            <li className="clearfix"><img className="rounded-circle user-image" src={user2} alt="" />
                                                <div className="status-circle online"></div>
                                                <div className="about">
                                                    <div className="name">Kori Thomas</div>
                                                    <div className="status"> Online</div>
                                                </div>
                                            </li>
                                            <li className="clearfix"><img className="rounded-circle user-image" src={user3} alt="" />
                                                <div className="status-circle online"></div>
                                                <div className="about">
                                                    <div className="name">Erica Hughes</div>
                                                    <div className="status"> Online</div>
                                                </div>
                                            </li>
                                            <li className="clearfix"><img className="rounded-circle user-image" src={man} alt="" />
                                                <div className="status-circle offline"></div>
                                                <div className="about">
                                                    <div className="name">Ginger Johnston</div>
                                                    <div className="status"> 2 minutes ago</div>
                                                </div>
                                            </li>
                                            <li className="clearfix"><img className="rounded-circle user-image" src={user5} alt="" />
                                                <div className="status-circle away"></div>
                                                <div className="about">
                                                    <div className="name">Prasanth Anand</div>
                                                    <div className="status"> 2 hour ago</div>
                                                </div>
                                            </li>
                                            <li className="clearfix"><img className="rounded-circle user-image" src={designer} alt="" />
                                                <div className="status-circle online"></div>
                                                <div className="about">
                                                    <div className="name">Hileri Jecno</div>
                                                    <div className="status"> Online</div>
                                                </div>
                                            </li>
                                        
                                        */
                                        }
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />                                        
                </div>
            </div>
        )
    }
}

export default Right_sidebar

import React, { Component, Fragment, useState, useEffect } from 'react';

import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Unlock, HelpCircle, File } from 'react-feather';
import { withRouter } from 'react-router-dom';

import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'

import axios from 'axios';
import Icreappconfig from '../../Icreappconfig';
import ConsoleData from '../../hooks/ConsoleData';
import DecoderData from '../../hooks/DecoderData';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Accordion, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export class BiddingTabset extends Component {
        
    constructor(props) {
        super(props);                

        this.state = {
            tipo: 'B',
            Id_post: "",
            data_post : [],
            SessionState: false,
            SessionMessage: '',
            RegisterMessage: '',
            Action_form: 'R',
            form:{
                deta:{                    
                    Cargo:"",
                    Escolaridad:{
                        value: 0,
                        description: "",
                        handler_input:false,
                    },                    
                    Contrato:{
                        value: 0,
                        description: "",
                        handler_input: false
                    },
                    Horario:{
                        value: 0,
                        description: "",
                        handler_input: false
                    },
                    Direccion:{
                        value: "",
                        description: "",
                        handler_input: false
                    },
                    Salario:"",
                    Beneficios:"",
                    Experiencia:{
                        value: 0,
                        description: "",
                        handler_input: false
                    },
                    Discapacidad:{
                        value: 0,
                        description: "",
                        handler_input:false,
                    },                    
                    Certificaciones:[],                                        
                    Cursos:[],
                    Sexo:{
                        value: 0,
                        description: "",
                        handler_input: false
                    },
                    Vacantes:0,
                },                
                NITStore: "",
                NameStore: "",
                ProcessPerson: "",
                ProcessPhone: "",
                ProcessEmail: "",                

            },  
            data_structure: [],
            Msg_error: "",
            Msg_success: "", 
            Btn: "",             
            loading:false
        }

        /*
        this.state = {
            activeShow: true,
            startDate: new Date()
        }
        */
        
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeReg = this.handleChangeReg.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.GetDataSession = this.GetDataSession.bind(this);
        this.Func_login = this.Func_login.bind(this);
        
    }

    componentWillMount(){
        ///Se valida que la sesion este iniciada para redireccionar
        //this.GetDataSession("IcreapSessionData");      
        this.GetFormStructure(); 
    }
    

    ///Se debe inicializar la variable de estado para evitar errores al iniciar los campos
    //state = {};
    

    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove('show');
        event.target.classList.add('show');
    }

    /*Se cambia funcion para cambiar datos de estado
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    */
    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })
        
    } 

    handleChangeDeta = e => { 
                
        this.setState({
            form:{
                ...this.state.form,
                deta:{
                    ...this.state.form.deta,
                    [e.target.name]: e.target.value
                }                
            }            
        })                   
    }

    handleChangeDetaChildValue = e => {     
        
        ConsoleData(e);

        this.setState({
            form:{
                ...this.state.form,
                deta:{
                    ...this.state.form.deta,
                    [e.target.name]: {
                        ...this.state.form.deta[e.target.name],    
                        value: e.target.value
                    }
                }                
            }            
        })                   
    }


    handleChangeDetaRadioChildValue = (id_form, value) => {     
        
        ConsoleData(id_form, value);

        this.setState({
            form:{
                ...this.state.form,
                deta:{
                    ...this.state.form.deta,
                    [id_form]: {
                        ...this.state.form.deta[id_form],    
                        value: value
                    }
                }                
            }            
        })                   
    }


    handleChangeCheckGroup = (id_form, value) => {     
        
        ConsoleData(id_form);
        ConsoleData(value);

        let Data_change = this.state.form.deta[id_form];
        let New_Data = [];
        let Save_new_Data = true;

        ConsoleData(Data_change);

        for(let _i=0; _i<Data_change.length; _i++ ){
            if(Data_change[_i].value != value ){
                New_Data.push(Data_change[_i]);
            }else{
                Save_new_Data=false
            }
        }

        if(Save_new_Data){
            New_Data.push({"value": value, "id_form":id_form, "description" : ""});
        }

        
        this.setState({
            form:{
                ...this.state.form,
                deta:{
                    ...this.state.form.deta,
                    [id_form]: New_Data
                }                
            }            
        })        
    }


    handleChangeSelectValue = e => {     
        
        ///ConsoleData(e);
        ///Se valida que maneje campo de texto para habilitarlo
        const dataStructure = this.state.data_structure;
        let HandlerInput = false;
        dataStructure.map( (CurrentData,i) => {   
            if(CurrentData.type=="SelectInput" ){
                
                if(CurrentData.handle_extra_field>0){
                    CurrentData.data_deta.map( (CurrentDataDeta,i) => {                                       
                    
                        if(CurrentDataDeta.id_child == e.target.value){
                            if(CurrentDataDeta.handle_extra_field>0){
                                HandlerInput = true;
                            }
                            //ConsoleData(CurrentDataDeta);
                        }
    
                    });
                }
                
            }
        });
        /////////////////////////

        this.setState({
            form:{
                ...this.state.form,
                deta:{
                    ...this.state.form.deta,
                    [e.target.name]: {
                        ...this.state.form.deta[e.target.name],    
                        handler_input: HandlerInput,
                        value: e.target.value
                    }
                }                
            }            
        })                   
    }        

    handleChangeDetaChildDesc = e => {        
        this.setState({
            form:{
                ...this.state.form,
                deta:{
                    ...this.state.form.deta,
                    [e.target.name]: {
                        ...this.state.form.deta[e.target.name],    
                        description: e.target.value
                    }
                }                
            }            
        })                   
    }

    handleChangeReg = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })        
    }

    onCleanStates = () => {


        this.setState({ 
            tipo: 'B',
            SessionState: false,
            SessionMessage: '',
            RegisterMessage: '',
            Action_form: 'R',
            form:{
                deta:{                    
                    Cargo:"",
                    Escolaridad:{
                        value: 0,
                        description: "",
                        handler_input:false,
                    },
                    Contrato:{
                        value: 0,
                        description: "",
                        handler_input: false
                    },
                    Salario:"",
                    Beneficios:"",
                    Experiencia:{
                        value: 0,
                        description: "",
                        handler_input: false
                    },
                    Discapacidad:{
                        value: 0,
                        description: "",
                        handler_input:false,
                    },                    
                    Certificaciones:[],                                        
                    Cursos:[],
                    Sexo:{
                        value: 0,
                        description: "",
                        handler_input: false
                    },
                    Vacantes:0,
                },                
                NITStore: "",
                NameStore: "",
                ProcessPerson: "",
                ProcessPhone: "",
                ProcessEmail: "",                

            },  
            data_structure: [],
            loading:false
        });


        this.GetFormStructure(); 
            

    };

    handleSubmit = async e => {
        ConsoleData("Submit")

        e.preventDefault()           
        
        ///Validaciones adicionales
        if(this.state.form.NITStore.length < 7){
            toast.error( "El N.I.T no puede ser menor a 7 caracteres. " );
            return;
        }

        if(this.state.form.NameStore.length < 5){
            toast.error( "La razón social no puede ser menor a 5 caracteres." );
            return;
        }

        if(this.state.form.ProcessPerson.length < 10){
            toast.error( "El nombre de la Persona Encargada debe ser superior a 10 caracteres." );
            return;
        }

        if(this.state.form.ProcessPhone.length < 7){
            toast.error( "El telefono de la Persona Encargada debe ser superior a 7 caracteres." );
            return;
        }

        if(this.state.form.ProcessEmail.length<4){
            toast.error( "Debes ingresar un email correcto." );
            return;
        }else{
            if(this.state.form.ProcessEmail.indexOf("@")<0 || this.state.form.ProcessEmail.indexOf(".")<0){
                toast.error( "Debes ingresar un email correcto." );
                return;
            }
        }


        if(this.state.form.deta.Cargo.length < 5){
            toast.error( "El nombre del cargo no puede ser menor a 5 caracteres" );
            return;
        }

        if(this.state.form.deta.Salario.length < 5){
            toast.error( "la descripción del salario no puede ser menor a 5 caracteres" );
            return;
        }


        if(this.state.form.deta.Vacantes < 1){
            toast.error( "El numero de Vacantes no puede ser menor a 1" );
            return;
        }

        if(this.state.form.deta.Escolaridad.value == 0){
            toast.error( "Debe escoger un nivel de escolaridad" );
            return;
        }

        if(this.state.form.deta.Contrato.value == 0){
            toast.error( "Debe escoger un tipo de contrato" );
            return;
        }

        if(this.state.form.deta.Horario.value == 0){
            toast.error( "Debe escoger un tipo de horario" );
            return;
        }

        if(this.state.form.deta.Direccion.value == 0){
            toast.error( "Debe escoger una ubicación de la oferta" );
            return;
        }

        if(this.state.form.deta.Sexo.value == 0){
            toast.error( "Debe seleccionar el sexo requerido para la oferta." );
            return;
        }

        if(this.state.form.deta.Experiencia.description.value > 0){
            if(this.state.form.deta.Experiencia.description.length <= 0){
                toast.error( "Debe ingresar una descripción de la experiencia" );
                return;
            }
        }       
        
        if(this.state.form.deta.Discapacidad.value == 0){
            toast.error( "Debe escoger una opción de discapacidad" );
            return;
        }
        ////////////////////////////////        
        
        try {
            
            //console.log(this.state.form);            
            
            const Urlquery = icreappconfig.url_controller+"AdminEmployment.php";
            console.log(Urlquery);        

            const formData = new FormData();               
            formData.append('Action',this.state.Action_form);                
            formData.append('Form',JSON.stringify(this.state.form)); 
    
            /*
            const Params = {
                Action : this.state.Action_form,
                User_email : this.state.User_email,
                User_type : this.state.User_type,
                Form : this.state.form
            }
            console.log(Params);           
            */
    
           this.setState({                            
                loading: true,
                error: false,
                Msg_error: "",
                Msg_success: "",          
            });        
    
            //axios.post(Urlquery,Params)
            axios.post(Urlquery,formData)
                .then(res => {
                
                ConsoleData(res.data);   
                
                if(res.data[0].Status){
                                                                                
                    //let decoder = decodeURIComponent(escape(res.data[0].Msg));                                        
                    let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       

                    ///Se utiliza para cerrar el modal despues de registrar el cambio
                    this.setState({
                        loading: false,
                        error: false,
                        Msg_success : res.data[0].Msg, 
                        Btn :  res.data[0].Btn,
                    })       

                    toast.success(decoder);                                                
                    ///this.onCleanStates();                                                      

                }else{  
                    //alert(res.data[0].Msg); 
                    //let decoder = decodeURIComponent(escape(res.data[0].Msg));    
                    
                    
                    let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       

                    toast.error( decoder );
                    
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_success: "",    
                        ///Msg_Error: res.data[0].Msg
                    })                    
                }
                
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_success: "",    
                        ///Msg_Error: err
                    })
                });                                    

            ////this.props.history.push('/exercise')

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        

    }

    
    routeChange = () => {
        //Se cambia a ruta temporal mientras se define dashboard
        //this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);

        ///Se valida a donde se dirige dependiendo del tipo de usuario
        let SessionDataValue = sessionStorage.getItem("IcreapSessionData");
        let DataValue = JSON.parse(SessionDataValue);  
        if(parseInt(DataValue.data[0].Tipo)<9){
            this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
        }else{
            this.props.history.push(`${process.env.PUBLIC_URL}/store/data_store`);
        }


        
    }    


    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            let expirationDate = new Date(DataValue.expirationDate);
            if (expirationDate > new Date()) {
                this.routeChange();
            } else {
                sessionStorage.removeItem(key);                
            }

        }else{
            //console.log("No se encontro session iniciada");
            ConsoleData("No se encontro session iniciada");
            ///this.GetHelpFiles();
            this.GetFormStructure();
        }

    }
    
    Func_login(action){

        const UrlLogin = icreappconfig.url_controller+"Reg_UserLogin.php";
        //console.log(UrlLogin);        
        ConsoleData(UrlLogin);

        const Params = {
            Email: this.state.Name,
            Pasw: this.state.Pasw,
            Action: action
        }

        //console.log(Params);        
        ConsoleData(Params);

        /*
        axios.defaults.headers.post['Content-Type'] ='application/json';               
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';
        axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';        
        //const {data} = axios.post(UrlLogin,Params);        
        //console.log(data);                
        */

        axios.post(UrlLogin,Params)
             .then(res => {
                //console.log(res.data);   
                ConsoleData(res.data);
                
                if(res.data[0].Status){
                    let expirationDate = new Date(new Date().getTime() + (60000 * icreappconfig.sessionExpiration) );
                    let ValueData = {
                        data: res.data,
                        expirationDate: expirationDate.toISOString()
                    }

                    this.setState({
                        SessionState: true,
                        SessionMessage: ''
                    })

                    sessionStorage.setItem('IcreapSessionData', JSON.stringify(ValueData));
                    
                    setTimeout(() => {
                        this.routeChange();
                    }, 300);
                    
                }else{
                    //console.log("Error al ingresar "+res.data[0].Msg);
                    ConsoleData("Error al ingresar "+res.data[0].Msg);                    

                    this.setState({
                        SessionState: true,
                        SessionMessage: res.data[0].Msg
                    })

                    this.render();
                }

                
             })
             .catch(err => console.error(err));

    }

    GetFormStructure = () => {
        const Urlquery = icreappconfig.url_controller+"AdminEmployment.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);

        const formData = new FormData(); 
        formData.append('Action',"C");            

        ///Se reinician estados para control de datos
        this.setState({            
            data_structure: [],  
            loading: true            
        })

        try{
            axios.post(Urlquery,formData)
                .then(res => {
                    
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        ConsoleData(res.data[0].Data);
                        
                        this.setState({                            
                            data_structure: res.data[0].Data,
                            loading : false,                           
                            Msg_load: decoder,                            
                            error: false                        
                        })           
                                                
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            data_structure: [],
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({                            
                            data_structure: [],
                            loading: false,
                            error: true,                            
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
    
    
    }

    routeChangeFile  = (file) => {        
        
        sessionStorage.removeItem("HelpFile");      
        sessionStorage.setItem("HelpFile", file );
        this.props.history.push(`${process.env.PUBLIC_URL}/view_help`);

    }

    viewHelpFiles = () => {


        ConsoleData("Ingreso viewHelpFiles")

        const helpfiles = this.state.files_help;
        const routeChangeFile = this.routeChangeFile;

        return helpfiles.map( (CurrentData,i) => {   
            //ConsoleData(Object.values(form_data).includes(CurrentData.Cod_biddingpost));

            return (
                <tr key={i}> 
                    <td>
                        <a href={Icreappconfig.url_platform+CurrentData.name_file} target="_blank" >
                            <File />{CurrentData.desc_file}
                        </a>                                    
                    </td>                            
                </tr>                
            )                                                
            
        })

    }


    viewFormStructure = () => {


        //ConsoleData("Ingreso viewFormStructure")
        //ConsoleData("Ingreso viewFormStructure")

        const dataStructure = this.state.data_structure;
        const routeChangeFile = this.routeChangeFile;

        //ConsoleData(dataStructure)

        return dataStructure.map( (CurrentData,i) => {   
            //ConsoleData(Object.values(form_data).includes(CurrentData.Cod_biddingpost));

            if(CurrentData.type=="Text"){
                return this.viewFormText(CurrentData,i);
            }else if(CurrentData.type=="RadioButton"){
                return this.viewFormRadioButton(CurrentData,i);
            }else if(CurrentData.type=="TextArea"){
                return this.viewFormTextArea(CurrentData,i);
            }else if(CurrentData.type=="Number"){
                return this.viewFormNumber(CurrentData,i);
            }else if(CurrentData.type=="Input-Desc"){
                return this.viewFormInputDesc(CurrentData,i);
            }else if(CurrentData.type=="Checkbox-Groups"){
                return this.viewFormCheckboxGroup(CurrentData,i);
            }else if(CurrentData.type=="DateInput"){
                return this.viewFormDate(CurrentData,i);
            }else if(CurrentData.type=="SelectInput"){
                return this.viewFormSelectOption(CurrentData,i);
            }else if(CurrentData.type=="Collapse-Groups"){
                return this.viewFormCollapse(CurrentData,i);
            }else {

                return (
                
                    <div className={"form-group col-md-"+CurrentData.spacing} key={i}> 
                        
                            <a href={Icreappconfig.url_platform+CurrentData.name} target="_blank" >
                                {CurrentData.name}
                            </a>                                                        
                    </div>                
                )

            }

                                                            
            
        })

    }

    viewFormText = (DataForm,i) => {        

        return  <div className={"form-group col-md-"+DataForm.spacing} key={i}> 
                    <label className="font-weight-bold" for={"field_"+DataForm.id_field+"_0"}>{DataForm.name}</label>
                    <input 
                        required={DataForm.required ? true : false} 
                        name={DataForm.name_form} 
                        type="text" 
                        className="form-control" 
                        placeholder="" 
                        onChange={this.handleChangeDeta} 
                        value={this.state.form.deta[DataForm.name_form]} 
                    />                                    
                </div> ;                             
    }

    viewFormTextArea  = (DataForm,i) => {
        return  <div className={"form-group col-md-"+DataForm.spacing} key={i}> 
                    <label className="font-weight-bold" for={"field_"+DataForm.id_field+"_0"}>{DataForm.name} {DataForm.warning.length>0 ? <span className='text-danger'> * </span> : ""}</label>
                    <small style={{color: "#095f28"}}>
                        {DecoderData({Data: DataForm.warning, Type: "S"})}
                    </small> 
                    <textarea 
                        required={DataForm.required>0 ? true : false} 
                        name={DataForm.name_form} 
                        className="form-control" 
                        placeholder="" 
                        rows="3"
                        onChange={this.handleChangeDeta} 
                        value={this.state.form.deta[DataForm.name_form]} 
                        
                    />                                    
                </div> ;                             
    }

    viewFormNumber = (DataForm,i) => {
        return  <div className={"form-group col-md-"+DataForm.spacing} key={i}> 
                    <label className="font-weight-bold" for={DataForm.name_form}>{DecoderData({Data: DataForm.name, Type: "S"})}</label>
                    <input 
                        required={DataForm.required ? true : false} 
                        name={DataForm.name_form} 
                        type="number" 
                        className="form-control" 
                        placeholder="" 
                        onChange={this.handleChangeDeta} 
                        value={this.state.form.deta[DataForm.name_form]} 
                    />                                    
                </div> ;                             
    }

    viewFormDate = (DataForm,i) => {
        return  <div className={"form-group col-md-"+DataForm.spacing} key={i}> 
                    <label className="font-weight-bold" for={DataForm.name_form}>{DataForm.name}</label>
                    <input 
                        required={DataForm.required ? true : false} 
                        name={DataForm.name_form} 
                        type="date" 
                        className="form-control" 
                        placeholder={DataForm.warning} 
                        onChange={this.handleChangeDeta} 
                        value={this.state.form.deta[DataForm.name_form]} 
                    />                                    
                </div> ;                             
    }

                                                                                                                                                                                                                    
    viewFormInputDesc  = (DataForm,i) => {
        return  <>
                    <div className={"form-group col-md-"+(DataForm.spacing/2)}> 
                        <label className="font-weight-bold" for={"field_"+DataForm.id_field+"_0"}>{DataForm.name}</label>
                        <input 
                            required={DataForm.required ? true : false} 
                            name={DataForm.name_form} 
                            type="number" 
                            className="form-control" 
                            placeholder="" 
                            onChange={this.handleChangeDetaChildValue} 
                            value={this.state.form.deta[DataForm.name_form]["value"]} 
                        />                                    
                    </div>
                    <div className={"form-group col-md-"+(DataForm.spacing/2)}> 
                        <label className="font-weight-bold" for={"field_"+DataForm.id_field+"_0"}>{DataForm.warning}</label>
                        <input 
                            required={DataForm.required ? true : false} 
                            name={DataForm.name_form}
                            type="text" 
                            className="form-control" 
                            placeholder="" 
                            onChange={this.handleChangeDetaChildDesc} 
                            value={this.state.form.deta[DataForm.name_form]["description"]} 
                        />                                    
                    </div>
                </> ;
    }


    viewFormSelectOption = (DataForm,i) => {        
        ConsoleData("Ingreso a viewFormSelectOption");
        ConsoleData(DataForm);        

        if(DataForm.handle_extra_field>0){
            return  <div className={"form-group col-md-"+DataForm.spacing} key={i}> 
                <label className="font-weight-bold" for={DataForm.name_form}>{DecoderData({Data: DataForm.name, Type: "S"})} {DataForm.warning.length>0 ? <span className='text-danger'> * </span> : ""} </label>
                
                <small style={{color: "#095f28"}}>
                    {DecoderData({Data: DataForm.warning, Type: "S"})}
                </small> 

                <div className="row">
                    <div className={DataForm.handle_extra_field>0 ? "form-group col-md-"+(DataForm.spacing/2) : "form-group col-md-"+(DataForm.spacing)}>
                        <select className="custom-select" name={DataForm.name_form} onChange={this.handleChangeSelectValue}>
                            <option value={0} {...this.state.form.deta[DataForm.name_form]["value"]==0? "selected" : ""}>Seleccione una opción</option>
                            {this.viewFormDetaSelectOption(DataForm.data_deta,DataForm.name_form)}                    
                        </select>
                    </div>

                    <div className={"form-group col-md-"+(DataForm.spacing/2)}>
                        <input                                 
                            type="text" 
                            className="form-control" 
                            name={DataForm.name_form} 
                            placeholder="" 
                            disabled={!this.state.form.deta[DataForm.name_form]["handler_input"]}                                                        
                            onChange={this.handleChangeDetaChildDesc} 
                            value={!this.state.form.deta[DataForm.name_form]["handler_input"] ? "" : this.state.form.deta[DataForm.name_form]["description"] }                                 
                        />                                    
                    </div>

                </div>                    
                
            </div> ;    


        }else{
            return  <div className={"form-group col-md-"+DataForm.spacing} key={i}> 
                <label className="font-weight-bold" for={DataForm.name_form}>{DataForm.name} {DataForm.warning.length>0 ? <span className='text-danger'> * </span> : ""} </label>
                
                <small style={{color: "#095f28"}}>
                    {DecoderData({Data: DataForm.warning, Type: "S"})}
                </small> 
                
                
                <select className="custom-select" name={DataForm.name_form} onChange={this.handleChangeSelectValue}>
                    <option value={0} {...this.state.form.deta[DataForm.name_form]["value"]==0? "selected" : ""}>Seleccione una opción</option>
                    {this.viewFormDetaSelectOption(DataForm.data_deta,DataForm.name_form)}                    
                </select>
                
                
            </div> ;     
        }

    }

    viewFormDetaSelectOption = (DataFormDeta,id_field) => {
        //ConsoleData("Revisa viewFormDetaSelectOption");        
        //ConsoleData(id_field);                
        return DataFormDeta.map( (CurrentData,i) => {                                                                         
                return <option value={CurrentData.id_child} selected={this.state.form.deta[id_field]["value"]==CurrentData.id_child} >{CurrentData.name_child}</option>;            
        }
        );
                
    }

    viewFormRadioButton = (DataForm,i) => {
        return  <div className={"form-group col-md-"+DataForm.spacing} key={i}> 
                    <label className="font-weight-bold" for={DataForm.name_form}>{DataForm.name} {DataForm.warning.length>0 ? <span className='text-danger'> * </span> : ""} </label>
                    <small style={{color: "#095f28"}}>
                        {DecoderData({Data: DataForm.warning, Type: "S"})}
                    </small> 

                    {this.viewFormDetaRadioButton(DataForm.data_deta, DataForm.spacing, DataForm.required, DataForm.handle_extra_field, DataForm.name_form)}                    
                    
                </div> ;                             
    }    

    viewFormDetaRadioButton = (DataFormDeta, Espacing, Required, Handle_extra_field, id_field ) => {
        //ConsoleData("Revisa viewFormDetaRadioButton");        
        return DataFormDeta.map( (CurrentData,i) => {   
                        
            if(Handle_extra_field>0 && CurrentData.handle_extra_field>0){                
                return <div className="row" key={i}>
                        <div className={"form-group col-md-"+(Espacing/2)}>
                            <div className="form-check">
                                <input className="form-check-input" 
                                    type="radio" 
                                    value={CurrentData.id_child} 
                                    name={id_field} 
                                    id={"field_child_"+CurrentData.id_child}
                                    onChange={ () => this.handleChangeDetaRadioChildValue(id_field,CurrentData.id_child)} 
                                    checked={this.state.form.deta[id_field]["value"]==CurrentData.id_child}                                     
                                />

                                <label className="form-check-label" for={"field_child_"+CurrentData.id_child} >
                                    {CurrentData.name_child}
                                </label>
                            </div>                    
                        </div>
                        <div className={"input-group-sm col-md-"+(Espacing/2)}>
                            <input                                 
                                type="text" 
                                className="form-control" 
                                name={id_field} 
                                placeholder="" 
                                disabled={this.state.form.deta[id_field]["value"]== CurrentData.id_child ? false : true }
                                onChange={this.handleChangeDetaChildDesc} 
                                value={this.state.form.deta[id_field]["value"]== CurrentData.id_child ? this.state.form.deta[id_field]["description"] : "" }                                 
                            />                                    
                        </div>
                    </div>;
            }else{                
                return <div className="row" key={i}>
                        <div className={"form-group col-md-"+(Espacing/2)}>
                            <div className="form-check">
                                <input className="form-check-input" 
                                    type="radio"                                     
                                    name={id_field} 
                                    id={"field_child_"+CurrentData.id_child}
                                    onChange={ () => this.handleChangeDetaRadioChildValue(id_field,CurrentData.id_child)} 
                                    checked={this.state.form.deta[id_field]["value"]==CurrentData.id_child}       
                                />
                                <label className="form-check-label" for={"field_child_"+CurrentData.id_child}>
                                    {CurrentData.name_child}
                                </label>
                            </div>                    
                        </div>
                        <div className={"input-group-sm col-md-"+(Espacing/2)}>                            
                        </div>
                    </div>;
            }

        }
        );
                
    }

    viewFormCheckboxGroup = (DataForm,i) => {
        return  <div className={"form-group col-md-"+DataForm.spacing} key={i}> 
                    <label className="font-weight-bold" for={DataForm.name_form}>{DataForm.name} {DataForm.warning.length>0 ? <span className='text-danger'> * </span> : ""} </label>
                    <small style={{color: "#095f28"}}>
                        {DecoderData({Data: DataForm.warning, Type: "S"})}
                    </small> 
                    
                    <div className="row">
                        {this.viewFormDetaCheckboxGroup(DataForm.data_deta, DataForm.spacing, DataForm.required, DataForm.handle_extra_field, DataForm.name_form)}                    
                    </div>

                </div> ;                             
    }

    viewFormDetaCheckboxGroup = (DataFormDeta, Espacing, Required, Handle_extra_field, id_field ) => {
        //ConsoleData("Revisa viewFormDetaRadioButton");        
        return DataFormDeta.map( (CurrentData,i) => {   

            if(CurrentData.handle_deta>0){
                return <div className={"form-group col-md-"+(Espacing/2)} key={i}> 
                    <label className="font-weight-bold">{CurrentData.name_child}</label>                    
                    {this.viewFormDetaParentCheckboxGroup(CurrentData.data_child, id_field)}                    
                </div> ;                             

            }else{
                return <div className={"form-group col-md-"+(Espacing)} key={i}>
                
                    <div className="form-check">                    
                        <input className="form-check-input" type="checkbox" name={id_field} id={"field_child_"+CurrentData.id_child} onChange={() => this.handleChangeCheckGroup(id_field,CurrentData.id_child)} />
                        <label className="form-check-label" for={"field_child_"+CurrentData.id_child}>
                            {CurrentData.name_child}
                        </label>
                    </div>                    
                </div>;

            }

            

        }
        );
                
    }

    viewFormDetaParentCheckboxGroup = (DataFormDeta, id_field ) => {
        //ConsoleData("Revisa viewFormDetaRadioButton");        
        return DataFormDeta.map( (CurrentData,i) => {   
                        
                            
            return <div className="form-check" key={i}>
                            <input className="form-check-input" type="checkbox" name={id_field} id={"field_child_"+CurrentData.id_child} onChange={() => this.handleChangeCheckGroup(id_field,CurrentData.id_child)}/>
                            <label className="form-check-label" for={"field_child_"+CurrentData.id_child}>
                                {CurrentData.name_child}
                            </label>
                        </div>;
        }
        );
                
    }


    viewFormCollapse = (DataForm,i) => {

        return (<div className={"col-md-"+DataForm.spacing} key={i}>

                <Accordion>

                    <Card>

                        <Accordion.Toggle as={Card.Header} eventKey="0">

                            <h5 className="mb-0">
                                                                
                                {DataForm.name}

                                <br/>

                                {DataForm.warning.length>0 ? <span className='text-danger'> * </span> : ""}
                                
                                <small style={{color: "#095f28"}}>                            
                                    {DecoderData({Data: DataForm.warning, Type: "S"})}
                                </small>
                                                                                                
                            </h5>

                        </Accordion.Toggle>


                        <Accordion.Collapse eventKey="0">
                            <div className="row m-5">
                                {this.viewFormDetaCheckboxGroup(DataForm.data_deta, DataForm.spacing, DataForm.required, DataForm.handle_extra_field, DataForm.name_form)}                    
                            </div>
                        </Accordion.Collapse>

                    </Card>                    

                </Accordion>

            </div> 
        );     
    }

    GetDataPost = (Id_post) => {
        const Urlquery = icreappconfig.url_controller+"AdminEmployment.php";
        //console.log(Urlquery);        

        const formData = new FormData(); 
        formData.append('Action',"D");            
        formData.append('Id_post',Id_post);            

        /*
        const Params = {
            Action: this.state.Action,              
            Id_post: this.state.IdPost
        }
        */

        ///Se reinician estados para control de datos
        this.setState({
            data_post : [],
            loading : true,
            error : true,
            Msg_load: ""            
        })

        //console.log(Params); 
        try{
            axios
            .post(Urlquery,formData)
            .then(res => {
                ConsoleData(res.data);   
                
                if(res.data[0].Status){                                                   
                    let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                        
                    
                    let Man_data = res.data[0].Data[0];
                                  
                    /*
                    this.setState({                            
                        data_post : Man_data,                                                      
                        data_groups: [],
                        Msg_load: decoder,
                        loading: false,
                        error: false                        
                    });  
                    */


                    ///Se recorre la data para asignar las variables a cada State
                    ConsoleData("DATA POST");   
                    ConsoleData(Man_data);   
                    ConsoleData(Man_data.name_store);   

                    let Man_data_deta = Man_data.data;

                    ///Variables para asignacion de States
                    let data_Cargo = "";
                    let data_Escolaridad = {
                        value: 0,
                        description: "",
                        handler_input:false,
                    };
                    let data_Contrato = {
                        value: 0,
                        description: "",
                        handler_input: false
                    };
                    let data_Horario = {
                        value: 0,
                        description: "",
                        handler_input: false
                    }
                    let data_Direccion = {
                        value: "",
                        description: "",
                        handler_input: false
                    };
                    let data_Salario = "";
                    let data_Beneficios = "";
                    let data_Experiencia = {
                        value: 0,
                        description: "",
                        handler_input: false
                    };
                    let data_Discapacidad = {
                        value: 0,
                        description: "",
                        handler_input:false,
                    }
                    let data_Certificaciones = [];
                    let data_Cursos = [];
                    let data_Sexo = {
                        value: 0,
                        description: "",
                        handler_input: false
                    };
                    let data_Vacantes = 0;


                    ////////////////////////////
                    
                    Man_data_deta.map(function (CurrentData,i){
                        ConsoleData("Man_data_deta "+i);   
                        ConsoleData(CurrentData); 
                        if(CurrentData.name_form == "Cargo"){ data_Cargo = CurrentData.value;  }  
                        else if(CurrentData.name_form == "Salario"){ data_Salario = CurrentData.description;  }  
                        else if(CurrentData.name_form == "Beneficios"){ data_Beneficios = CurrentData.description;  }  
                        else if(CurrentData.name_form == "Experiencia"){ data_Experiencia.value = CurrentData.value; data_Experiencia.description = CurrentData.description;  }  
                        else if(CurrentData.name_form == "Vacantes"){ data_Vacantes = CurrentData.value;  }  
                        else if(CurrentData.name_form == "Escolaridad"){ data_Escolaridad.value = CurrentData.data[0][0].id_child; data_Escolaridad.description = CurrentData.data[0][0].description; data_Escolaridad.handler_input = parseInt(CurrentData.data[0][0].handler_input); }  
                        else if(CurrentData.name_form == "Contrato"){ data_Contrato.value = CurrentData.data[0][0].id_child; data_Contrato.description = CurrentData.data[0][0].description; data_Contrato.handler_input = parseInt(CurrentData.data[0][0].handler_input);  }  
                        else if(CurrentData.name_form == "Horario"){ data_Horario.value = CurrentData.data[0][0].id_child; data_Horario.description = CurrentData.data[0][0].description; data_Horario.handler_input = parseInt(CurrentData.data[0][0].handler_input);   }  
                        else if(CurrentData.name_form == "Direccion"){ data_Direccion.value = CurrentData.data[0][0].id_child; data_Direccion.description = CurrentData.data[0][0].description; data_Direccion.handler_input = parseInt(CurrentData.data[0][0].handler_input);   }  
                        else if(CurrentData.name_form == "Sexo"){ data_Sexo.value = CurrentData.data[0][0].id_child; data_Sexo.description = CurrentData.data[0][0].description; data_Sexo.handler_input = parseInt(CurrentData.data[0][0].handler_input);   }  
                        else if(CurrentData.name_form == "Certificaciones"){ 

                            CurrentData.data[0].map(function (CurrentDataDeta,ideta){
                                ConsoleData("CurrentDataDeta "+ideta);   
                                ConsoleData(CurrentDataDeta); 

                                data_Certificaciones.push({"value": CurrentDataDeta.id_child, "id_form":CurrentDataDeta.name_form, "description" : ""});

                            })
                            //data_certificado.value = CurrentData.data[0][0].id_child; data_Discapacidad.description = CurrentData.data[0][0].description; data_Discapacidad.handler_input = parseInt(CurrentData.data[0][0].handler_input);   
                        }
                        
                        else if(CurrentData.name_form == "Cursos"){ 

                            CurrentData.data[0].map(function (CurrentDataDeta,ideta){
                                ConsoleData("CurrentDataDeta "+ideta);   
                                ConsoleData(CurrentDataDeta); 

                                data_Cursos.push({"value": CurrentDataDeta.id_child, "id_form":CurrentDataDeta.name_form, "description" : ""});

                            })
                            //data_certificado.value = CurrentData.data[0][0].id_child; data_Discapacidad.description = CurrentData.data[0][0].description; data_Discapacidad.handler_input = parseInt(CurrentData.data[0][0].handler_input);   
                        }
                    });
                    


                    this.setState({
                        form:{
                            ...this.state.form,
                            NITStore: Man_data.id_store,
                            NameStore: Man_data.name_store,
                            ProcessPerson: Man_data.name_person,
                            ProcessPhone: Man_data.phone_person,
                            ProcessEmail: Man_data.email_store,
                            deta:{
                                ...this.state.form.deta,
                                Cargo: data_Cargo,
                                Escolaridad: data_Escolaridad,                    
                                Contrato: data_Contrato,
                                Horario: data_Horario,
                                Direccion: data_Direccion,
                                Salario: data_Salario,
                                Beneficios: data_Beneficios,
                                Experiencia: data_Experiencia,
                                Discapacidad: data_Discapacidad,                    
                                Certificaciones: data_Certificaciones,                                        
                                Cursos: data_Cursos,
                                Sexo: data_Sexo,
                                Vacantes: data_Vacantes,                                
                            }                                            
                        }            
                    });  
                    
                    this.setState({                            
                        data_post : Man_data,                                                      
                        data_groups: [],
                        Msg_load: decoder,
                        loading: false,
                        error: false                        
                    });  
                    
                                        
                }else{                         
                    //let decoder = decodeURIComponent(escape(res.data[0].Msg));
                    let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                

                    this.setState({
                        data_post : [],
                        loading: false,
                        Msg_load: decoder,
                        error: true                        
                    });
                }                
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    data_post : [],
                    loading: false,
                    error: true,
                    Msg_Error: err
                })
            });
        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }        
    }

    render() {

        ConsoleData("render");
        ConsoleData(this.props);

        if(this.state.Id_post == ""){
            this.setState({            
                Id_post: this.props.Id_post,
                Action_form: (this.props.Id_post != null ? 'U' : 'R')
            });

            if(this.props.Id_post != null){
                this.GetDataPost(this.props.Id_post);
            }
        }        

        let view_form_structure = <div></div>;
        let content_tabpanel = <div></div>;

        if(!this.state.loading){     
            
            if(this.state.Msg_success.length>0 || this.state.Msg_error.length>0){
                
                content_tabpanel = <div className="container mt-5">
                                        <div className={this.state.Msg_success.length>0 ? "alert alert-success" : "alert alert-danger" } role="alert" >
                                            {this.state.Msg_success.length>0 ? this.state.Msg_success : this.state.Msg_error}
                                        </div>                                        

                                        <div className="form-footer">
                                            <a className="btn btn-primary" href="register_bidding" role="button">Registrar nueva oferta</a>
                                        </div>


                                    </div>;


            }else{
                if(this.state.data_structure != []){

                    view_form_structure = this.viewFormStructure();

                }else{
                    view_form_structure = <div>
                                        <h3>Estamos en proceso de construccion del formulario </h3>
                                    </div>;                
                }

                content_tabpanel = <form className="form-horizontal auth-form" onSubmit={this.handleSubmit}>
                <div className="container">
                    <div className="row mt-3">                                    

                        <div className="form-group col-md-6">
                            <input required name="NITStore" type="number" className="form-control" placeholder="NIT de la empresa" onChange={this.handleChange} value={this.state.form.NITStore} />
                        </div>
                        
                        <div className="form-group col-md-6">
                            <input required name="NameStore" type="text" className="form-control" placeholder="Nombre de la empresa" onChange={this.handleChange} value={this.state.form.NameStore} />
                        </div>
                        
                        <div className="form-group col-md-12">                                    
                            <input required name="ProcessPerson" type="text" className="form-control" placeholder="Persona encargada del proceso" onChange={this.handleChange} value={this.state.form.ProcessPerson} />
                        </div>
                        
                        <div className="form-group col-md-6">
                            <input required name="ProcessPhone" type="phone" className="form-control" placeholder="Teléfono de contacto" onChange={this.handleChange} value={this.state.form.ProcessPhone} />
                        </div>

                        <div className="form-group col-md-6">
                            <input required name="ProcessEmail" type="email" className="form-control" placeholder="Email de contacto" onChange={this.handleChange} value={this.state.form.ProcessEmail} />
                        </div>

                        <div className="form-group divider bg-dark col-md-12"> </div>

                        {view_form_structure}                        


                                                                                                                                    
                        
                        <div className="form-button">
                            <button className="btn btn-primary" type="submit" >Registrar Oferta Laboral</button>
                        </div>
                        
                        <div className="form-footer">

                            {/* se quita ya que no se utilza
                            
                            <span>O ingresa con tus redes sociales</span>
                            <ul className="social">
                                <li><a className="fa fa-facebook" href=""></a></li>
                                <li><a className="fa fa-google-plus" href=""></a></li>                                        
                            </ul>


                            */}
                            {/* Se comentarea para posterior use
                            
                                <span>Or Login up with social platforms</span>
                                <ul className="social">
                                    <li><a className="fa fa-facebook" href=""></a></li>
                                    <li><a className="fa fa-twitter" href=""></a></li>
                                    <li><a className="fa fa-instagram" href=""></a></li>
                                    <li><a className="fa fa-pinterest" href=""></a></li>
                                </ul>
                            */                                   
                            }    
                            
                        </div>
                    </div>
                </div>
            </form>;

            }                   

            

        }else{
            content_tabpanel = <Loading />;
        }

        let title_tab = ( (this.state.Id_post != null && this.state.Id_post != "") ? "Modificar Oferta Laboral #"+this.state.Id_post : "Registrar Nueva Oferta Laboral" );

        return (
            <Fragment>
                <Tabs>
                    <TabList className="nav nav-tabs tab-coupon" >
                        <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><User />{title_tab}</Tab>                            
                        {/*<Tab className="nav-link" onClick={(e) => this.clickActive(e)}><Unlock />Register</Tab>*/}
                    </TabList>

                        <TabPanel>
                            {content_tabpanel}
                        </TabPanel>                        
                    
                </Tabs>
                <ToastContainer />
            </Fragment>
        );
        

        
    }
}

export default withRouter(BiddingTabset)


import React, { Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal';
import Datatable from '../common/datatable';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import Activity_finder from '../specific/activity_finder'
import Handler_bidding_deta from '../specific/handler_bidding_deta'
import ConsoleData from '../../hooks/ConsoleData'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Bidding_made extends Component {
    constructor(props) {
        super(props);

        this.GetDataStore = this.GetDataStore.bind(this);
        this.GetDataBiddings = this.GetDataBiddings.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);    

        this.state = {
            open: false,
            fase_app: 2,
            Max_size: 5*1000000, //Se maneja máximo de 5MB 
            Action: "C",
            Action_form: "R",
            num_post: 0,
            data_bidding: [],
            data_category: [],
            data_store: [], 
            data_groups: [],
            data_cat: [],
            data_subcat: [],
            data_aliado: [],
            file: null,
            file_name: null, 
            tmp:{                  
                Cod_group: "",
                Cod_category: "",
                Cod_subcategory: "",
                Name_subcategory: ""
            },
            form:{
                deta:{
                    sub_categories:[],
                    deta_bidding: []                    
                },    
                Status_bidding: "",
                Desc : "",
                Type_bidding : "",
                ComercioSel : "",
                Expire : "",
                Delivery: "",
                Items : "",
                Valmax : "",
                PolPag : "",
                Partial : "",
                Employees : "",
                Experience : "",
                FactElect : ""                      
            },            
            Id_bidding : "",            
            loading: true,
            error: false,               
            Msg_load: '',    
            User_email: '',
            User_type: '',     
            Msg_Error: ''
        };
    }


    viewBiddingPost = (props) => {
        ConsoleData(props.Cod_bidding);
        
        const Data_bidding = { Cod_bidding: props.Cod_bidding, Cod_store: this.state.data_store.Cod_store };
        ConsoleData(Data_bidding);

        sessionStorage.removeItem("Data_bidding");      
        sessionStorage.setItem("Data_bidding", JSON.stringify(Data_bidding) );
        this.props.history.push(`${process.env.PUBLIC_URL}/bidding/bidding_postulation`);

    }

    onOpenModal = (e) => {
        ConsoleData("onOpenModal");
        ConsoleData(e);
        this.setState({ 
            Action_form: e,
            open: true            
        });
    };

    onCloseModal = () => {        
        this.setState({
            open: false,       
            Action_form: "R",   
            num_post: 0,
            file: null,
            file_name: null,  
            data_aliado: [],                     
            form:{
                deta:{
                    sub_categories:[],                    
                    deta_bidding: []                 
                },  
                Status_bidding: "",              
                Desc : "",
                Type_bidding : "",
                ComercioSel : "",
                Expire : "",
                Delivery: "",
                Items : "",
                Valmax : "",
                PolPag : "",
                Partial : "",
                Employees : "",
                Experience : "",
                FactElect : ""                      
            }
        })  
    };

    componentDidMount(){        
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataSubCategories();
            this.GetDataSession("IcreapSessionData");        
        }        
    }

    handleChange = e => {
        ConsoleData(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })


        ///Se hace validacion de carga si la solicitud es directa
        if(e.target.name=="Type_bidding"){
            if(e.target.value=="2"){
                    this.LoadDirectStore();
            }else{
                this.setState({       
                    data_aliado: [],
                    form:{
                        ...this.state.form,
                        [e.target.name]: e.target.value,
                        ComercioSel: ""
                    }
                }) ;
            }
        }
        
    }    

    handleChangeFile = e => {
        ConsoleData(e.target.files[0]);

        ///Se valida el tamaño máximo de los archivos
        if(e.target.files[0].size > this.state.Max_size ){
            this.setState({       
                file: e.target.files[0],    
                file_name: "",    
                error: true,
                Msg_error: "El archivo no puede superar el tamaño de "+(this.state.Max_size/1000000)+" MB"
            }) ;

            toast.error("El archivo no puede superar el tamaño de "+(this.state.Max_size/1000000)+" MB");
        }else{
            this.setState({
                file : e.target.files[0],
                file_name: e.target.value,    
                loading: false,                
                error: false,
                Msg_error: ""                
            });
        }
        
    }

    handlerAddSubcategory  = e => {                
        ConsoleData("handlerAddSubcategory");
        ConsoleData(e);

        this.setState({
            tmp:{                
                Cod_subcategory: e[0],                          
                Name_subcategory: e[1]                            
            }            
        });

        setTimeout(() => {
            this.handleSubcategories(["A",""]);            
        }, 500);
        
    }


    handlerAddDetaBidding  = e => {            
        
        ConsoleData("handlerAddDetaBidding");
        ConsoleData(e);
        
        this.setState({            
            form:{
                ...this.state.form,
                deta:{
                    ...this.state.form.deta,
                    deta_bidding: e
                }
            }            
        })
        
    }

    handleSubcategories  = (props) => {        

        if(this.state.tmp.Cod_subcategory=="" && props[0]=="A"){
            toast.error( "Debes seleccionar una Sub Categoría para poder adicionar la actividad a tu perfil." );
            return;
        }

        ConsoleData(props);

        if(props[0]=="A"){ //Adiciona subcategoria
            let TMP_NomSubcategory = this.state.tmp.Name_subcategory;
            let Tam_Subcat=this.state.form.deta.sub_categories.length;
            let Data_subcat=this.state.form.deta.sub_categories;
            //console.log(Tam_Subcat);

            if(Tam_Subcat != 0){
                
                //Se valida que el dato a agregar no se encuentre ya definido
                for(let _i=0; _i<Tam_Subcat; _i++ ){
                    if(Data_subcat[_i].cod_subcat == this.state.tmp.Cod_subcategory){
                        toast.error( "No puedes agregar dos veces la misma actividad. "+Data_subcat[_i].name_subcat );
                        return;
                    }
                }
                /////////////////////////////////////////

                let Data_AddSubcat = { 'cod_subcat' : this.state.tmp.Cod_subcategory, 'name_subcat' : this.state.tmp.Name_subcategory };
                Data_subcat.push(Data_AddSubcat) ;

                this.setState({
                    tmp:{
                        ...this.state.tmp,
                        Cod_subcategory: "",
                        Name_subcategory: ""
                    },
                    form:{
                        ...this.state.form,
                        deta:{
                            ...this.state.form.deta,
                            sub_categories: Data_subcat
                        }
                    }            
                })

                toast.success( "Se agrego correctamente la actividad. "+TMP_NomSubcategory+". ");
                return;

            }else{
                let Data_AddSubcat = [{ 'cod_subcat' : this.state.tmp.Cod_subcategory, 'name_subcat' : this.state.tmp.Name_subcategory }];
                ConsoleData(Data_AddSubcat);
                this.setState({
                    tmp:{
                        ...this.state.tmp,
                        Cod_subcategory: "",
                        Name_subcategory: ""
                    },
                    form:{
                        ...this.state.form,
                        deta:{
                            ...this.state.form.deta,
                            sub_categories: Data_AddSubcat
                        }
                    }            
                })

                toast.success( "Se agrego correctamente la actividad. "+TMP_NomSubcategory+". ");
                return;

            }
        }else if(props[0]=="D"){
            let Tam_Subcat=this.state.form.deta.sub_categories.length;
            let Data_subcat=this.state.form.deta.sub_categories;
            let Data_newsubcat = [];
            let Name_delete = "";

            //Se valida que dato se eliminara y no se agrega al array
            for(let _i=0; _i<Tam_Subcat; _i++ ){
                if(Data_subcat[_i].cod_subcat != props[1]){
                    Data_newsubcat.push(Data_subcat[_i]);
                }else{
                    Name_delete = Data_subcat[_i].name_subcat
                }
            }

            if (window.confirm('¿Esta seguro de eliminar la actividad '+Name_delete+'?')) {
                this.setState({                    
                    form:{
                        ...this.state.form,
                        deta:{
                            ...this.state.form.deta,
                            sub_categories: Data_newsubcat
                        }
                    }            
                })
            }
            /////////////////////////////////////////

        }

    }

    handleSubmit = async e => {
        
        try {

            ConsoleData("Submit")
            e.preventDefault();                            
            ConsoleData("Paso validaciones");

            
            ///Si ya se postularon no se puede cancelar el requerimiento
            if(this.state.Action_form=="M" && this.state.form.Status_bidding=="C" && this.state.num_post>0){
                toast.error("No se puede cancelar el requerimiento porque ya se le han hecho postulaciones.", {                          
                    autoClose: 10000                            
                } );
                return false;
            }

            ///Si se va a cancelar la solicitud se debe hacer doble validacion
            if(this.state.Action_form=="M" && this.state.form.Status_bidding=="C"){
                if (!window.confirm('¿Esta seguro de cambiar el estado a CANCELADO?. Luego de cambiarlo no podrá revertir este cambio. Si desea continuar con el cambio de estado ingrese en la opción Aceptar')) {
                    toast.success("Se detuvo el proceso de cancelacion correctamente" );
                    return false;
                }
            }            
            ////////////////////////////////////////////
            

            ConsoleData(this.state.form.deta_bidding);

            if(this.state.form.deta.deta_bidding == undefined || this.state.form.deta.deta_bidding.length<1){
                toast.error("Se debe agregar mínimo un ítem en el detalle del requerimiento" );
                return false;
            }
                
            const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
            ConsoleData(Urlquery);        
    

            const formData = new FormData();   

            formData.append("Bidding",this.state.file);         
            formData.append('Action',this.state.Action_form);    
            formData.append('User_email',this.state.User_email);    
            formData.append('User_type',this.state.User_type);    
            formData.append('form',JSON.stringify(this.state.form)); 

            //console.log(formData);     
            ConsoleData("form data");           
            ConsoleData(formData);           
            

            this.setState({                            
                loading: true,
                error: false,
                Msg_error: ""                        
            });        
            
            axios.post(Urlquery, formData)            
                .then(res => {
                    ConsoleData(res.data);   
                   
                    if(res.data[0].Status){

                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       
                        
                        this.setState({
                            loading: false,
                            error: false,
                            file: null,
                            file_name: null,
                            form:{
                                deta:{
                                    sub_categories:[],
                                    deta_bidding: []                                                       
                                },  
                                Status_bidding: "",                              
                                Desc : "",
                                Type_bidding : "",
                                ComercioSel : "",
                                Expire : "",
                                Delivery: "",
                                Items : "",
                                Valmax : "",
                                PolPag : "",
                                Partial : "",
                                Employees : "",
                                Experience : "",
                                FactElect : ""                      
                            }
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio                        
                        toast.success(decoder);                                                
                        this.onCloseModal();        
                        this.GetDataBiddings();                              
                    }else{  
                        //alert(res.data[0].Msg); 
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 

                        toast.error( decoder );
                        this.setState({
                            loading: false,
                            error: false,
                            Msg_Error: res.data[0].Msg
                        })
                        
                   }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            ////this.props.history.push('/exercise')

        } catch (error) {

            ConsoleData("Ingreso a Catch");
            ConsoleData(error);

            this.setState({
                loading: false,
                error
            }) 
        }
        

    }

    ///////////////////    

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            this.GetDataStore([DataValue.data[0].Email,DataValue.data[0].Tipo])

        }
    }

    ///carga comercios con los que ya se hayan hecho negocios
    LoadDirectStore(){
        
        try {                                        
            const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
            ConsoleData(Urlquery);        
    

            const formData = new FormData();               
            formData.append('Action','L');              
            formData.append('Cod_store',this.state.data_store.Cod_store);  

            axios.post(Urlquery, formData)            
                .then(res => {
                    ConsoleData(res.data);   
                   
                    if(res.data[0].Status){

                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});     

                        this.setState({                            
                            data_aliado : res.data[0].Data
                        })                        

                    }else{  
                        //alert(res.data[0].Msg); 
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 
                        
                        toast.error( decoder );
                        this.setState({                            
                            data_aliado: []
                        })
                        
                   }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            

        }catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }        
    }
    //////////////////////////////////////

    viewHandlerBiddings = (e) => {
        
        ConsoleData("viewHandlerBiddings");
        ConsoleData(e);
        ConsoleData(e.datos.id);
        this.setState({                            
            loading: true,
            num_post: e.datos.postulaciones
        });   

        ////Se traen los datos de la requerimientos especifica 
        try {                                        
            const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
            ConsoleData(Urlquery);        
    

            const formData = new FormData();               
            formData.append('Action','B');  
            formData.append('Cod_bidding',e.datos.id);  
            formData.append('Cod_store',this.state.data_store.Cod_store);  

            axios.post(Urlquery, formData)            
                .then(res => {
                    ConsoleData(res.data);   
                   
                    if(res.data[0].Status){

                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       
                        this.setState({
                            loading: false,
                            error: false,
                            form : res.data[0].DataBidding[0]                            
                        })

                        this.onOpenModal("M");

                        if(res.data[0].DataBidding[0].Type_bidding=="2"){
                            this.LoadDirectStore();
                        }

                    }else{  
                        //alert(res.data[0].Msg); 
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 

                        toast.error( decoder );
                        this.setState({
                            loading: false,
                            error: false,
                            Msg_Error: res.data[0].Msg
                        })
                        
                   }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            

        }catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }

    }

    GetDataBiddings = () => {
        

        const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
        ConsoleData(Urlquery);        


        const formData = new FormData();           


        formData.append('Action','C');    
        //formData.append('User_email',this.state.User_email);    
        //formData.append('User_type',this.state.User_type);    
        formData.append('Cod_store',this.state.data_store.Cod_store); 

        //console.log(formData);                

        this.setState({                            
            loading: true,
            data_bidding: [],
            error: false,
            Msg_error: ""                        
        });    
        
        try{
            axios.post(Urlquery,formData)
                .then(res => {
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        
                        let Man_bidding = [];
                        if(res.data[0].Data_bidding.length != 0){
                            
                            res.data[0].Data_bidding.map( (CurrentService,i) => {                            
                            
                                ConsoleData(CurrentService);
    
                                let Status_bidding;
                                if(CurrentService.status == "A"){
                                    Status_bidding = <i className="fa fa-circle font-warning f-12" > Activo </i>;                                                               
                                }else if(CurrentService.status == "O"){
                                    Status_bidding = <i className="fa fa-circle font-warning f-12" > Cerrado </i>;                                                               
                                }else if (CurrentService.status == "F") {
                                    Status_bidding = <i className="fa fa-circle font-success f-12" > Finalizado </i>;                                                                                              
                                }else if (CurrentService.status == "C") {
                                    Status_bidding = <i className="fa fa-circle font-danger f-12" > Cancelado </i>;                             
                                }else{
                                    Status_bidding = <i className="fa fa-circle font-danger f-12" > Bloqueado </i>;                             
                                }
    
                                Man_bidding[i] = {                            
                                    id: parseInt(CurrentService.cod_bidding),
                                    //creado: CurrentService.date_create,
                                    expira: CurrentService.date_expires,                            
                                    tipo: CurrentService.type,                            
                                    desc: CurrentService.desc,
                                    postulaciones: CurrentService.num_post,                            
                                    status: Status_bidding                        
                                }                                                
                                
                            })                            

                        }

                        this.setState({                            
                            data_bidding : Man_bidding,                            
                            Msg_load: decoder,
                            loading: false,
                            error: false                        
                        })                    
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            data_bidding : res.data[0].Data_bidding,
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }


    }

    GetDataStore = (Data_Sesion) => {
        const Urlquery = icreappconfig.url_controller+"AdminStores.php";
        ConsoleData(Urlquery);        

        const Params = {
            Action: this.state.Action,
            User_email: Data_Sesion[0],
            User_type: Data_Sesion[1]
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_store: [],
            loading: true            
        })

        ConsoleData(Params); 
        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        
                        let Man_dataStore = [];
                        if(res.data[0].Data_store.length != 0){
                            Man_dataStore = res.data[0].Data_store[0];

                        }

                        this.setState({                            
                            data_store : Man_dataStore,
                            data_groups: res.data[0].Data_group,
                            Msg_load: decoder,
                            loading: false,
                            error: false                        
                        })           
                        
                        setTimeout(() => {
                            this.GetDataBiddings();
                        },1000); 
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            data_store : res.data[0].Data_store,
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }

    LoadOptionSelect(props){
        
        //console.log("LoadOptionStatus")
        //console.log(props)

        return props.map(function (CurrentData,i){
            return (
                <option value={CurrentData.cod}> {CurrentData.name} </option>                
            ) 
        })

    }

    render() {
        const { open } = this.state;
        ///Se valida si se muestra la tabla o no
        let man_table;  
        let handle_add ="";      
        if(this.state.data_bidding==undefined || this.state.data_bidding.length == 0 ){
            if(this.state.loading){
                man_table = <Loading />
            }else{
                man_table = <table className="table table-striped" >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Creado</th>
                                        <th>Expira</th>
                                        <th>Tipo</th>
                                        <th>Desc</th>
                                        <th>Status</th>                                        
                                    </tr>                                    
                                </thead>
                                <tbody></tbody>
                            </table>;
            }               

        }else{
            man_table = <Datatable
                    multiSelectOption={false}
                    myData={this.state.data_bidding} 
                    pageSize={10} 
                    pagination={true}
                    class="-striped -highlight"     
                    viewHandler={this.viewHandlerBiddings}                                    
                />;   
        }

        ///Manejo de data_store
        if(this.state.data_store.length !=0){
            handle_add = <button type="button" className="btn btn-primary" onClick={() => this.onOpenModal("R")} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Crear requerimiento</button>;
        }else{
            handle_add = <h3>No puedes crear requerimientos hasta que no configures los datos del perfil</h3>;
            man_table = <div></div>
        }


        ///Manejo loading modal
        let LoadModal;
        if(this.state.loading){
            LoadModal = <Loading />;            
        }else{

            let LoadCategories;
            if(this.state.form.deta.sub_categories.length == 0){
                LoadCategories = <div></div>
            }else{
                LoadCategories = <div style={{margin:20}} >
                                    <table className="table table-striped" >
                                        <thead>
                                            <tr>
                                                <th>Cod</th>
                                                <th>Nombre</th>                                            
                                                <th>Action</th>                                            
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {
                                                //let Data_AddSubcat = { 'cod_subcat' : this.state.tmp.Cod_subcategory, 'name_subcat' : this.state.tmp.Name_subcategory };
                                                this.state.form.deta.sub_categories.map( (CurrentService,i) => {                            
                                                    return (
                                                        <tr key={i} >
                                                            <td >{CurrentService.cod_subcat} </td>
                                                            <td >{CurrentService.name_subcat}</td>                                                        
                                                            <td > 
                                                                <i className="fa fa-trash" style={{ width: 35, fontSize: 20, color: '#e4566e' }} onClick={() => this.handleSubcategories(["D",CurrentService.cod_subcat]) } >
                                                                </i>
                                                            </td>                                                                
                                                        </tr>
                                                    )                                                
                                                })
                                            }
                                        </tbody>
                                    </table>                                
                                </div>;
            }

            let Man_filtros = "";
            if(this.state.form.Type_bidding != "D"){
                Man_filtros =   <>
                                    <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop:20} } >
                                        <h5>Filtros para las ofertas</h5> 
                                    </div>                                    

                                    {/* <div className="col-xl-3 col-md-3"> */}
                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" >Permite parciales</label>                                                                                
                                        <select className="custom-select " value={this.state.form.Partial} onChange={this.handleChange} name="Partial" required >            
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Permite</option>
                                            <option value="0">No permite</option>
                                        </select>
                                    </div>

                                    {/* <div className="col-xl-3 col-md-3"> */}
                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" >Facturación Electrónica</label>                                                                                
                                        <select className="custom-select " value={this.state.form.FactElect} onChange={this.handleChange} name="FactElect" required >            
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Obligatorio</option>
                                            <option value="0">No Obligatorio</option>
                                        </select>
                                    </div>

                                    {/*
                                    
                                    <div className="col-xl-3 col-md-3">
                                        <label htmlFor="recipient-name" className="col-form-label" >Empleados mínimos</label>
                                        <input type="number" className="form-control" name="Employees" value={this.state.form.Employees} onChange={this.handleChange}  />
                                    </div>

                                    <div className="col-xl-3 col-md-3">
                                        <label htmlFor="recipient-name" className="col-form-label" >Experiencia mínima (años)</label>
                                        <input type="number" className="form-control" name="Experience" value={this.state.form.Experience} onChange={this.handleChange}  />                                        
                                    </div>                                    
                                    
                                    
                                    */}
                                    

                                    <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop:20} } >
                                        <h6>Filtro por actividad</h6> 
                                    </div>
                                    
                                    
                                    <div style={ {marginTop:20} } className="col-xl-12 col-md-12 align-self-center text-center" >
                                        <Activity_finder 
                                            HandlerAdd = {this.handlerAddSubcategory} 
                                            DiferentAction = {"B"}/>
                                    </div>                                                                                                                

                                    <div className="col-xl-12 col-md-12">
                                        {LoadCategories}
                                    </div>                                

                                </>    

                                
            }else{
                Man_filtros = "";
            }

            let Man_buttons = "";
            if(this.state.error){
                Man_buttons =   <>                                    
                                    <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal()}>Cerrar</button>
                                </>;
            }else{

                let MsgButton = "";
                if(this.state.Action_form == "M"){
                    MsgButton = "Guardar Cambios";
                }else{
                    MsgButton = "Publicar Requerimiento";
                }

                Man_buttons =   <>
                                    <button type="submit" disabled={ (this.state.form.Status_actual != "A" && this.state.form.Status_bidding.length>0 ) ? true : false} className="btn btn-primary" >{MsgButton}</button>
                                    <button type="button" className="btn btn-secondary" onClick={() => this.onCloseModal()}>Cerrar</button>                
                                </>;
            }

            let DataBiddingCreate = "";
            if(this.state.Action_form == "M"){
                DataBiddingCreate = <>
                                        <div className="col-xl-12 col-md-12" style={ {marginTop:10,marginBottom:10} }>
                                            <button type="button" className="btn btn-success" onClick={() => this.viewBiddingPost(this.state.form)}>Ver Postulaciones</button>                                                            
                                        </div>
                                        
                                        <div className="col-xl-6 col-md-6">
                                            <label htmlFor="recipient-name" className="col-form-label" >Id Requerimiento</label>
                                            <input type="text" className="form-control" value={this.state.form.Cod_bidding} disabled={true} />
                                        </div>

                                        <div className="col-xl-6 col-md-6">
                                            <label htmlFor="recipient-name" className="col-form-label" >Estado del Requerimiento</label>
                                            <select className="custom-select " value={this.state.form.Status_bidding} onChange={this.handleChange} name="Status_bidding" disabled={(this.state.form.Status_bidding != "A" ? true : false )} required>            
                                                <option value="">Seleccione una opción</option>
                                                <option value="A">Activo</option>
                                                <option value="C">Cancelado</option>
                                                <option disabled={true} value="O">Cerrado</option>
                                                <option disabled={true} value="F">Finalizado</option>
                                                <option disabled={true} value="E">Expirado</option>
                                            </select>
                                        </div>

                                        <div className="col-xl-6 col-md-6">
                                            <label htmlFor="recipient-name" className="col-form-label" >Creado</label>
                                            <input type="text" className="form-control" value={this.state.form.Date_create} disabled={true} />
                                        </div>

                                        <div className="col-xl-6 col-md-6">
                                            <label htmlFor="recipient-name" className="col-form-label" >Expira</label>
                                            <input type="text" className="form-control" value={this.state.form.Date_expire} disabled={true} />
                                        </div>

                                        <div style={ {marginTop:20} } className="col-xl-12 col-md-12">                                            
                                            <h4>
                                                { (this.state.form.Adjunto!= undefined && this.state.form.Adjunto.length>0) ? <a href={icreappconfig.url_platform+this.state.form.Adjunto} target="_blank" >Ver Archivo adjunto</a> : ""} 
                                            </h4>
                                        </div>

                                    </>;

            }

            LoadModal = <form onSubmit={this.handleSubmit}>
                            <div className="modal-body">        
                                <div className="row">

                                    <div className="col-xl-12 col-md-12 align-self-center text-center" >
                                        <h4>Datos del Requerimiento</h4> 
                                    </div>                                    

                                    {DataBiddingCreate}

                                    <div className="col-xl-12 col-md-12">
                                        <label htmlFor="recipient-name" className="col-form-label" >Descripción del Requerimiento</label>
                                        <textarea className="form-control" name="Desc" rows="3" value={this.state.form.Desc} onChange={this.handleChange} required></textarea>
                                    </div> 

                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" >Solicitud</label>                                                                                
                                        <select className="custom-select " value={this.state.form.Type_bidding} onChange={this.handleChange} name="Type_bidding" disabled={(this.state.Action_form == "M" ? true : false )} required>            
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Pública</option>
                                            <option value="2">Directa</option>
                                        </select>
                                    </div>                                    

                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" >Comercio Seleccionado</label>
                                        {/*
                                            <input type="text" className="form-control" name="ComercioSel" value={this.state.form.ComercioSel} onChange={this.handleChange} disabled={ (this.state.form.Type_bidding != "D" || this.state.Action_form == "M") ? true : false} required={this.state.form.Type_bidding =="D" ? true : false} />
                                        */}

                                        <select className="custom-select " value={this.state.form.ComercioSel} onChange={this.handleChange} name="ComercioSel" disabled={ (this.state.form.Type_bidding != "2" || this.state.Action_form == "M" ) ? true : false} required={this.state.form.Type_bidding =="2" ? true : false}>            
                                            {this.state.data_aliado==[]? <option value=""></option> : this.LoadOptionSelect(this.state.data_aliado) }                                                                                        
                                        </select>


                                    </div>

                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" >Expiración del Requerimiento  "días"</label>
                                        <input type="number" className="form-control" min={1} name="Expire" disabled={(this.state.Action_form == "M" ? true : false )} value={this.state.form.Expire} onChange={this.handleChange} required/>
                                    </div>

                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" >Tiempo máximo entrega "días"</label>
                                        <input type="number" className="form-control" min={1} name="Delivery" value={this.state.form.Delivery} onChange={this.handleChange} required/>
                                    </div>

                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" >Cantidad Ítems "Si no aplica dejar 0"</label>
                                        <input type="number" className="form-control" min={0} name="Items" value={this.state.form.Items} onChange={this.handleChange} required/>
                                    </div>

                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="recipient-name" className="col-form-label" >Valor máximo $ "Si no aplica dejar 0"</label>
                                        <input type="number" className="form-control" min={0} name="Valmax" value={this.state.form.Valmax} onChange={this.handleChange} required/>
                                    </div>

                                    <div className="col-xl-12 col-md-12">
                                        <label htmlFor="recipient-name" className="col-form-label" >Políticas de pago</label>
                                        <input type="text" className="form-control" name="PolPag" placeholder="Ingrese una descripción relacionada, ejemplo 'Pago a 30 días' " value={this.state.form.PolPag} onChange={this.handleChange} required/>
                                    </div>

                                    <div className="col-xl-12 col-md-12">
                                        <label htmlFor="recipient-name" className="col-form-label" >{ this.state.Action_form=="M" ? "Cambiar archivo adjunto" : "Subir Requerimiento Adjunto" }</label>
                                        {
                                            /*                                            
                                            <input type="file" className="form-control" onChange={this.handleChangeFile} required={this.state.Action_form=="M" ? false : true}/>    
                                            */
                                        }
                                        <input type="file" className="form-control" onChange={this.handleChangeFile} />
                                    </div>                                    
                                    
                                                                        
                                    <div style={ {marginTop:10} } className="col-xl-12 col-md-12 align-self-center text-center" >                                        
                                        <Handler_bidding_deta 
                                            Type = {"Made"}
                                            Data={ this.state.form.deta.deta_bidding }
                                            HandlerData = {this.handlerAddDetaBidding}/>                                        
                                    </div>                                                                                                                                                                                        
                                    

                                    {Man_filtros}                                                                                                            

                                </div>                                          
                            </div>
                            <div className="modal-footer">
                                {Man_buttons}
                            </div>
                        </form>;    
        }

        let TitleModal = "";
        if(this.state.Action_form == "M"){
            TitleModal = "Modificar Requerimiento";
        }else{
            TitleModal = "Adicionar Requerimiento";
        }

        if(this.state.fase_app==1){
            return(
                <Fragment>
                    <Breadcrumb title="Requerimientos Realizados" parent="Comprar PYS" />
                    {/* <!-- Container-fluid starts--> */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">                                
                                    <div className="card-body">
                                        <div className="btn-popup pull-right">                                            
                                        </div>
                                        <div className="clearfix"></div>
                                        <div id="basicScenario" className="product-physical">                                        
                                            <h5>Actualmente nos encontramos en la fase 1 de inscripción de Empresas y comercios. En el mes de julio habilitaremos todas nuestras opciones. En MosquerApp encontrarás todo lo que necesitas.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Container-fluid Ends--> */}
                    <ToastContainer />
                </Fragment>
            );

        }

        return (
            <Fragment>
                <Breadcrumb title="Requerimientos Realizados" parent="Comprar PYS" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">
                                    <div className="btn-popup pull-right">

                                        {handle_add}
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{TitleModal}</h5>
                                            </div>
                                            {LoadModal}
                                        </Modal>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">                                        
                                        {man_table}                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }
}

export default Bidding_made
import React, { Component,Fragment } from 'react';
import {Search,Plus}  from 'react-feather';
import Loading from '../Loading'
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import ConsoleData from '../../hooks/ConsoleData'
import DecoderData from '../../hooks/DecoderData'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Switch } from 'react-router';

export class Handler_bidding_deta extends Component {   

    constructor(props){
        super(props)
        
        //this.GetDetaServices = this.GetDetaServices.bind(this);        

        ConsoleData("Ingresa a Handler_bidding_deta");
        ConsoleData(props);
        

        this.state = {            
            Data: props.Data,
            HandlerData : props.HandlerData,
            Type_form : props.Type,
            //Action: "D", //Se comentarea para agregar nueva funcionalidad
            ///Action: DiferentAction,
            loading: false,
            error: false,            
            Msg_Error: '',
            Unit: "",
            Quantity: "",
            Desc: "",
            Search: ""            
        }        
        
    }

    componentDidMount(){
        //this.GetDetaServices();
    }


    handlerChange = e => {        
        this.setState({            
            [e.target.name]: e.target.value
        })                   
    }

    handlerChangeDeta = (e, DataDeta) => {        
        /*
        ConsoleData("handlerChangeDeta");
        ConsoleData(e.target.name);        
        ConsoleData(e.target.value);        
        ConsoleData(DataDeta);        
        */

        let Data_AddDeta=[];
        let Data_ActAddDeta=this.state.Data;
        let Tam_deta=this.state.Data.length;
        

        for(let _i=0; _i<Tam_deta; _i++ ){            
            let Data_Add = Data_ActAddDeta[_i];

            if(Data_ActAddDeta[_i].Desc == DataDeta.Desc){
                
                if(e.target.name=="ValUnit"){
                    Data_Add = { 'Quantity' : Data_ActAddDeta[_i].Quantity, 'Unit' : Data_ActAddDeta[_i].Unit, 'Desc' : Data_ActAddDeta[_i].Desc, 'ValUnit' : e.target.value, 'Desc2' : Data_ActAddDeta[_i].Desc2  };
                }else if(e.target.name=="Desc2"){                    
                    Data_Add = { 'Quantity' : Data_ActAddDeta[_i].Quantity, 'Unit' : Data_ActAddDeta[_i].Unit, 'Desc' : Data_ActAddDeta[_i].Desc, 'ValUnit' : Data_ActAddDeta[_i].ValUnit, 'Desc2' : e.target.value  };
                }                
            }

            Data_AddDeta.push(Data_Add); 
        }

        this.setState({
            Data: Data_AddDeta            
        })

        this.state.HandlerData(Data_AddDeta);



    }


    handlerAddDeta = e => {                
        
        ConsoleData("Ingreso handlerAddDeta");   
        ConsoleData(this.state);
        let Data_AddDeta=[];


        if(this.state.Unit.length < 1 || this.state.Desc.length < 1 || this.state.Quantity.length < 1 ){
            toast.error( "Debes digitar todos los campos para poder agregar el Ítem" );
            return;                        
        }

        if(this.state.Data.length>0){
            ConsoleData("Ingreso handlerAddDeta if");   
            Data_AddDeta=this.state.Data;
            let Tam_deta=this.state.Data.length;

            ConsoleData(Data_AddDeta);   

            for(let _i=0; _i<Tam_deta; _i++ ){
                if(Data_AddDeta[_i].Desc == this.state.Desc){
                    toast.error( "No puedes agregar dos veces la misma descripción. ");
                    return;
                }
            }

            let Data_Add = { 'Quantity' : this.state.Quantity, 'Unit' : this.state.Unit, 'Desc' : this.state.Desc, 'ValUnit' : 0, 'Desc2' : ""  };
            Data_AddDeta.push(Data_Add) ;

            ConsoleData(Data_AddDeta);    

        }else{
            ConsoleData("Ingreso handlerAddDeta else");   
            Data_AddDeta = [{ 'Quantity' : this.state.Quantity, 'Unit' : this.state.Unit, 'Desc' : this.state.Desc, 'ValUnit' : 0, 'Desc2' : "" }];
            ConsoleData(Data_AddDeta);        
        }

        toast.success( "Se agrego correctamente el Ítem al detalle.");
        this.state.HandlerData(Data_AddDeta);
        this.setState({
            Data: Data_AddDeta,
            Quantity: 0,
            Unit:"",
            Desc:""                
        })
        

    }

    handlerDeleteDeta = e => {
        
        ConsoleData("Ingreso handlerDeleteDeta");   
        ConsoleData(e);
        
        let Data_AddDeta=[];
        let Name_delete = e.Desc;

        let Data_tmpDeta=this.state.Data;
        let Tam_deta=this.state.Data.length;

        for(let _i=0; _i<Tam_deta; _i++ ){
            if(Data_tmpDeta[_i].Desc != e.Desc){
                Data_AddDeta.push(Data_tmpDeta[_i]); 
            }
        }    

        if (window.confirm('¿Esta seguro de eliminar el Ítem: "'+Name_delete+'"?')) {
            this.setState({
                Data: Data_AddDeta            
            })

            this.state.HandlerData(Data_AddDeta);
        }
        
        
    }


    render() {      
        
        let TableResult= <div></div>;
        let FormDeta = <div></div>;
        let TotalValue = 0;

        if(this.state.loading){
            TableResult=<Loading msg="Cargando..." />;
        }else{
            ConsoleData("Ingreso a Data");
            if(this.state.Data != undefined && this.state.Data.length>0){
                
                ConsoleData(this.state.Type_form);
                switch(this.state.Type_form){
                    case "Made":
                        TableResult=<div >                    
                                        <table className="table table-sm table-hover table-bordered" style={ {marginTop:10,paddingLeft:20,paddingRight:20}  } >
                                            <thead>
                                                <tr>                                            
                                                    <th>Cantidad</th>
                                                    <th>Medida</th>
                                                    <th>Descripción</th>                                                            
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>  
                                                {                                        
                                                    this.state.Data.map( (CurrentDetaService,i) => {                            
                                                        return (
                                                            <tr key={i} >                                                        
                                                                <td >{CurrentDetaService.Quantity}</td>                                            
                                                                <td>
                                                                    <select className="custom-select" value={CurrentDetaService.Unit} disabled>
                                                                        <option value="">Seleccione una medida</option>
                                                                        <option value="un">Unidades</option>
                                                                        <option value="mt">Metros</option>
                                                                        <option value="kg">Kilogramos</option>                        
                                                                        <option value="lt">Litros</option>                                               
                                                                        <option value="ot">Otros</option>                                       
                                                                    </select>    
                                                                </td>                                       
                                                                <td >{CurrentDetaService.Desc}</td>                                        
                                                                <td >
                                                                    <span>
                                                                        <i 
                                                                            className="fa fa-trash" 
                                                                            style={{ width: 35, fontSize: 20, padding: 11,color:'#e4566e' }} 
                                                                            onClick={() => {this.handlerDeleteDeta(CurrentDetaService)} }/>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                        
                                                    })                                            
                                                }                    
                                            </tbody>
                                        </table>                                  
                                    </div>;
                    break;
                    case "ViewMade":
                        TableResult=<div >                    
                                        <table className="table table-sm table-hover table-bordered" style={ {marginTop:10,paddingLeft:20,paddingRight:20}  } >
                                            <thead>
                                                <tr>                                            
                                                    <th>Cantidad</th>
                                                    <th>Medida</th>
                                                    <th>Descripción</th>                                                                                                                
                                                </tr>
                                            </thead>
                                            <tbody>  
                                                {                                        
                                                    this.state.Data.map( (CurrentDetaService,i) => {                            
                                                        return (
                                                            <tr key={i} >                                                        
                                                                <td >{CurrentDetaService.Quantity}</td>                                            
                                                                <td>
                                                                    <select className="custom-select" value={CurrentDetaService.Unit} disabled>
                                                                        <option value="">Seleccione una medida</option>
                                                                        <option value="un">Unidades</option>
                                                                        <option value="mt">Metros</option>
                                                                        <option value="kg">Kilogramos</option>                        
                                                                        <option value="lt">Litros</option>                                               
                                                                        <option value="ot">Otros</option>                                       
                                                                    </select>    
                                                                </td>                                      
                                                                <td >{CurrentDetaService.Desc}</td>                                                                                                        
                                                            </tr>
                                                        )
                                                        
                                                    })                                            
                                                }                    
                                            </tbody>
                                        </table>                                  
                                    </div>;
                    break;
                    case "Postulation":
                        this.state.Data.map( (CurrentDetaService,i) => { 
                            TotalValue = TotalValue + (CurrentDetaService.Quantity*CurrentDetaService.ValUnit);
                        });
        
                        TableResult=<div >                    
                                        <table className="table table-sm table-hover table-bordered" style={ {marginTop:10,paddingLeft:20,paddingRight:20}  } >
                                            <thead>
                                                <tr>                                                                                        
                                                    <th>Cantidad</th>
                                                    <th>Medida</th>
                                                    <th>Descripción</th>                                                            
                                                    <th>Valor Unitario</th>   
                                                    <th>Descripción Adicional</th>   
                                                    <th>Valor Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>  
                                                {                                        
                                                    this.state.Data.map( (CurrentDetaService,i) => {                                                                                                                            
                                                        return (
                                                            <tr key={i} >                                                        
                                                                <td>{CurrentDetaService.Quantity}</td>                                            
                                                                <td>
                                                                    <select className="custom-select" value={CurrentDetaService.Unit} disabled>
                                                                        <option value="">Seleccione una medida</option>
                                                                        <option value="un">Unidades</option>
                                                                        <option value="mt">Metros</option>
                                                                        <option value="kg">Kilogramos</option>                        
                                                                        <option value="lt">Litros</option>                                               
                                                                        <option value="ot">Otros</option>                                       
                                                                    </select>    
                                                                </td>                                        
                                                                <td>{CurrentDetaService.Desc}</td>   
                                                                <td> 
                                                                    <input type="number" className="form-control" name="ValUnit" min={0} value={CurrentDetaService.ValUnit} onChange={(e)=>{this.handlerChangeDeta(e,CurrentDetaService)}} placeholder="Ingrese el valor unitario"/>                    
                                                                </td>   
                                                                <td > 
                                                                    <input type="text" className="form-control" name="Desc2" value={CurrentDetaService.Desc2} onChange={(e)=>{this.handlerChangeDeta(e,CurrentDetaService)}} placeholder="Ingrese una Descripción Adicional" />                    
                                                                </td>   
                                                                <td>{ DecoderData({Data: (CurrentDetaService.Quantity*CurrentDetaService.ValUnit) , Type: "C"})   }</td>   
                                                            </tr>
                                                        )
                                                        
                                                    })                                            
                                                }  
        
                                                <tr> 
                                                    <td colSpan={5} align="right" ><strong>Total:</strong></td>                                            
                                                    <td> <strong>{ DecoderData({Data: TotalValue , Type: "C"}) }</strong> </td>
                                                </tr>  
        
                                            </tbody>
                                        </table>                                  
                                    </div>;
                    break;
                    case "ViewPostulation":
                        this.state.Data.map( (CurrentDetaService,i) => { 
                            TotalValue = TotalValue + (CurrentDetaService.Quantity*CurrentDetaService.ValUnit);
                        });
        
                        TableResult=<div >                    
                                        <table className="table table-sm table-hover table-bordered" style={ {marginTop:10,paddingLeft:20,paddingRight:20}  } >
                                            <thead>
                                                <tr>                                                                                        
                                                    <th>Cantidad</th>
                                                    <th>Medida</th>
                                                    <th>Descripción</th>                                                            
                                                    <th>Valor Unitario</th>   
                                                    <th>Descripción Adicional</th>   
                                                    <th>Valor Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>  
                                                {                                        
                                                    this.state.Data.map( (CurrentDetaService,i) => {                                                                                                                            
                                                        return (
                                                            <tr key={i} >                                                        
                                                                <td>{CurrentDetaService.Quantity}</td>                                            
                                                                <td>
                                                                    <select className="custom-select" value={CurrentDetaService.Unit} disabled>
                                                                        <option value="">Seleccione una medida</option>
                                                                        <option value="un">Unidades</option>
                                                                        <option value="mt">Metros</option>
                                                                        <option value="kg">Kilogramos</option>                        
                                                                        <option value="lt">Litros</option>                                               
                                                                        <option value="ot">Otros</option>                                       
                                                                    </select>    
                                                                </td>                                        
                                                                <td>{CurrentDetaService.Desc}</td>   
                                                                <td> 
                                                                    {DecoderData({Data: (CurrentDetaService.ValUnit) , Type: "C"})  }
                                                                </td>   
                                                                <td > 
                                                                    {CurrentDetaService.Desc2}
                                                                </td>   
                                                                <td>{ DecoderData({Data: (CurrentDetaService.Quantity*CurrentDetaService.ValUnit) , Type: "C"})   }</td>   
                                                            </tr>
                                                        )
                                                        
                                                    })                                            
                                                }  
        
                                                <tr> 
                                                    <td colSpan={5} align="right" ><strong>Total:</strong></td>                                            
                                                    <td> <strong>{ DecoderData({Data: TotalValue , Type: "C"}) }</strong> </td>
                                                </tr>  
        
                                            </tbody>
                                        </table>                                  
                                    </div>;
                    break;

                }
                
            }else{

                ConsoleData("else");
                TableResult=<div >                    
                                <table className="table table-sm table-hover table-bordered" style={ {marginTop:10,paddingLeft:20,paddingRight:20}  } >
                                    <thead>
                                        <tr>
                                            <th>Cantidad</th>
                                            <th>Medida</th>
                                            <th>Descripción</th>                                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="3" >No se ha agregado ningún ítem</td>                                        
                                        </tr>
                                    </tbody>
                                </table>                                  
                            </div>;

            }

        }


        switch(this.state.Type_form){
            case "Made":
                FormDeta = <>
                            <div className="col-xl-12 col-md-12" >                    
                                <label htmlFor="recipient-name" className="col-form-label" >Crear detalle del requerimiento</label>                    
                            </div>
                            <div className="col-xl-2 col-md-2" >                    
                                <label htmlFor="recipient-name" className="col-form-label" >Cantidad</label>
                                <input type="number" className="form-control" name="Quantity" onChange={this.handlerChange} placeholder="Ingrese la cantidad" value={this.state.Quantity} />                    
                            </div>
                            <div className="col-xl-3 col-md-3" >                    
                                <label htmlFor="recipient-name" className="col-form-label" >Tipo de Medida</label>
                                <select className="custom-select" value={this.state.Unit} onChange={this.handlerChange} name="Unit" >
                                    <option value="">Seleccione una medida</option>
                                    <option value="un">Unidades</option>
                                    <option value="mt">Metros</option>
                                    <option value="kg">Kilogramos</option>                        
                                    <option value="lt">Litros</option>                                               
                                    <option value="ot">Otros</option>                                       
                                </select>
                            </div>


                            <div className="col-xl-7 col-md-7" >                    
                                <label htmlFor="recipient-name" className="col-form-label" >Descripción</label>
                                <input type="text" className="form-control" name="Desc" onChange={this.handlerChange} placeholder="Digite una descripción" value={this.state.Desc}/>                                                                    
                            </div>       


                            
                            <div className="col-xl-12 col-md-12">
                                <button className="btn btn-warning btn-sm" type="button" onClick={this.handlerAddDeta} style={{margin:10}} >
                                    Agregar Ítem                        
                                </button>                
                            </div>            
                        </>
            break;
            default:
                FormDeta = <div className="col-xl-12 col-md-12" >                    
                            <label htmlFor="recipient-name" className="col-form-label" >Detalle del requerimiento</label>                    
                        </div>
        
        }
        

        return(

            <div className="row">       

                {FormDeta}

                <div className="col-xl-12 col-md-12" >
                    {TableResult}
                </div>

            </div>


        );

    }


}

export default Handler_bidding_deta;
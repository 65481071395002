import React, { Component,Fragment } from 'react';
import Loading from '../Loading'
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import ConsoleData from '../../hooks/ConsoleData'

export class ManualForm_services extends Component {    
        
    constructor(props){
        super(props)        

        //console.log("Form_services");
        //console.log(props.DataService);

        this.state = {
            onHide: props.DataService.onHide,
            RefreshData : props.DataService.RefreshData,
            UpdateData: props.UpdateData,            
            Action: "P",
            form: {
                Name: '',
                Phone: '',
                Address: '',
                Email: '',
                Veh_type: '',
                Serie: '',
                City: '',
                Descripcion: '',
                Agenda_date: '',
                Agenda_time: '',
                Pay_type: '',
                Total_cost: ''
            },               
            loading: true,
            error: null            
        }        

        this.GetStatusServices = this.GetStatusServices.bind(this);
        this.GetDataSession = this.GetDataSession.bind(this);

    }

    componentDidMount(){
        this.GetStatusServices();
        ///Se traen datos de sesion
        this.GetDataSession("IcreapSessionData");        
    }    

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

        }
    }


    GetStatusServices = () => {
        const Urlquery = icreappconfig.url_controller+"LoadStatusServices.php";
        //console.log(Urlquery);        

        const Params = {
            Action : this.state.Action,            
            Cod_agenda : this.state.Cod_Agenda            
        }

        //console.log(Params); 

        axios.post(Urlquery,Params)
             .then(res => {

                //console.log("FormService query");   
                //console.log(res.data);   
                
                if(res.data[0].Status){                                         

                    this.setState({
                        Veh_Type: res.data[0].Veh_Type,
                        Payment_Type: res.data[0].Payment,                        
                        form: {
                            Name: '',
                            Phone: '',
                            Email: '',
                            Address: '',
                            Veh_type: '',
                            Serie: '',
                            City: '',
                            Descripcion: '',
                            Agenda_date: '',
                            Agenda_time: '',
                            Payment_Type: '',
                            Total_cost: ''
                        },
                        loading: false,
                        error: false                        
                    })                    
                }else{                    
                }
                
             })
             .catch(err => {
                    console.error(err);
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
            });
    }

    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        })
        
    }

    handleSubmit = async e => {
        //console.log("Submit")
        ConsoleData("Submit");        

        e.preventDefault() 
        this.setState({
            loading: true
        })

        try {
            
            //console.log(this.state.form);
            ConsoleData(this.state.form);
                    
            this.setState({
                loading: false
            })            

           const Urlquery = icreappconfig.url_controller+"ChangeStatusServices.php";
           //console.log(Urlquery);        
           ConsoleData(Urlquery);
   
           const Params = {
                Action : "M",
                User_email : this.state.User_email,
                User_type : this.state.User_type,
                Form : this.state.form
           }
   
           //console.log(Params); 
           ConsoleData(Params);
   
           axios.post(Urlquery,Params)
                .then(res => {
                   //console.log(res.data);   
                   ConsoleData(res.data);
                   
                   if(res.data[0].Status){
                        this.setState({
                            loading: false,
                            error: false
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio
                        alert(res.data[0].Msg);  
                        this.state.RefreshData();
                        this.state.onHide()            

                   }else{  
                    alert(res.data[0].Msg);                        
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: res.data[0].Msg
                        })
                    
                   }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            ////this.props.history.push('/exercise')

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        //console.log(this.state)
    }

    LoadOption(props){
        
        //console.log("LoadOptionStatus")
        //console.log(props)

        return props.map(function (CurrentData,i){
            return (
                <option value={CurrentData.cod_type}> {CurrentData.name_type} </option>                
            ) 
        })

    }

    LoadDelivery(props){
        return props.map(function (CurrentData,i){
            return (
                <option value={CurrentData.cod_delivery}> {CurrentData.alias+" - "+CurrentData.name_delivery} </option>                
            ) 
        })
    }



    render(){                

        //console.log(this.state.DataStatus);
        ConsoleData(this.state.DataStatus);

        if(this.state.loading)
            return <Loading msg="Grabando..." />

        return(
            <div className="border border-warning" style={ {marginLeft: 20, marginRight: 20, marginTop: 20, padding: 20 } }>                                           
                
                <h4 className="align-self-center text-center"> <strong>Datos del Servicio</strong> </h4>

                <form onSubmit={this.handleSubmit} style={ { marginTop: 10 } }>

                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span> Nombre del cliente</label>
                        <input className="form-control col-xl-8 col-md-7" id="Name" name="Name" type="text" required value={this.state.form.Name} onChange={this.handleChange}  />
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span> Telefono</label>
                        <input className="form-control col-xl-8 col-md-7" id="Phone" name="Phone" type="tel" required value={this.state.form.Phone} onChange={this.handleChange} />
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span>Email</label>
                        <input className="form-control col-xl-8 col-md-7" id="Email" name="Email" type="email" value={this.state.form.email} onChange={this.handleChange} />                        
                    </div>                    

                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span>Direccion del servicio</label>
                        <input className="form-control col-xl-8 col-md-7" id="Address" name="Address" type="text" required value={this.state.form.Address} onChange={this.handleChange}/>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span>Ciudad o Municipio</label>
                        <input className="form-control col-xl-8 col-md-7" id="City" name="City" type="text" required value={this.state.form.City} onChange={this.handleChange}/>
                    </div>
                    
                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span>Tipo de vehiculo</label>
                        <select className="custom-select custom-select-sm col-xl-8 col-md-7" required value={this.state.form.Veh_type} onChange={this.handleChange} name="Veh_type" id="Veh_type" style={ { marginLeft: 10 } }>            
                            <option value="">Seleccionar Tipo:</option>
                            {this.LoadOption(this.state.Veh_Type)}                                                                    
                        </select>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span>Serie o Placa</label>
                        <input className="form-control col-xl-8 col-md-7" id="Serie" name="Serie" type="text" required value={this.state.form.Serie} onChange={this.handleChange}/>
                    </div>
                    
                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span>Descripcion del servicio</label>
                        <input className="form-control col-xl-8 col-md-7" id="Descripcion" name="Descripcion" type="text" required value={this.state.form.Descripcion} onChange={this.handleChange}/>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span>Dia del servicio</label>
                        <input className="form-control col-xl-8 col-md-7" id="Agenda_date" name="Agenda_date" type="date" required value={this.state.form.Agenda_date} onChange={this.handleChange}/>
                    </div>
                    
                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span>Hora del servicio "HH:MM AM/PM"</label>
                        <input className="form-control col-xl-8 col-md-7" id="Agenda_time" name="Agenda_time" type="time" value={this.state.form.Agenda_time} onChange={this.handleChange}/>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span>Metodo de Pago</label>
                        <select className="custom-select custom-select-sm col-xl-8 col-md-7" required value={this.state.form.Payment_Type} onChange={this.handleChange} name="Payment_Type" id="Payment_Type" style={ { marginLeft: 10 } }>            
                            <option value="">Seleccionar Tipo:</option>
                            {this.LoadOption(this.state.Payment_Type)}                                                                    
                        </select>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"><span>*</span>Costo total del servicio</label>
                        <input className="form-control col-xl-8 col-md-7" id="Total_cost" name="Total_cost" type="number" required value={this.state.form.Total_cost} onChange={this.handleChange}/>
                    </div>
                    
                    <div className="form-row align-self-center text-center" >                        
                        <button type="submit" className="btn btn-primary" > Registrar Servicio </button>                        
                    </div>    
                </form>
                                
            </div>
        )


    }

}

export default ManualForm_services
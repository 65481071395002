import React, { Component } from 'react'
import { Tabs } from 'react-tabs';
import ConsoleData from '../../hooks/ConsoleData'

export class Logout extends Component {

    constructor(props){
        super(props)

    }

    componentWillMount(){
        this.DeleteDataSession("IcreapSessionData");
    }

    DeleteDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        if (SessionDataValue !== null) {
            sessionStorage.removeItem(key);  
        }else{
            //console.log("No se encontro session iniciada");
            ConsoleData("No se encontro session iniciada");            
        }

        this.routeChange();
    }

    routeChange = () => {
        this.props.history.push(`${process.env.PUBLIC_URL}/`);
    } 

    render(){
        return (
            <div>
                <Tabs>

                </Tabs>
            </div>
        )
    }
}

export default Logout
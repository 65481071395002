import React, { Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal';
import Datatable from '../common/datatable';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import Activity_finder from '../specific/activity_finder'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConsoleData from '../../hooks/ConsoleData';

export class List_category_directory_store extends Component {

    constructor(props) {
        super(props);        

        this.state = {
            Action: "L",
            Action_form: "P",
            open: false,
            data : [],
            data_category_directory : [],        
            data_filter_directory : [],             
            change_table : false,      
            data_store_directory : {},  
            data_tmp_directory : {},  
            file: null,
            file_name: null, 
            Max_size: 5*1000000, //Se maneja máximo de 5MB 
            Txt_search: "",
            Msg_load: "",
            User_email: "",
            User_type: "",                        
            loading: true,            
            error: false            
        };
    }

    componentDidMount(){        
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataSubCategories();
            this.GetDataSession("IcreapSessionData");        
        }        
    }
    
    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            this.GetDataCategoryDirectoryStore();
        }        
    }

    handlerAddRegisterModal = () => {
        this.setState({                      
            Action_form: "E",
        });

        this.onOpenModal();
    }

    onOpenModal = (e) => {
        ConsoleData("onOpenModal");
        this.setState({             
            open: true            
        });
    };

    onCloseModal = () => {     
        ConsoleData("onCloseModal");

        this.setState({
            open: false,
            data_store_directory : {},   
            data_tmp_directory : {},  
            file: null,
            file_name: null,            
            Action_form: "P",
        })  
    };    

    GetDataCategoryDirectoryStore = () => {
        const Urlquery = icreappconfig.url_controller+"AdminDirectoryStores.php";
        //console.log(Urlquery);   
        ConsoleData(Urlquery);            

        const formData = new FormData();  
        formData.append("Action","C");

        //console.log(formData);     
        ConsoleData("form data");           
        ConsoleData(formData); 
        

        ///Se reinician estados para control de datos
        this.setState({
            data_category_directory: [],
            loading: true            
        })           

        try{
            axios.post(Urlquery,formData)
                .then(res => {
                    //console.log(res.data);   
                    ConsoleData(res.data);       
                    
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        
                        let Man_dataStore = [];
                        if(res.data[0].Data.length != 0){
                            
                            res.data[0].Data.map( (CurrentService,i) => {                            
                            
                                //console.log(CurrentService);
                                //ConsoleData(CurrentService);     
                                    
    
                                Man_dataStore[i] = {                            
                                    id: CurrentService.id,
                                    Nombre: CurrentService.name_category,
                                    Imagen: CurrentService.name_logo,                                    
                                }                                                
                                
                            })                            

                        }                        


                        this.setState({     
                            data : res.data[0].Data != undefined ? res.data[0].Data : [] ,                       
                            data_category_directory : Man_dataStore, 
                            data_filter_directory : Man_dataStore,            
                            loading : false,                           
                            Msg_load: decoder,                            
                            error: false                        
                        })           
                                                
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            data : [] ,                       
                            data_category_directory : res.data[0].Data,
                            data_filter_directory : res.data[0].Data,            
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                                    
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }

    handleChangeFilter = e => {
        ConsoleData(`${e.target.name}: ${e.target.value}`)
        //ConsoleData(this.state.data_filter_directory);

        ///Se vuelve a generar array de datos aplicando el filtro
        const TxtData_filter = e.target.value;
        let new_data_directory = [];
        if(TxtData_filter==""){
            new_data_directory = this.state.data_category_directory;
        }else{
            this.state.data_category_directory.forEach(function(dataitem) {
                
                const Name = dataitem.Nombre;
                const Image = dataitem.Imagen;                
                let AddItem = false;

                //Se valida la categoria
                if(Name.includes(TxtData_filter) || Name.toLowerCase().includes(TxtData_filter.toLowerCase())   ){
                    AddItem=true;
                }

                //Se valida la imagen
                if(Image.includes(TxtData_filter) || Image.toLowerCase().includes(TxtData_filter.toLowerCase() ) ){
                    AddItem=true;
                }
                

                //Se agrega item si se encuentra resultado
                if(AddItem){
                    new_data_directory.push(dataitem);     
                    //ConsoleData(Status);               
                }

              })
        }
                
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            [e.target.name]: e.target.value,
            change_table : true,
            data_filter_directory: new_data_directory
        })
        

        //ConsoleData(new_data_directory);
                
    }

    handleChangeModal = e => {
        ConsoleData("handleChangeModal");

        ConsoleData(`${e.target.name}: ${e.target.value}`);
        //ConsoleData(Object.entries(this.state.data_tmp_directory).length);
    
        this.setState({
            data_tmp_directory:{
                ...this.state.data_tmp_directory,
                [e.target.name]: e.target.value
            } 
        })
    
    }

    handleChangeFileModal = e => {
        ConsoleData(e.target.files[0]);

        ///Se valida el tamaño máximo de los archivos
        if(e.target.files[0].size > this.state.Max_size ){
            this.setState({       
                file: null,    
                file_name: "",    
                error: true,
                Msg_error: "El archivo no puede superar el tamaño de "+(this.state.Max_size/1000000)+" MB"
            }) ;

            toast.error("El archivo no puede superar el tamaño de "+(this.state.Max_size/1000000)+" MB");
        }else{
            this.setState({
                file : e.target.files[0],
                file_name: e.target.value,    
                loading: false,                
                error: false,
                Msg_error: ""                
            });
        }
    
    }

    viewHandlerDirectoryStore = (e) => {
        ConsoleData("viewHandlerDirectoryStore");
        ConsoleData(e);
        ConsoleData(e.datos.id);

        let DataDirectoryStore = [];
        let id_category = 0;

        ///Se busca el registro para tomar los datos de la consulta ya generada
        this.state.data.map( (CurrentData,i) => {               

            if(CurrentData.id==e.datos.id){
                id_category = CurrentData.id;
                DataDirectoryStore.push(CurrentData);
            }

        });
        /////////////////////////

        this.setState({
            data_store_directory : DataDirectoryStore,
            data_tmp_directory : {
                id: id_category
            },  
        })  
        
        this.onOpenModal();
    }


    handleSubmit = async e => {
        
        try {

            ConsoleData("Submit")
            e.preventDefault();                            
            ConsoleData("Paso validaciones");

            const Urlquery = icreappconfig.url_controller+"AdminDirectoryStores.php";
            ConsoleData(Urlquery);        

            const formData = new FormData();   

            if(this.state.file == null && this.state.Action_form=="E"){
                toast.error("Se debe agregar una imagen correspondiente a la categoría." );
                return false;
            }

            formData.append("Logo",this.state.file);         
            formData.append('Action',this.state.Action_form);    
            formData.append('User_email',this.state.User_email);    
            formData.append('User_type',this.state.User_type);    
            formData.append('form',JSON.stringify(this.state.data_tmp_directory)); 

            ConsoleData(this.state.Action_form);
            ConsoleData(this.state.data_tmp_directory);                                    
                                            

            //console.log(formData);     
            ConsoleData("form data");           
            ConsoleData(formData);                                   

            this.setState({                            
                loading: true,
                error: false,
                Msg_error: ""                        
            });        
            
            axios.post(Urlquery, formData)            
                .then(res => {
                    ConsoleData(res.data);   
                   
                    if(res.data[0].Status){

                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       
                        
                        this.setState({
                            loading: false
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio                        
                        toast.success(decoder);                                                
                        this.onCloseModal();        
                        this.GetDataCategoryDirectoryStore();
                    }else{  
                        //alert(res.data[0].Msg); 
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 

                        toast.error( decoder );
                        this.setState({
                            loading: false,
                            error: false,
                            Msg_Error: res.data[0].Msg
                        })
                        
                   }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            ////this.props.history.push('/exercise')

        } catch (error) {

            ConsoleData("Ingreso a Catch");
            ConsoleData(error);

            this.setState({
                loading: false,
                error
            }) 
        }
        

    }

    handlerViewModal = () => {  

        const onCloseModal = this.onCloseModal;     
        const data_store_directory = this.state.data_store_directory;
        const handleSubmit = this.handleSubmit;        
        const handleChangeFileModal = this.handleChangeFileModal;
        const handleChangeModal = this.handleChangeModal;
        let data_tmp_directory = this.state.data_tmp_directory;
        let file_data = this.state.file;

        if(data_store_directory != undefined && data_store_directory.length>0){

            return data_store_directory.map(function (CurrentData,i){
                ConsoleData("map data_store_directory");
                ConsoleData(CurrentData);

                return (<form key={i} onSubmit={handleSubmit}>
                    <div className="modal-body" >                                                                                        
                        
                        <div className="row" style={{margin:10}}>                                                               

                            <div className="col-xl-2 col-md-2" >
                                <label htmlFor="recipient-name" className="col-form-label" >Id</label>
                                <input type="number" className="form-control" name="id" value={CurrentData.id} disabled/>                                
                            </div>                            

                            <div className="col-xl-10 col-md-10" >
                                <label htmlFor="recipient-name" className="col-form-label" >Nombre Categoria</label>
                                <input type="text" className="form-control" name="name_category" value={DecoderData({Data:  data_tmp_directory.name_category ==  undefined ? CurrentData.name_category : data_tmp_directory.name_category, Type: "S"})} onChange={handleChangeModal} required/>                                
                            </div>

                                                        
                            <div className="col-xl-6 col-md-6">
                                <label htmlFor="recipient-name" className="col-form-label" >Logo</label>                                
                                <input type="text" className="form-control" value={CurrentData.name_logo.length>0 ? CurrentData.name_logo : "No se ha cargado ningún logo"}  disabled/>
                            </div> 

                            <div className="col-xl-6 col-md-6">
                                <label htmlFor="recipient-name" className="col-form-label" >Subir Logo</label>                                
                                <input type="file" className="form-control" onChange={handleChangeFileModal} />
                            </div> 
                            
                            <div className="col-xl-12 col-md-12" style={ {marginTop:20} }>                                
                                <img style={ {width: '100%', maxWidth: 150, maxHeight: 150} } src={icreappconfig.url_img_directory+CurrentData.name_logo} alt="Sin Logo" className="rounded mx-auto d-block"/>      
                            </div>
                            
                
                        </div>
                    </div>
                    <div className="modal-footer">       
                        <button type="submit" disabled={ (Object.entries(data_tmp_directory).length>1 || file_data != null ) ? false : true} className="btn btn-primary" >Actualizar Comercio</button>                 
                        <button type="button" className="btn btn-secondary" onClick={() => onCloseModal()}>Cerrar</button>                
                    </div>
                </form>
        )


            });

        }else{            

            return (<form onSubmit={handleSubmit}>
                <div className="modal-body" >                                                                                        
                    
                    <div className="row" style={{margin:10}}>                                                         
                                                    
                        <div className="col-xl-12 col-md-12" >
                            <label htmlFor="recipient-name" className="col-form-label" >Nombre Categoria</label>
                            <input type="text" className="form-control" name="name_category" value={DecoderData({Data:  data_tmp_directory.name_category ==  undefined ? "" : data_tmp_directory.name_category, Type: "S"})} onChange={handleChangeModal} required/>                                
                        </div>                        
                                                
                        <div className="col-xl-6 col-md-6">
                            <label htmlFor="recipient-name" className="col-form-label" >Logo</label>                                
                            <input type="text" className="form-control" value={"No se ha cargado ningún logo"}  disabled/>
                        </div> 

                        <div className="col-xl-6 col-md-6">
                            <label htmlFor="recipient-name" className="col-form-label" >Subir Logo</label>                                
                            <input type="file" className="form-control" onChange={handleChangeFileModal} required/>
                        </div>                                                                       
            
                    </div>
                </div>
                <div className="modal-footer">    
                    <button type="submit" disabled={ ( Object.entries(data_tmp_directory).length>0 || file_data != null ) ? false : true} className="btn btn-primary" >Grabar Comercio</button>                                     
                    <button type="button" className="btn btn-secondary" onClick={() => onCloseModal()}>Cerrar</button>                
                </div>
            </form>);

        }
                

    }





    render() {

        const { open } = this.state;
        let TitleModal = "Datos del Comercio";
        let BodyPage = "";     
        let handle_add = "";   
        let handler_modal = "";      
        
        if(this.state.loading){
            BodyPage = <Loading />;
        }else{
            if(this.state.data_category_directory == [] || this.state.data_category_directory == undefined ){
                handle_add = <h3>{this.state.Msg_load}</h3>;
            }else{
                //console.log(this.state.data_bidding.length)                
                if(this.state.data_category_directory.length>0){

                    ////Se carga data amosttrar o guardar en Modal                    
                    handler_modal = this.handlerViewModal();
                    

                    handle_add = <></>;
                    if(!this.state.change_table){
                        BodyPage = <div className="row">

                                    <div className="col-xl-6 col-md-6 align-self-center text-center" >                                                                            
                                        <input type="text" className="form-control" name="Txt_search" placeholder="Ingresa tu búsqueda" value={this.state.Txt_search} onChange={this.handleChangeFilter}  />                                        
                                    </div>    

                                    <div className="col-xl-6 col-md-6 align-self-right text-right" >                                                                            
                                        <button className="btn btn-success" type="button" onClick={this.handlerAddRegisterModal} ><i className="fa fa-plus"></i> Agregar Categoria</button>
                                    </div>    

                                    <div style={ {marginTop:10} } className="col-xl-12 col-md-12 align-self-center text-center" >
                                        <Datatable
                                            multiSelectOption={false}
                                            //myData={this.state.data_directory} 
                                            myData={this.state.data_filter_directory} 
                                            pageSize={20} 
                                            pagination={true}
                                            class="-striped -highlight"     
                                            viewHandler={this.viewHandlerDirectoryStore}                                    
                                        />
                                    </div>        
                                </div>;                                
                    }else{
                        BodyPage = <div className="row">

                            <div className="col-xl-6 col-md-6 align-self-center text-center" >                                                                            
                                <input type="text" className="form-control" name="Txt_search" placeholder="Ingresa tu búsqueda" value={this.state.Txt_search} onChange={this.handleChangeFilter}  />                                        
                            </div>  

                        </div>;     
                        
                        this.setState({
                            change_table : false,                                        
                        })

                    }
                    
                }else{
                    handle_add = <h3>{this.state.Msg_load}</h3>;
                    BodyPage = <div></div>;
                }                

            }

        }

        return (
            <Fragment>
                <Breadcrumb title="Lista de categorias" parent="Directorio" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        {handle_add}
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{TitleModal}</h5>
                                            </div>   
                                            {handler_modal}                                         
                                        </Modal>                                        

                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">  
                                        {BodyPage}                                                                                                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }

}


export default List_category_directory_store
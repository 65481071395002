import React, { Component, Fragment, useRef } from 'react';

import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/listUser';
import Datatable from '../common/datatable';

/////////////////////////////////
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import ConsoleData from '../../hooks/ConsoleData'
import EmailEditorTemplate from './edit-email-template'

export class AdminEmailTemplate extends Component {

    constructor(props) {
        super(props);

        this.GetDataUsers = this.GetDataUsers.bind(this);
        
        this.state = {
            template_id: null,
            data_template : null,

            open: false,
            data:[],
            data_table: [],
            data_tmp_table: [],
            change_table : false,   
            Txt_search: "",
            form:{
                Name_category: "",
                Manual_cod: ""         
            },
            Action: "C",
            loading: true,
            error: false,   
            Msg_load: '',    
            User_email: '',
            User_type: '',     
            Msg_Error: ''
        };
    }


    componentDidMount(){
        const DataSession = GetDataSession(`IcreapSessionData`);        

        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataCategories();
            this.GetDataSession("IcreapSessionData");        
        }        
    }

    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })
            
            ///Manejo de permisos por tipo de usuario
            if(this.state.User_type < 9){
                this.GetDataUsers([DataValue.data[0].Email,DataValue.data[0].Tipo]);
            }

        }
    }    
    
    GetDataUsers = (data_session) => {
        const Urlquery = icreappconfig.url_controller+"LoadUsers.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);  

        //console.log(data_session);
        ConsoleData(data_session);  

        const Params = {
            Action: this.state.Action,
            Email: data_session[0],
            Type: data_session[1],
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_table: [],
            data_tmp_table: [],
            loading: true            
        })

        //console.log(Params); 
        ConsoleData(Params);  

        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    //console.log(res.data);   
                    ConsoleData(res.data);  
                    
                    if(res.data[0].Status){
                        let DataUSers = [];

                        res.data[0].Data.map( (CurrentService,i) => {                            
                            
                            //console.log(CurrentService);
                            ConsoleData(CurrentService);  

                            let Type_status;
                            if(CurrentService.status == "A"){
                                Type_status= <i className="fa fa-circle font-success f-12"> Activo </i>;                             
                            }else if (CurrentService.status == "I") {
                                Type_status= <i className="fa fa-circle font-warning f-12"> Inactivo </i>;                             
                            }else if (CurrentService.status == "R") {
                                Type_status= <i className="fa fa-circle font-warning f-12"> Pendiente Confirmación</i>;                             
                            }else{
                                Type_status= <i className="fa fa-circle font-danger f-12"> Bloqueado </i>;                             
                            }

                            DataUSers[i] = {                            
                                id: parseInt(CurrentService.cod_user),
                                Nombre: CurrentService.name_user,
                                Email: CurrentService.email,
                                Telefono: CurrentService.phone_user,
                                Tipo: CurrentService.name_usertype,
                                Metodo: CurrentService.method,                            
                                status: Type_status                        
                            }                                                
                            
                        })

                        this.setState({
                            data: res.data[0].Data,
                            data_table: DataUSers,
                            data_tmp_table: DataUSers,
                            loading: false,
                            error: false                        
                        })                    
                    }else{     
                        this.setState({
                            data: [],
                            data_table: [],
                            data_tmp_table: [],
                            loading: false,
                            Msg_load: res.data[0].Msg,
                            error: false                        
                        })                                   
                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }            


    RegisterTemplateData = (Design_Template, Design_HTML) => {
        console.log("RegisterTemplateData");

        console.log(Design_Template);
        console.log(JSON.stringify(Design_Template));


        const Urlquery = icreappconfig.url_controller+"AdminEmailsUsers.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);          

        const Params = {
            Action: "R",     
            Id_form: "",    
            Description: "",   
            DesignTemplate: Design_Template,
            DesignHTML: Design_HTML,
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_template: Design_Template,
            data_table: [],
            data_tmp_table: [],
            loading: true            
        })

        //console.log(Params); 
        ConsoleData(Params);  

        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    //console.log(res.data);   
                    ConsoleData(res.data);  
                    
                    if(res.data[0].Status){
                        
                        
                    }else{     
                        this.setState({
                            data: [],
                            data_table: [],
                            data_tmp_table: [],
                            loading: false,
                            Msg_load: res.data[0].Msg,
                            error: false                        
                        })                                   
                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }



        //console.log(Design_HTML);
        
    }

    render() {

        let man_email_template;
        if(this.state.template_id != null && this.state.data_template ==null ){
            if(this.state.loading){
                man_email_template = <Loading />
            }else{
                man_email_template = <h6>{this.state.Msg_load}</h6>
            }
        }else{
            man_email_template = <EmailEditorTemplate IdTemplate={this.state.template_id} DataTemplate={this.state.data_template} RegisterTemplateData = {this.RegisterTemplateData}  />;
        }
        
        return (
            <Fragment>
                <Breadcrumb title="Administrador Templates Email" parent="Notificaciones" />
                <div className="container-fluid">                    
                    {man_email_template}
                </div>
            </Fragment>
        )
    }
}

export default AdminEmailTemplate;

import React, { Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal';
import Datatable from '../common/datatable';
import axios from 'axios';
import icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'
import Activity_finder from '../specific/activity_finder'
import Handler_bidding_deta from '../specific/handler_bidding_deta'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConsoleData from '../../hooks/ConsoleData';

export class Bidding_apply extends Component {

    constructor(props) {
        super(props);
        this.GetDataStore = this.GetDataStore.bind(this);

        this.state = {
            Action: "C",
            Action_form : "G",
            open: false,
            data_store: [],   
            data_bidding: [],
            data_detabidding: [],
            data_detabiddingpost:[],                      
            form : {
                Cod_biddingPost: "",
                Note:"",
                Status_bidding: ""

            },
            Msg_load: "",
            loading: true,            
            error: false            
        };
    }

    onOpenModal = () => {
        //console.log("onOpenModal");
        //console.log(e);
        this.setState({            
            open: true            
        });
    };

    onCloseModal = () => {        
        this.setState({            
            open: false,
            form : {
                Cod_biddingPost: "",
                Note:"",
                Status_bidding: ""
            }, 
        });
    }

    componentDidMount(){        
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataSubCategories();
            this.GetDataSession("IcreapSessionData");        
        }        
    }
    
    ///Se valida que la sesion se encuentre activa
    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            this.GetDataStore([DataValue.data[0].Email,DataValue.data[0].Tipo])

        }
    }

    handleChange = e => {
        //console.log(`${e.target.name}: ${e.target.value}`)
        ConsoleData(`${e.target.name}: ${e.target.value}`);
        
        // Esto se utiliza para guardar los valores en los estados                
        // Metodo nuevo - computer propierti name
        this.setState({
            form:{
                ...this.state.form,
                Cod_biddingPost: this.state.data_detabidding[0].Cod_bidding,
                [e.target.name]: e.target.value
            }            
        })
        
    }  

    handleSubmit = async e => {
        ConsoleData("Submit")

        e.preventDefault();                
        
        try {

            //Se valida que la nota este bien ingresada
            if(this.state.form.Note.length<20 && this.state.form.Status_bidding=="C"){
                toast.error("La nota debe tener mas de 20 caracteres" );
                return false;
            }
            //////////////////////

            ///Si se va a cancelar la solicitud se debe hacer doble validacion
            if(this.state.form.Status_bidding=="C"){
                if (!window.confirm('¿Esta seguro de cambiar el estado a CANCELADO?. Luego de cambiarlo no podrá revertir este cambio. Si desea continuar con el cambio de estado ingrese en la opción Aceptar')) {
                    toast.success("Se detuvo el proceso de cancelacion correctamente" );
                    return false;
                }
            }            
            ////////////////////////////////////////////
                        
                
            const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
            ConsoleData(Urlquery);        
    

            const formData = new FormData();   
            
            formData.append('Action',this.state.Action_form);    
            formData.append('User_email',this.state.User_email);    
            formData.append('User_type',this.state.User_type);    
            formData.append('form',JSON.stringify(this.state.form)); 

            //console.log(formData);                

            this.setState({                            
                loading: true,
                error: false,
                Msg_error: ""                        
            });        
            
            axios.post(Urlquery, formData)            
                .then(res => {
                    ConsoleData(res.data);   
                   
                    if(res.data[0].Status){

                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       
                        
                        this.setState({
                            loading: false,
                            error: false,                            
                            form:{                                                     
                            }
                        })       
                        
                        ///Se utiliza para cerrar el modal despues de registrar el cambio                        
                        toast.success(decoder);                                                
                        this.onCloseModal();        
                        this.GetDataSession("IcreapSessionData");        
                    }else{  
                        //alert(res.data[0].Msg); 
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 

                        toast.error( decoder );
                        this.setState({
                            loading: false,
                            error: false,
                            Msg_Error: res.data[0].Msg
                        })
                        
                   }
                   
                })
                .catch(err => {
                    console.error(err);
                    this.setState({
                        loading: false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            ////this.props.history.push('/exercise')

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        

    }

    ///////////////////    

    GetDataStore = (Data_Sesion) => {
        const Urlquery = icreappconfig.url_controller+"AdminStores.php";
        //console.log(Urlquery);        

        const Params = {
            Action: this.state.Action,
            User_email: Data_Sesion[0],
            User_type: Data_Sesion[1]
        }

        ///Se reinician estados para control de datos
        this.setState({
            data_store: [],
            loading: true            
        })

        //console.log(Params); 
        try{
            axios.post(Urlquery,Params)
                .then(res => {
                    //console.log(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        
                        let Man_dataStore = [];
                        if(res.data[0].Data_store.length != 0){
                            Man_dataStore = res.data[0].Data_store[0];

                        }

                        this.setState({                            
                            data_store : Man_dataStore,                            
                            Msg_load: decoder,                            
                            error: false                        
                        })           
                        
                        setTimeout(() => {
                            this.GetDataBiddingsApply();
                        },1000); 
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            data_store : res.data[0].Data_store,
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
        
    }

    GetDataBiddingsApply = () => {
        

        const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);

        const formData = new FormData();           

        formData.append('Action','A');    
        //formData.append('User_email',this.state.User_email);    
        //formData.append('User_type',this.state.User_type);    
        formData.append('Cod_store',this.state.data_store.Cod_store); 

        //console.log(formData);                

        this.setState({                            
            loading: true,
            data_bidding: [],
            error: false,
            Msg_error: ""                        
        });    
        
        try{
            axios.post(Urlquery,formData)
                .then(res => {
                    //console.log(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        
                        let Man_bidding = [];
                        if(res.data[0].Data_biddingPost.length != 0){
                            
                            res.data[0].Data_biddingPost.map( (CurrentService,i) => {                            
                            
                                //console.log(CurrentService);
                                ConsoleData(Urlquery);
    
                                let Status_bidding;
                                if(CurrentService.status == "A"){
                                    Status_bidding = <i className="fa fa-check-circle-o font-success f-12" > Aceptado </i>;                                                               
                                }else if (CurrentService.status == "F") {
                                    Status_bidding = <i className="fa fa-check-circle-o font-success f-12" > Finalizado </i>;                                                                                              
                                }else if (CurrentService.status == "C") {
                                    Status_bidding = <i className="fa fa-ban font-danger f-12" > Cancelado </i>;                             
                                }else if (CurrentService.status == "R") {
                                    Status_bidding = <i className="fa fa-ban font-danger f-12" > Rechazado </i>;                             
                                }else if (CurrentService.status == "P") {
                                    Status_bidding = <i className="fa fa-clock-o font-warning f-12">Pendiente</i>;                             
                                }else if (CurrentService.status == "N") {
                                    Status_bidding = <i className="fa fa-circle font-danger f-12" > No aceptado </i>;                             
                                }
                                else{
                                    Status_bidding = <i className="fa fa-circle font-danger f-12" > Bloqueado </i>;                             
                                }
    
                                Man_bidding[i] = {                            
                                    id: parseInt(CurrentService.cod_biddingpost),
                                    creado: CurrentService.date_create,
                                    Comercio: CurrentService.name_store,                                                                                                    
                                    valor_total: DecoderData({Data: CurrentService.total_value, Type: "C"}),                            
                                    Parcial: (CurrentService.partial=="1" ? "Si" : "No, Completa"),                                                
                                    status: Status_bidding                        
                                }                                                
                                
                            })                            

                        }

                        this.setState({                            
                            data_bidding : Man_bidding,                            
                            Msg_load: decoder,
                            loading: false,
                            error: false                        
                        })                    
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            data_bidding : [],
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }


    }

    viewHandlerBiddingsPost = (e) => {
        //console.log(e);
        //console.log(e.datos.id);

        ConsoleData(e);
        ConsoleData(e.datos.id);
        
        this.setState({                            
            loading: true,
            name_store: e.datos.Comercio,
            open: true
        });   

        ////Se traen los datos de la oferta especifica 
        try {                                        
            const Urlquery = icreappconfig.url_controller+"AdminBiddings.php";
            //console.log(Urlquery);    
            ConsoleData(Urlquery);    
            
    
            const formData = new FormData();               
            formData.append('Action','T');  
            formData.append('Cod_bidding',e.datos.id);  
            formData.append('Cod_store',this.state.data_store.Cod_store);  

            axios.post(Urlquery, formData)            
                .then(res => {
                   //console.log(res.data);   
                   ConsoleData(res.data);
                   
                    if(res.data[0].Status){

                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});                                                                       
                        this.setState({
                            loading: false,
                            error: false,
                            data_detabidding: res.data[0].DataBidding,
                            data_detabiddingpost: res.data[0].DataBiddingPost,                            
                            form:{
                                ...this.state.form,
                                Status_bidding: res.data[0].DataBiddingPost[0].Status_bidding
                            }                             
                        })                        

                    }else{  
                        //alert(res.data[0].Msg); 
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"}); 

                        toast.error( decoder );
                        this.setState({
                            loading: false,
                            error: false,
                            open : false,
                            Msg_Error: res.data[0].Msg,
                            data_detabidding: [],
                            data_detabiddingpost: []
                        })
                        
                   }
                   
                })
                .catch(err => {
                    console.error(err)
                    this.setState({
                        loading: false,
                        open : false,
                        error: true,
                        Msg_Error: err
                    })
                });                                    

            

        }catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
    }


    handlerViewModal = () => {
        
        //console.log("handlerViewModal");
        //console.log(props.length);                    

        const handleChange = this.handleChange;
        const onCloseModal = this.onCloseModal;
        const form_data = this.state.form;
        const name_store = this.state.name_store;
        const data_detabidding = this.state.data_detabidding;
        const data_detabiddingpost = this.state.data_detabiddingpost;
        const handleSubmit = this.handleSubmit;

        const ManNoteInput = <div className="col-xl-12 col-md-12">
                                <label htmlFor="recipient-name" className="col-form-label" >Nota cambio de estado</label>
                                <textarea className="form-control" name="Note" rows="3" value={form_data.Note} onChange={handleChange} required disabled={(form_data.Cod_biddingPost!="" ? false : true )} ></textarea>
                            </div>;

        let LoadBidding;
        if(data_detabidding.length>0){
            //console.log(props[0].deta);    
            LoadBidding= <div style={{margin:20}} >
                                 
                            {
                                //let Data_AddSubcat = { 'cod_subcat' : this.state.tmp.Cod_subcategory, 'name_subcat' : this.state.tmp.Name_subcategory };
                                data_detabidding.map( (CurrentData,i) => {                            
                                    return (
                                        <div className="row" key={i}>

                                            <div className="col-xl-12 col-md-12 align-self-center text-center" >
                                                <h4>Datos de la Oferta</h4> 
                                            </div>                                                                                        

                                            <div className="col-xl-4 col-md-4">
                                                <label htmlFor="recipient-name" className="col-form-label" ><strong>Comercio:</strong> {name_store}</label>                                
                                            </div>

                                            <div className="col-xl-4 col-md-4">
                                                <label htmlFor="recipient-name" className="col-form-label" ><strong>Estado del requerimiento:</strong> {(CurrentData.Status_bidding=="A" ? "Activa" : (CurrentData.Status_bidding=="C" ? "Cancelado" : (CurrentData.Status_bidding=="F" ? "Finalizado" : "Sin definir" )  ) )}</label>
                                            </div>                                            

                                            <div className="col-xl-4 col-md-4">
                                                <label htmlFor="recipient-name" className="col-form-label" ><strong>Expira:</strong> {CurrentData.Date_expire}</label>                                                                
                                            </div>

                                            <div style={ {marginTop:20} } className="col-xl-12 col-md-12 align-self-center text-center">                                            
                                                <h4>
                                                    {CurrentData.Adjunto.length>0 ? <a href={icreappconfig.url_platform+CurrentData.Adjunto} target="_blank" >Ver archivo de la oferta</a> : ""}                                                    
                                                </h4>
                                            </div>

                                            <div className="col-xl-12 col-md-12">
                                                <label htmlFor="recipient-name" className="col-form-label" ><strong>Descripción del requerimiento:</strong> {CurrentData.Desc}</label>                                                                
                                            </div> 

                                            <div className="col-xl-12 col-md-12">
                                                <label htmlFor="recipient-name" className="col-form-label" ><strong>Políticas de pago del requerimiento:</strong> {CurrentData.PolPag}</label> 
                                            </div> 
                                            
                                            <div className="col-xl-6 col-md-6">
                                                <label htmlFor="recipient-name" className="col-form-label" ><strong>Solicitud:</strong>{(CurrentData.Type_bidding == "1" ? "Pública" : (CurrentData.Type_bidding == "2" ? "Directa" : "Sin definir")  )}</label>                                
                                            </div>

                                            <div className="col-xl-6 col-md-6">
                                                <label htmlFor="recipient-name" className="col-form-label" ><strong>Tiempo máximo entrega "días":</strong> {CurrentData.Delivery}</label>                                 
                                            </div>                                            

                                        </div>    
                                    )                                                
                                })
                            }                                
                        </div>;

        }

        return data_detabiddingpost.map(function (CurrentData,i){
            return (<form key={i} onSubmit={handleSubmit}>
                        <div className="modal-body" >                                
                            
                            {LoadBidding}
                            
                            <div className="row" style={{margin:10}}>                                

                                <div className="col-xl-12 col-md-12 align-self-center text-center" style={ {marginTop:20} } >
                                    <h4>Datos aplicados a la oferta</h4> 
                                </div>                                 

                                <div className="col-xl-12 col-md-12" style={{marginTop:10,marginBottom:10}}>
                                    <label htmlFor="recipient-name" className="col-form-label" >Estado</label>
                                    <select className="custom-select " value={form_data.Status_bidding == null ? CurrentData.Status_bidding : form_data.Status_bidding  } onChange={handleChange} name="Status_bidding" disabled={(  CurrentData.Status_bidding != "P" ? true : (form_data.Status_bidding != "P" ? true : false ) )} required>                                                    
                                        <option value="P">Pendiente</option>
                                        <option value="C">CANCELAR</option>
                                        <option disabled={true} value="A">Aceptado</option>
                                        <option disabled={true} value="R">Rechazada</option>
                                        <option disabled={true} value="F">Finalizada</option>                                        
                                        <option disabled={true} value="N">No aceptado</option>                                        
                                    </select>
                                </div>

                                { form_data.Status_bidding == "C" ? ManNoteInput : "" }

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Creado:</strong>{CurrentData.Date_create}</label>                                
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Maneja parciales:</strong>{(CurrentData.Partial == "1" ? "Si" : "No, propuesta completa" )}</label>                                
                                </div>

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Valor total de la propuesta:</strong>{DecoderData({Data: CurrentData.Total_value, Type: "C"})}</label>                                
                                </div>                                

                                <div className="col-xl-6 col-md-6">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Tiempo de entrega "días":</strong>{CurrentData.Delivery}</label>                                
                                </div>

                                <div style={ {marginTop:20} } className="col-xl-12 col-md-12 align-self-center text-center">                                            
                                    <h4>
                                        {CurrentData.Adjunto.length>0 ? <a href={icreappconfig.url_platform+CurrentData.Adjunto} target="_blank" >Ver archivo de la propuesta</a> : ""}                                        
                                    </h4>
                                </div>

                                <div style={ {marginTop:10} } className="col-xl-12 col-md-12 align-self-center text-center" >                                        
                                        <Handler_bidding_deta 
                                            Type = {"ViewPostulation"}
                                            Data={ CurrentData.deta.deta_bidding }
                                            HandlerData = {null}/>                                        
                                </div>

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Políticas de pago de la Oferta:</strong> {CurrentData.PolPag}</label> 
                                </div> 
                                
                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Valor agregado de la oferta:</strong>{CurrentData.Plus}</label>                                
                                </div>                                

                                <div className="col-xl-12 col-md-12">
                                    <label htmlFor="recipient-name" className="col-form-label" ><strong>Nota adicional:</strong>{CurrentData.Note}</label>                                
                                </div>                                                               
                    
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={form_data.Status_bidding == CurrentData.Status_bidding ? true : false } >Registrar cambio de estado</button>                            
                            <button type="button" className="btn btn-secondary" onClick={() => onCloseModal()}>Cerrar</button>                
                        </div>
                    </form>
            )
        })        
    }


    render() {

        const { open } = this.state;
        let TitleModal = "Oferta Aplicada";
        let BodyPage = "";     
        let handle_add = "";   
        let handler_modal = "";      
        
        if(this.state.loading){
            BodyPage = <Loading />;
        }else{
            if(this.state.data_bidding == []){
                handle_add = <h3>{this.state.Msg_load}</h3>;
            }else{
                //console.log(this.state.data_bidding.length)
                if(this.state.data_bidding.length>0){
                    handle_add = <></>;
                    BodyPage = <Datatable
                                    multiSelectOption={false}
                                    myData={this.state.data_bidding} 
                                    pageSize={10} 
                                    pagination={true}
                                    class="-striped -highlight"     
                                    viewHandler={this.viewHandlerBiddingsPost}                                    
                                />
                }else{
                    handle_add = <h3>{this.state.Msg_load}</h3>;
                    BodyPage = <div></div>;
                }


                if(this.state.data_detabiddingpost == []){
                    handler_modal = <></>;
                }else{
                    handler_modal = this.handlerViewModal();
                }

            }

        }

        return (
            <Fragment>
                <Breadcrumb title="Ofertas Aplicadas" parent="Ofertas" />
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">
                                    <div className="btn-popup pull-right">
                                        {handle_add}
                                        <Modal open={open} onClose={this.onCloseModal} >
                                            <div className="modal-header">
                                                <h5 className="modal-title f-w-600" id="exampleModalLabel2">{TitleModal}</h5>
                                            </div>   
                                            {handler_modal}                                         
                                        </Modal>

                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">  
                                        {BodyPage}                                                                                                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }

}

export default Bidding_apply
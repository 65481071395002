import React, { Component, Fragment } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb'
import Modal from 'react-responsive-modal';
import { File } from 'react-feather';

import ConsoleData from '../../hooks/ConsoleData';
import axios from 'axios';
import Icreappconfig from '../../Icreappconfig'
import Loading from '../Loading'
import GetDataSession from '../../hooks/DataSession'
import DecoderData from '../../hooks/DecoderData'


export class Help_store extends Component {   

    constructor(props) {
        super(props);
        
        this.state = {            
            files_help: [],
            Msg_load: "",                            
            error: false,
            loading:false,
            User_email: "",
            User_type: ""
        }        
        
        this.GetDataSession = this.GetDataSession.bind(this);        
    }

    componentWillMount(){
        const DataSession = GetDataSession(`IcreapSessionData`);
        if(!DataSession){
            this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }else{
            //this.GetDataSubCategories();
            this.GetDataSession("IcreapSessionData");        
        } 
    }


    GetDataSession(key){
        let SessionDataValue = sessionStorage.getItem(key);
        
        if (SessionDataValue !== null) {
            let DataValue = JSON.parse(SessionDataValue);
            this.setState({
                User_email: DataValue.data[0].Email,
                User_type: DataValue.data[0].Tipo
            })

            this.GetHelpFiles();
        }
    }

    GetHelpFiles = () => {
        const Urlquery = Icreappconfig.url_controller+"upload_file.php";
        //console.log(Urlquery);        
        ConsoleData(Urlquery);

        const formData = new FormData(); 
        formData.append('Action',"H");    
        formData.append('Group',"ayuda_comercio");    

        ///Se reinician estados para control de datos
        this.setState({
            datatable_files : [], 
            data_files: [],  
            loading: true            
        })

        try{
            axios.post(Urlquery,formData)
                .then(res => {
                    
                    ConsoleData(res.data);   
                    
                    if(res.data[0].Status){           
                                          
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});
                        ConsoleData(res.data[0].Data_file);
                        
                        this.setState({                            
                            files_help: res.data[0].Data_file,
                            loading : false,                           
                            Msg_load: decoder,                            
                            error: false                        
                        })           
                                                
                        
                    }else{     
                        
                        let decoder = DecoderData({Data: res.data[0].Msg, Type: "S"});

                        this.setState({
                            files_help: [],
                            loading: false,
                            Msg_load: decoder,
                            error: false                        
                        }) 

                    }
                    
                })
                .catch(err => {
                        console.error(err);
                        this.setState({
                            datatable_files : [], 
                            data_files: [],
                            loading: false,
                            error: true,
                            Msg_Error: err
                        })
                });

        } catch (error) {
            this.setState({
                loading: false,
                error
            }) 
        }
    
    
    }

    viewHelpFiles = () => {

        ConsoleData("Ingreso viewHelpFiles")
        const helpfiles = this.state.files_help;        

        return helpfiles.map( (CurrentData,i) => {   
            //ConsoleData(Object.values(form_data).includes(CurrentData.Cod_biddingpost));

            return (
                <tr key={i}> 
                    <td>
                        <a href={Icreappconfig.url_platform+CurrentData.name_file} target="_blank" >
                            <File />{CurrentData.desc_file}
                        </a>                                    
                    </td>                            
                </tr>                
            )                                                
            
        })

    }

    render() {

        const { open } = this.state;
        let TitleModal = "Oferta Aplicada";
        let BodyPage = "";     
        let handle_add = "";   
        let handler_modal = "";      

        let Table_help = <tr><td></td></tr>;        

        if(this.state.loading){
            Table_help = <tr>
                            <td>Cargando Archivos...</td>
                        </tr>;
        }else{
            if(this.state.files_help!= []){

                Table_help = this.viewHelpFiles();

            }else{
                Table_help = <tr>
                                <td>Lo sentimos aun no hemos subido los archivos de ayuda. Pronto podras ingresar a ellos y conocer mejor nuestra plataforma</td>
                            </tr>;                
            }
        }

        
        BodyPage = <div className="modal-body" >                                
                        <div className="row">  

                            <div className="col-xl-12 col-md-12">
                                <h6 style={{color: "#000000"}}>
                                    Verifica primero los archivos de ayuda, si no encuentras respuesta a tus preguntas puedes escribirnos al correo <strong>mosqueraaplicacion@gmail.com</strong>, te responderemos en el menor tiempo posible.
                                </h6>
                            </div>
                        
                            <div className="col-xl-12 col-md-12">                            
                                <table class="table table-striped">
                                    <thead>
                                        <tr className="text-center">
                                            <th>Manuales</th>
                                        </tr> 
                                    </thead>
                                    <tbody>
                                        {Table_help}
                                    </tbody>
                                </table>   

                            </div>
                        </div>
                    </div>        

        return (
            <Fragment>
                <Breadcrumb title="Ayuda" parent="Ayuda"/>
                {/* <!-- Container-fluid starts--> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">                                
                                <div className="card-body">
                                    
                                    <div className="clearfix"></div>
                                    <div id="basicScenario" className="product-physical">  
                                        {BodyPage}                                                                                                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Container-fluid Ends--> */}
                <ToastContainer />
            </Fragment>
        )
    }

}

export default Help_store

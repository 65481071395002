const name_platform = "MosquerApp"
const in_debugger = true; ///Parametro de depuracion

// let url_platform = 'https://www.mosquerapp.com/'
let url_platform = '/'

if(in_debugger){
  //url_platform = 'http://localhost/mosquerapp.com/'
}

const url_images = url_platform+'img/'
const url_controller = url_platform+'controllers/'
const url_img_directory = url_platform+'images/Directory/'
const sessionExpiration = 60*4; //Expira la sesion despues de una hora de inactividad, el 4 representa cuantas horas
const max_size_catalogs = 5; ///El numero representara el tamaño en MB
const max_size_logos = 2; ///El numero representara el tamaño en MB
const max_size_verif = 7; //Se maneja máximo de 7MB 

export default {url_platform,url_controller,max_size_verif,url_images,sessionExpiration,name_platform,max_size_catalogs,max_size_logos,in_debugger,url_img_directory}